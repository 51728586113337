import {Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function SearchButton(props) {
    return (
        <Button style={{color: '#fff'}} color="secondary" variant="contained" onClick={() => props.onClick()}>
            Zoeken
        </Button>
    );
}

SearchButton.propTypes = {
    onClick: PropTypes.func
};

export default SearchButton;
