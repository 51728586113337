import * as Actions from '../store/actions';
//import {Util} from 'ui-shared';

const initialState = {
    //supportEntry: Util.loadFromSessionStorage('supportEntry')
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.SAVE_SUPPORTENTRY:
            return {...state, supportEntry: action.supportEntry};
        // Onderstaande Action wordt niet gebruikt, maar kan nog wel handig zijn voor de toekomst
        case Actions.SUPPORTENTRY_CLOSED:
            return {...state, closedSupportEntryId: action.id};
        default:
            return state;
    }
};
