import * as React from 'react';

import EditPage from '../../../components/editpage';
import OAuthClientForm from './oauthclientform';
import {compose} from "recompose";
import {Paper, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class OAuthClientEdit extends React.Component {
    static defaultProps = {
        endpoint: 'oauthclients',
        entityDescription: 'OAuthClient'
    };

    render() {
        const {entityDescription, endpoint, classes} = this.props;
        const {id} = this.props.match.params;
        return (
            <Paper className={classes.root}><EditPage endpoint={endpoint} entityDescription={entityDescription} formComponent={OAuthClientForm} entityId={id} /></Paper>
        );
    }
}

export default compose(withStyles(styles))(OAuthClientEdit);
