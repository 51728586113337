import {Grid, withStyles} from '@material-ui/core';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import React from 'react';

function LinkButton(props) {
    const {entity, endpoint, tooltip, history} = props;
    return entity ? (
        <Grid>
            <a
                href=""
                onClick={e => {
                    e.preventDefault();
                    history.push(`/${endpoint}/${entity.id}/extended-view`);
                }}
            >
                <Grid title={tooltip}>
                    <OpenInNewOutlinedIcon color="primary" />
                </Grid>
            </a>
        </Grid>
    ) : (
        <React.Fragment />
    );
}

export default LinkButton;
