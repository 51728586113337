import {Oidc} from '@axa-fr/react-oidc-redux';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import ToegangDrawer from './components/drawer';
import UserMenu from './components/usermenu';
import history from './history';
import {oidcConfiguration} from './oidcconfiguration';
import ProtectedRoutes from './routes';
import store from './store/store';

const styles = (theme) => {
    const drawerWidth = 240;
    return {
        root: {
            display: 'flex',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
            position: 'relative',
        },
        appBar: {
            backgroundColor: '#071918',
            color: '#fff',
            height: theme.spacing(8),
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            minHeight: '100vh',
            backgroundColor: '#EBFDFC',
        },
        formContainer: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        menuIcon: {
            color: '#fff',
        },
        menuButton: {
            marginLeft: 12,
            marginRight: 36,
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            flexGrow: 1,
        },
        pumpkin: {
            top: 20,
            zIndex: -1,
            position: 'absolute',
            marginLeft: theme.spacing(1),
        },
    };
};

class App extends React.Component {
    state = {
        open: true,
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;
        const currentDate = new Date();

        const Main = () => (
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <Oidc store={store} configuration={oidcConfiguration.config} isEnabled={true}>
                        <div className={classes.root}>
                            <AppBar
                                position="absolute"
                                className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                            >
                                <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                                    <IconButton
                                        aria-label="Open drawer"
                                        onClick={this.handleDrawerOpen}
                                        className={classNames(classes.menuButton, this.state.open && classes.menuButtonHidden)}
                                    >
                                        <MenuIcon className={classes.menuIcon} />
                                    </IconButton>
                                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                                        <span>Toegang.org</span>
                                        {currentDate.getMonth() === 9 && currentDate.getDate() >= 30 && (
                                            <span className={classes.pumpkin}>
                                                <img
                                                    style={{width: '2.5em'}}
                                                    src="https://storage.googleapis.com/cdn-toegang/pumpkin_3.png"
                                                />
                                            </span>
                                        )}
                                    </Typography>
                                    <UserMenu />
                                </Toolbar>
                            </AppBar>
                            <ToegangDrawer handleDrawerClose={this.handleDrawerClose} open={this.state.open} />
                            <Grid className={classes.content}>
                                <div className={classes.appBarSpacer} />
                                <ProtectedRoutes />
                            </Grid>
                        </div>
                    </Oidc>
                </BrowserRouter>
            </Provider>
        );
        return <Main />;
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
