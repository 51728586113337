import * as Actions from '../store/actions';

export const initialState = {
    saving: false,
    loading: false,
    entities: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.POST_DATA:
        case Actions.PUT_DATA:
        case Actions.DELETE_ITEM:
            return {...state, saving: true, loading: false};
        case Actions.FETCH_ITEM:
        case Actions.FETCH_DATA:
            return {...state, saving: false, loading: true};
        case Actions.RECEIVE_DATA:
            return {...state, saving: false, loading: false, entities: action.data};
        case Actions.RECEIVE_DATA_ERROR:
            return {...state, saving: false, loading: false};
        case Actions.POST_DATA_SUCCESS:
        case Actions.PUT_DATA_SUCCESS:
            return {...state, saving: false, postError: false, postSuccess: true};
        case Actions.DELETE_ITEM_SUCCESS:
            return {...state, saving: false, postError: false, entities: state.entities.filter(e => e.id !== action.id)};
        case Actions.DESTROY_SESSION_SUCCESS:
            return {...state, saving: false, postError: false, entities: state.entities.filter(e => e.id !== action.session.id)};
        case Actions.POST_DATA_ERROR:
        case Actions.PUT_DATA_ERROR:
        case Actions.DELETE_ITEM_ERROR:
        case Actions.DESTROY_SESSION_ERROR:
            return {
                ...state,
                saving: false,
                postError: true,
                postErrorMessage: action.error.message,
                postSuccess: false
            };
        default:
            return state;
    }
};
