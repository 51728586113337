import * as React from 'react';

import EditPage from '../../../components/editpage';
import ProductForm from './productform';
import {Paper, withStyles} from '@material-ui/core';

import {compose} from 'recompose';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class ProductEdit extends React.Component {
    static defaultProps = {
        endpoint: 'products',
        entityDescription: 'Product'
    };

    render() {
        const {id, ean} = this.props.match.params;
        const {entityDescription, endpoint, classes} = this.props;

        const editpageProps = {entityDescription, endpoint, formComponent: ProductForm};

        if (ean) editpageProps.filter = {ean};
        else editpageProps.entityId = id;

        return (<Paper className={classes.root}><EditPage {...editpageProps} /></Paper>);
    }
}

export default compose(withStyles(styles))(ProductEdit);
