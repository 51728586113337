import DateFnsUtils from '@date-io/date-fns';
import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {nl} from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {InfoResolveRenderer} from '../../components/datatable/contentrenderers';
import EditableInfoResolveRenderer from '../../components/datatable/editableinforesolverenderer';
import GenericField from '../../components/input/genericfield';
import ReadOnly from '../../components/input/readonly';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import * as RestClient from '../../services/restclient';
import defaultConnect from '../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    inputContainer: {
        alignItems: 'center'
    },
    formInputBox: {
        width: 250,
        margin: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    grid: {
        margin: theme.spacing(2),
        flexWrap: 'wrap',
        '& > div > div': {
            width: '100%'
        }
    }
});

const today = new Date();

const bedragenConverter = bedrag => {
    const pricestring = bedrag.toString();
    const split = pricestring.split('.');
    if (split[1] && split[1].length === 1) {
        return pricestring + '0';
    } else if (!split[1]) {
        return pricestring + '.00';
    } else {
        return pricestring;
    }
};

function InvoicePage(props) {
    const {classes, authUserReducer, invoiceReducer} = props;

    // getmonth niet +1 want we willen altijd vorige maand
    const [month, setMonth] = React.useState(today.getMonth());
    const [year, setYear] = React.useState(today.getFullYear());
    const [publisherId, setPublisherId] = React.useState(undefined);
    const [factuur, setFactuur] = React.useState({});
    const [version, setVersion] = React.useState(0);

    const valueDate = new Date();
    valueDate.setMonth(month - 1);
    valueDate.setFullYear(year);

    async function adjustInvoice() {
        await props.adjustInvoice(factuur);
    }

    function reloadDataTable() {
        setVersion(Number(Date.now()));
    }

    React.useEffect(() => {
        if (
            authUserReducer &&
            authUserReducer.beheerder &&
            authUserReducer.beheerder.uitgever &&
            authUserReducer.beheerder.uitgever.id
        ) {
            setPublisherId(authUserReducer.beheerder.uitgever.id);
        }
    }, [authUserReducer]);

    React.useEffect(() => {
        if (invoiceReducer && !invoiceReducer.loading) {
            setVersion(version + 1);
        }
    }, [invoiceReducer]);

    React.useEffect(() => {
        if (month && year && publisherId) {
            RestClient.fetchItem({
                endpoint: 'organisations',
                id: `${publisherId}/invoices/${year}/${month}`
            })
                .then(invoice => {
                    setFactuur(invoice);
                })
                .catch(e => {
                    setFactuur({});
                });
        }
    }, [month, year, publisherId, version]);

    return (
        <Paper className={classes.root}>
            <Typography gutterBottom component="h1" variant="h5">
                Facturatie
            </Typography>
            <Grid container justify="flex-start" className={classes.inputContainer}>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Periode"
                                variant={'inline'}
                                openTo={'month'}
                                autoOk={true}
                                value={valueDate}
                                onChange={date => {
                                    setMonth(date.getMonth() + 1);
                                    setYear(date.getFullYear());
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item>
                    {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'organisation'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'Uitgever'}}
                                value={publisherId}
                                labelKey={'name'}
                                secondLabelKey={'esn'}
                                placeholder={'Uitgever...'}
                                onChange={(property, value) => {
                                    setPublisherId(value && value.id);
                                }}
                            />
                        </FormControl>
                    )}
                </Grid>
                <Grid item>
                    <SearchButton onClick={reloadDataTable.bind(this)} />
                </Grid>
            </Grid>
            {factuur && factuur.attributes ? (
                <Grid container className={classes.grid}>
                    <Grid md={12}>
                        <GenericField label="Facturatie Model">
                            <ReadOnly>{factuur.attributes.billingModel || '-'}</ReadOnly>
                        </GenericField>
                        {factuur.attributes.billingPercentage && (
                            <GenericField label="Percentage">
                                <ReadOnly>{factuur.attributes.billingPercentage || '-'}</ReadOnly>
                            </GenericField>
                        )}
                        {factuur.attributes.billingPrice && (
                            <GenericField label="Prijs per licentie jaar">
                                <ReadOnly>€{factuur.attributes.billingPrice || '-'}</ReadOnly>
                            </GenericField>
                        )}
                        <GenericField label="Totaal deze maand">
                            <ReadOnly>€{bedragenConverter(factuur.attributes.totalPrice) || '-'}</ReadOnly>
                        </GenericField>
                        <DataTable
                            endpoint={`organisations/${publisherId}/invoices/${year}/${month}/productDetails?version=${version}`}
                            version={version}
                            csv
                            columns={[
                                {label: 'EAN', dataKey: 'ean'},
                                {label: 'Titel', dataKey: 'title'},
                                {label: 'Beschikbare Tlinks', dataKey: 'availableTlinks'},
                                {label: 'Actieve Tlinks', dataKey: 'activeTlinks'},
                                {label: 'Beschikbare ECK licenties', dataKey: 'availableEck'},
                                {label: 'Inactief deze maand', dataKey: 'totalInactive'},
                                {
                                    label: 'Totaal licenties',
                                    dataKey: 'totalPaidLicenses',
                                    component: (
                                        <EditableInfoResolveRenderer
                                            isEditable={hasRight(authUserReducer, Recht.IS_SUPERUSER)}
                                            inputProps={{pattern: '[0-9]*'}}
                                            onSave={(props, newValue) => {
                                                factuur.attributes.productDetails[
                                                    props.rowIndex
                                                ].adjustedTotal = Number.isInteger(parseInt(newValue))
                                                    ? parseInt(newValue)
                                                    : undefined;
                                                adjustInvoice();
                                            }}
                                            infoResolver={rowData => {
                                                return {
                                                    title: 'totaal',
                                                    rowValue:
                                                        rowData.adjustedTotal == undefined
                                                            ? rowData.availableTlinks +
                                                              rowData.activeTlinks +
                                                              rowData.availableEck +
                                                              rowData.totalInactive
                                                            : rowData.adjustedTotal
                                                };
                                            }}
                                        />
                                    )
                                },
                                {
                                    label: 'Prijs',
                                    dataKey: 'price',
                                    component: (
                                        <InfoResolveRenderer
                                            infoResolver={rowData => {
                                                return {
                                                    title: 'prijs',
                                                    rowValue: bedragenConverter(rowData.price)
                                                };
                                            }}
                                        />
                                    )
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            ) : (
                <h4>Geen factuur gevonden voor deze maand</h4>
            )}
        </Paper>
    );
}

InvoicePage.propTypes = {
    adjustInvoice: PropTypes.func
};

export default compose(defaultConnect, withPermissions(Recht.FACTUUR_INZIEN), withStyles(styles))(InvoicePage);
