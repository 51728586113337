import {BillingModelType} from 'shared-types';
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

function toUrl(input) {
    if (input && !input.includes('//')) {
        return 'http://' + input;
    } else {
        return input;
    }
}

export default function getValidationSchema() {
    return Yup.object()
        .shape({
            esn: Yup.string()
                .max(160)
                .nullable()
                .required(),
            name: Yup.string()
                .max(255)
                .nullable(),
            type: Yup.array().required(),
            websiteUrl: Yup.string()
                .transform(toUrl)
                .url()
                .nullable(),
            abonnementUrl: Yup.string()
                .transform(toUrl)
                .url()
                .nullable(),
            brin: Yup.string()
                .max(10)
                .nullable(),
            instellingBrin: Yup.string()
                .max(10)
                .nullable(),
            sector: Yup.string()
                .max(10)
                .nullable()
                .required(),
            city: Yup.string()
                .max(255)
                .nullable(),
            arp: Yup.boolean(),
            arpSince: Yup.date().nullable(),
            billingPercentage: Yup.number()
                .min(0)
                .nullable(),
            billingPrice: Yup.number()
                .min(0)
                .nullable()
        })
        .test({test: _requireBillingModel});
}

function _requireBillingModel(value) {
    if (!value.type?.includes('Uitgever')) {
        return true;
    } else if (!value.billingModel) {
        console.log('billingmodel');
        return this.createError({
            path: 'billingModel',
            message: 'Facturatiemodel moet gevuld zijn'
        });
    } else if (value.billingModel === BillingModelType.PERCENTAGE && !value.billingPercentage) {
        return this.createError({
            path: 'billingPercentage',
            message: 'Percentage moet gevuld zijn'
        });
    } else if (value.billingModel === BillingModelType.VASTE_PRIJS && !value.billingPrice) {
        return this.createError({
            path: 'billingPrice',
            message: 'Prijs moet gevuld zijn'
        });
    }
    return true;
}
