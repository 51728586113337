import {Fab, Grid, Paper, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    formInputBox: {
        width: 250,
        margin: theme.spacing(1)
    }
});

class Administrators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    q: Types.STRING,
                    rol: Types.NUMBER
                },
                props.history
            )
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    getAllowedActions() {
        let actions = ['EXTENDEDVIEW'];
        if (hasRight(this.props.authUserReducer, Recht.BEHEERDERS_AANPASSEN)) {
            actions.unshift('EDIT');
        }
        return actions;
    }

    render() {
        const {classes, authUserReducer, history} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Typography component="h1" variant="h5">
                    Beheerders
                </Typography>
                <Grid container justify={'flex-start'} className={classes.inputContainer}>
                    <Grid item>
                        <FormControl>
                            <SearchTextField
                                fullWidth
                                label={'e-mailadres of naam'}
                                autoFocus
                                value={this.state.filters.q}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, q: v}});
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name="role"
                                    endpoint="roles"
                                    labelKey="naam"
                                    value={this.state.filters.rol}
                                    onChange={(name, value) =>
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                rol: value && value.id
                                            }
                                        })
                                    }
                                    placeholder="Kies rol..."
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        endpoint="administrators"
                        version={this.state.version}
                        filter={this.state.filters}
                        onRowClick={data => {
                            if (data.id) {
                                history.push(`/beheerders/${data.id}/extended-view`);
                            }
                        }}
                        columns={[
                            {label: 'Naam', dataKey: 'name'},
                            {label: 'Email', dataKey: 'email'},
                            {label: 'Rol', dataKey: 'rol.naam'}
                        ]}
                    />
                </Grid>
                {hasRight(authUserReducer, Recht.BEHEERDERS_TOEVOEGEN) && (
                    <Fab color="secondary" aria-label="Add" className={classes.fab} component={Link} to="/beheerders/new">
                        <AddIcon />
                    </Fab>
                )}
            </Paper>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.BEHEERDERS_INZIEN), withStyles(styles))(Administrators);
