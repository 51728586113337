import DateFnsUtils from '@date-io/date-fns';
import {FormControlLabel, Grid, MenuItem, Paper, Switch, TextField, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {nl} from 'date-fns/locale';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import DynamicSelect from '../../components/dynamicselect';
import {isUitgeverAccount, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';
import {getSchoolYearName} from '../../services/schoolyear';
import SearchTextField from '../../components/input/searchtextfield';

const dateFns = new DateFnsUtils();

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    formInputBox: {
        width: 250,
        margin: theme.spacing(1),
        zIndex: 999,
    },
    searchButton: {
        marginLeft: theme.spacing(1),
    },
});

class Orderbalans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    contractId: Types.STRING,
                    showBasedOnContractId: Types.BOOLEAN,
                    distributor: Types.STRING,
                    uitgever: Types.STRING,
                    product: Types.STRING,
                    fromDate: Types.DATE,
                    toDate: Types.DATE,
                    schoolYear: Types.STRING,
                },
                props.history
            ),
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    handleContractIdSwitch(event) {
        this.setState({
            version: Number(new Date()),
            filters: {...this.state.filters, showBasedOnContractId: event.target.checked},
        });
    }
    render() {
        const {classes, authUserReducer} = this.props;
        return (
            <Grid>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Paper className={classes.root}>
                    <Typography gutterBottom component="h1" variant="h5">
                        Orderbalans
                    </Typography>
                    <Grid container justify="flex-start" className={classes.inputContainer}>
                        <Grid item>
                            <FormControl>
                                <SearchTextField
                                    fullWidth={true}
                                    className={classes.formInputBox}
                                    label="contract ID"
                                    value={this.state.filters.contractId}
                                    onEnter={() => this.reloadDataTable()}
                                    onChange={(v) => {
                                        this.setState({filters: {...this.state.filters, contractId: v, showBasedOnContractId: false}});
                                    }}
                                    autoFocus={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <DynamicSelect
                                    name="distributeur"
                                    endpoint="organisations"
                                    endpointFilter={{type: 'Distributeur'}}
                                    value={this.state.filters.distributor}
                                    valueKey="id"
                                    label="Distributeur..."
                                    labelKey="toString"
                                    placeholder="Distributeur..."
                                    onChange={(event) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                distributor: event.target.value,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {!isUitgeverAccount(authUserReducer) && (
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <AutoComplete
                                        name="uitgever"
                                        endpoint="organisations"
                                        endpointFilter={{type: 'Uitgever'}}
                                        value={this.state.filters.uitgever}
                                        labelKey="toString"
                                        placeholder="Uitgever..."
                                        onChange={(property, value) => {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    uitgever: value && value.id,
                                                },
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name="product"
                                    endpoint="products"
                                    value={this.state.filters.product}
                                    labelKey="toString"
                                    secondLabelKey="ean"
                                    placeholder="Product.."
                                    onChange={(property, value) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                product: value && value.id,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    label="Schooljaar"
                                    value={this.state.filters.schoolYearName}
                                    onChange={(e) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                'schoolYearName': e.target.value,
                                            },
                                        });
                                    }}
                                >
                                    <MenuItem value={getSchoolYearName(1)}>{getSchoolYearName(1)}</MenuItem>
                                    <MenuItem value={getSchoolYearName(0)}>{getSchoolYearName(0)}</MenuItem>
                                    <MenuItem value={getSchoolYearName(-1)}>{getSchoolYearName(-1)}</MenuItem>
                                    <MenuItem value={null}>Alles</MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl className={classes.formInputBox}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                    <KeyboardDatePicker
                                        autoOk
                                        clearable
                                        showTodayButton
                                        format="dd-MM-yyyy"
                                        label="Aangemaakt vanaf"
                                        margin="dense"
                                        inputVariant="outlined"
                                        onChange={(e) => {
                                            if (dateFns.isValid(e)) {
                                                this.setState({
                                                    filters: {
                                                        ...this.state.filters,
                                                        fromDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                    },
                                                });
                                            } else if (e == null) {
                                                this.setState({
                                                    filters: {
                                                        ...this.state.filters,
                                                        fromDate: null,
                                                    },
                                                });
                                            }
                                        }}
                                        value={this.state.filters.fromDate}
                                        invalidDateMessage=""
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <FormControl className={classes.formInputBox}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                    <KeyboardDatePicker
                                        autoOk
                                        clearable
                                        showTodayButton
                                        format="dd-MM-yyyy"
                                        label="Aangemaakt tot en met"
                                        margin="dense"
                                        inputVariant="outlined"
                                        onChange={(e) => {
                                            if (dateFns.isValid(e)) {
                                                this.setState({
                                                    filters: {
                                                        ...this.state.filters,
                                                        toDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                    },
                                                });
                                            } else if (e == null) {
                                                this.setState({
                                                    filters: {
                                                        ...this.state.filters,
                                                        toDate: null,
                                                    },
                                                });
                                            }
                                        }}
                                        value={this.state.filters.toDate}
                                        invalidDateMessage=""
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.searchButton}>
                            <SearchButton onClick={this.reloadDataTable.bind(this)} />
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" className={classes.inputContainer}>
                        <Grid item className={classes.switch}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={this.state.filters.showBasedOnContractId}
                                        onChange={this.handleContractIdSwitch.bind(this)}
                                        value={this.state.filters.showBasedOnContractId}
                                        color="primary"
                                    />
                                }
                                labelPlacement="start"
                                label={'Toon op basis van contract ID'}
                            />
                        </Grid>
                    </Grid>
                    <DataTable
                        csv
                        endpoint="orderstockdetails"
                        filter={this.state.filters}
                        version={this.state.version}
                        sortBy="stockBalance"
                        columns={[
                            {label: 'contract ID', dataKey: 'contractid', maxWidth: 200},
                            {label: 'distributor', dataKey: 'distributorname'},
                            {label: 'Product', dataKey: 'producttitle', bodyStyling: {paddingLeft: 40}},
                            {label: 'Ean', dataKey: 'productean', maxWidth: 200, bodyStyling: {paddingLeft: 65}},
                            {label: 'Besteld', dataKey: 'orderedamount', maxWidth: 150, bodyStyling: {paddingLeft: 60}},
                            {
                                label: 'Gespecificeerd',
                                dataKey: 'specifiedamount',
                                maxWidth: 150,
                                bodyStyling: {paddingLeft: 50},
                            },
                            {
                                label: 'ActivatieCodes',
                                dataKey: 'activationcodeamount',
                                maxWidth: 150,
                                bodyStyling: {paddingLeft: 50},
                            },
                            {label: 'Balans', dataKey: 'stockbalance', maxWidth: 150, bodyStyling: {paddingLeft: 35}},
                        ]}
                    />
                </Paper>
            </Grid>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.ORDERBALANS_INZIEN), withStyles(styles))(Orderbalans);
