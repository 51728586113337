import 'typeface-roboto';

import './index.css';
import './yuplocale';

import {Button, CssBaseline} from '@material-ui/core';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import {ToegangToastContainer} from './components/toast';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#00C2D1',
        },
        secondary: {
            main: '#FF7519',
        },
        error: {
            main: '#FF0000',
        },
    },
    status: {
        danger: 'orange',
    },
});

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
        <ToegangToastContainer />
    </MuiThemeProvider>,
    document.getElementById('root')
);
