import {Box, Button, withStyles} from '@material-ui/core';
import React, {useCallback, useEffect, useReducer} from 'react';

import elasticSearchReducer, {initialState} from '../../reducers/elasticSearchReducer';
import * as ActionCreators from '../../store/actioncreators';
import autodispatch from '../../store/autodispatch';
import MeldcodeGroep from '../meldcodegroep';

const styles = (theme) => ({
    expandArrow: {
        marginTop: theme.spacing(1),
    },
    maxWidth100: {
        maxWidth: '100%',
    },
    stickyRight: {
        marginLeft: 'auto',
    },
});

function MeldcodeOverzicht(props) {
    const {endpoint, filter, version, footer, classes, autoOpen, setMaxLogSize, maxLogSize, increaseLogBy} = props;
    const [state, dispatch] = useReducer(elasticSearchReducer, initialState);
    const {results} = state;
    const {fetchData} = autodispatch(ActionCreators, dispatch);

    const formatData = (results) => results.filter((result) => result.entries.length > 0).toJS();

    const doFetch = useCallback(() => {
        if (version > 0) {
            fetchData({endpoint, filter});
        }
    }, [endpoint, version]);

    useEffect(() => {
        doFetch();
    }, [endpoint, version]);

    return (
        <Box display={'flex'} flexDirection={'column'} flex={1}>
            <Box className={classes.maxWidth100} display={'flex'} flexGrow={1} flexDirection={'column'}>
                {results.size > 0 ? (
                    formatData(results).map((result) => (
                        <MeldcodeGroep key={result.meldcode} meldcodegroep={result} autoOpen={autoOpen === true} />
                    ))
                ) : (
                    <div>Geen activiteit gevonden</div>
                )}
                <span className={classes.expandArrow}>{footer(state)}</span>
            </Box>
            {maxLogSize && increaseLogBy && results.size === maxLogSize && (
                <Button
                    className={classes.stickyRight}
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        setMaxLogSize(maxLogSize + increaseLogBy);
                    }}
                >
                    <i className="material-icons">expand_more</i>
                </Button>
            )}
        </Box>
    );
}

export default withStyles(styles)(MeldcodeOverzicht);
