import * as React from 'react';

import EditPage from '../../../components/editpage';
import TLinkForm from './tlinkform';
import {compose} from "recompose";
import {Paper, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class TlinkEdit extends React.Component {
    static defaultProps = {
        endpoint: 'tlinklicenses',
        entityDescription: 'TLink'
    };

    render() {
        const {id, code} = this.props.match.params;
        const {entityDescription, endpoint, classes} = this.props;

        const editpageProps = {entityDescription, endpoint, formComponent: TLinkForm};

        if (code) editpageProps.filter = {licenseCode: code};
        else editpageProps.entityId = id;

        return (<Paper className={classes.root}><EditPage {...editpageProps} /></Paper>);
    }
}

export default compose(withStyles(styles))(TlinkEdit);