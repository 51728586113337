import {Button, Grid, Paper, Typography, withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, {useEffect, useReducer, useState} from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import MeldcodeOverzicht from '../../../components/meldcodeoverzicht';
import {hasRight, isUitgeverAccount} from '../../../components/permissions/withpermissions';
import PseudoIdTool from '../../../components/pseudoid/pseudoidtool';
import SimpleTable from '../../../components/simpletable';
import AccountAccessurlsTable from '../../../components/simpletemplates/accountaccessurlstable';
import AccountTLinksTable from '../../../components/simpletemplates/accounttlinkstable';
import BasicAccountInfo from '../../../components/simpletemplates/basicaccountinfo';
import ExternAccountsList from '../../../components/simpletemplates/externaccountslist';
import entityReducer, {initialState} from '../../../reducers/entityreducer';
import * as ActionCreators from '../../../store/actioncreators';
import autodispatch from '../../../store/autodispatch';
import defaultConnect from '../../../store/connector';
import config from '../../../config';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        margin: theme.spacing(1),
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    grid: {
        margin: theme.spacing(2),
        flexWrap: 'wrap',
    },
    inputContainer: {
        margin: theme.spacing(2),
        minWidth: 250,
    },
    gridItemWide: {
        margin: theme.spacing(2),
        minWidth: 350,
    },
    simpleTable: {
        minWidth: 250,
    },
    resendAccessUrlContainer: {
        margin: theme.spacing(1),
    },
    resendAccessUrlErrorMessage: {
        color: '#f00',
        marginTop: theme.spacing(1),
    },
    readOnlyText: {
        wordBreak: 'break-all',
        paddingRight: 20,
    },
    expandArrow: {
        marginTop: theme.spacing(1),
    },
    triggerBar: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        marginLeft: '-6px',
    },
    colorWhite: {
        color: '#fff',
    },
});

function AccountExtendedView(props) {
    const {classes, authUserReducer, match} = props;

    const [version, setVersion] = useState(Number(new Date()));
    const [maxLogSize, setMaxLogSize] = useState(5);

    const [state, dispatch] = useReducer(entityReducer, initialState);
    const {entity, loading, resending, resendError, errorStatus} = state;
    const {fetchItem, deleteItem, resendAccessUrl} = autodispatch(ActionCreators, dispatch);

    useEffect(() => {
        const {id} = match.params;
        if (id) {
            fetchItem({endpoint: 'accounts', id: id});
        }
    }, [match]);

    const mergeHistoryCols = [
        {
            header: 'Id',
            renderer: (row) => row.sourceAccountId,
        },
        {
            header: 'Contactgegeven',
            renderer: (row) => row.sourceAccountContact || '-',
        },
        {
            header: 'Gemerged op',
            renderer: (row) => moment(row.timestamp).format('DD-MM-YY'),
        },
        {
            header: 'Externe accounts',
            renderer: (row) => row.sourceAccountExternAccounts.map((ea) => ea.nameId).join(', ') || '-',
        },
        {
            header: 'TLinks',
            renderer: (row) => row.sourceAccountTlinks.map((tl) => tl.licenseCode).join(', ') || '-',
        },
        {
            header: 'Meldcodes',
            renderer: (row) => row.sourceAccountMeldcodes.join(', ') || '-',
        },
    ];

    if (loading) {
        return (
            <React.Fragment>
                <CircularProgress />
            </React.Fragment>
        );
    } else if (!loading && !entity) {
        let errorTitle, errorMessage;
        if (errorStatus && errorStatus === 403) {
            errorTitle = 'Toegang geweigerd';
            errorMessage = 'Je bent niet bevoegd om dit account in te zien.';
        } else if (errorStatus && errorStatus === 404) {
            errorTitle = 'Account niet gevonden';
            errorMessage = 'Account bestaat niet (meer). Mogelijk is dit account eerder samengevoegd met een ander account.';
        } else {
            errorTitle = 'Onbekende fout';
            errorMessage = 'Het ophalen van het account is mislukt';
        }
        return (
            <React.Fragment>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                    {errorTitle}
                </Typography>
                <Typography component="span" variant="subtitle1" color="inherit" noWrap>
                    {errorMessage}
                </Typography>
            </React.Fragment>
        );
    } else
        return (
            <React.Fragment>
                <Paper className={classes.root}>
                    <Grid container justify={'space-between'}>
                        <Grid>
                            <Typography component="h1" variant="h5" gutterBottom noWrap>
                                Overzicht {entity ? entity.toString : ''}
                            </Typography>
                        </Grid>
                        {hasRight(authUserReducer, Recht.GEBRUIKERS_SAMENVOEGEN) && entity && entity.id && (
                            <Grid>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.colorWhite}
                                    to={`/accounts/merge/${entity.id}/${entity.id}`}
                                    component={Link}
                                >
                                    Samenvoegen met...
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    { entity.pseudoId // if truthy we can suppose the current admin is a publisher
                        && hasRight(authUserReducer, Recht.PROFIELHUIS_ACCES) // if truthy we can suppose the publisher is Thieme
                        && <Button
                            color="primary"
                            variant="contained"
                            className={classes.colorWhite}
                            onClick={() => window.open(`${config.ph_beheer_url}/${entity.roles?.toLowerCase() === 'teacher' ? 'docenten' : 'leerlingen'}?pseudoId=%22${entity.pseudoId}%22`, '_blank')}
                        >
                            Naar profielhuis
                        </Button>
                    }
                    <BasicAccountInfo account={entity} />
                    <Grid container direction="row">
                        <Grid item xs={12} className={classes.gridItemWide}>
                            <Typography component="h1" variant="h5" gutterBottom noWrap>
                                Externe accounts
                            </Typography>
                            <ExternAccountsList account={entity} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.root}>
                    <Grid container direction="row">
                        <Grid item xs={12} className={classes.gridItemWide}>
                            <AccountTLinksTable entity={entity} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItemWide}>
                        <Typography component="h1" variant="h5" gutterBottom noWrap>
                            Verstuurde bevestigingslinks
                        </Typography>
                        <AccountAccessurlsTable
                            entity={entity}
                            version={version}
                            deleteItem={
                                hasRight(authUserReducer, Recht.ACCESSURLS_VERWIJDEREN)
                                    ? async (row) => {
                                          await deleteItem({endpoint: 'accessurls', id: row.id});
                                          setVersion(Date.now());
                                      }
                                    : undefined
                            }
                        />
                        {entity && entity.accessurlPresent && !entity.email && !entity.telephone && (
                            <div className={classes.resendAccessUrlContainer}>
                                {!resending ? (
                                    hasRight(authUserReducer, Recht.GEBRUIKERS_AANPASSEN) && (
                                        <Grid container direction="column" alignItems="flex-start">
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                className={classes.colorWhite}
                                                color="primary"
                                                type="button"
                                                onClick={async () => {
                                                    await resendAccessUrl(entity.id);
                                                    setVersion(Number(new Date()));
                                                }}
                                            >
                                                Stuur opnieuw{' '}
                                            </Button>
                                            {resendError && (
                                                <span className={classes.resendAccessUrlErrorMessage}>
                                                    Kon bevestigingslink niet opnieuw versturen
                                                </span>
                                            )}
                                        </Grid>
                                    )
                                ) : (
                                    <CircularProgress />
                                )}
                            </div>
                        )}
                    </Grid>
                    {!isUitgeverAccount(authUserReducer) && (
                        <Grid item xs={12} className={classes.gridItemWide}>
                            <Typography component="h1" variant="h5" gutterBottom noWrap>
                                Merge historie
                            </Typography>
                            <SimpleTable endpoint={`accounts/${entity.id}/logs/merge`} columns={mergeHistoryCols} />
                        </Grid>
                    )}
                    {!isUitgeverAccount(authUserReducer) && (
                        <Grid container direction="row">
                            <Grid item xs={4} className={classes.gridItemWide}>
                                <PseudoIdTool
                                    title={'Pseudoid genereren'}
                                    subjectPlaceholder={'id van de gebruiker'}
                                    description={
                                        'Tool om pseudoid te genereren. Pseudoids zijn ids voor uitgevers om zo gebruikers per uitgever een eigen id te geven.'
                                    }
                                    buttonValue={'Genereer pseudoid'}
                                    httpMethod={'GET'}
                                    endpoint={(state) => `accounts/${state.subject}/idfor/${state.publisher.id}`}
                                    responseFilter={(response) => response.data}
                                    defaultSubject={entity.id}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Paper>
                <Paper className={classes.root}>
                    <Grid item xs={12} className={classes.gridItemWide}>
                        <Typography component="h1" variant="h5" gutterBottom noWrap>
                            Activiteit
                        </Typography>
                        <MeldcodeOverzicht
                            version={Number(new Date())}
                            endpoint={`accounts/${entity.id}/activity`}
                            filter={{amount: maxLogSize}}
                            setMaxLogSize={setMaxLogSize}
                            maxLogSize={maxLogSize}
                            increaseLogBy={5}
                            footer={({fetching}) => {
                                return (
                                    fetching && (
                                        <React.Fragment>
                                            <CircularProgress />
                                        </React.Fragment>
                                    )
                                );
                            }}
                        />
                    </Grid>
                </Paper>
                {hasRight(authUserReducer, Recht.GEBRUIKERS_AANPASSEN) && (
                    <Fab
                        color="secondary"
                        aria-label="Edit"
                        className={classes.fab}
                        component={Link}
                        to={`/accounts/${entity.id}/edit`}
                    >
                        <EditIcon />
                    </Fab>
                )}
            </React.Fragment>
        );
}

export default compose(defaultConnect, withStyles(styles))(AccountExtendedView);
