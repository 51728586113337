import * as Actions from '../store/actions';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.IMPORT_OK:
            return {...state, log: action.data, importFailed: false};
        case Actions.IMPORT_FAILED:
            return {...state, importFailed: true};
        case Actions.CLEAR_IMPORT:
            return {...state, log: undefined, importFailed: false};
        default:
            return state;
    }
};
