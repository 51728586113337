import * as moment from 'moment';
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default function getValidationSchema() {
    return Yup.object()
        .shape({
            amountToCreate: Yup.number()
                .required()
                .min(1)
                .max(5000)
                .integer(),
            licenseState: Yup.mixed().required(),
            product: Yup.mixed()
                .nullable()
                .required(),
            account: Yup.mixed().nullable(),
            organisation: Yup.mixed().nullable(),
            startDate: Yup.date()
                .required()
                .nullable(),
            activationDate: Yup.date().nullable(),
            endDate: Yup.date()
                .nullable()
                .required()
        })
        .test({test: _test});
}

function _test(value) {
    // if the product used is not valid, block the create/update request to the back-end
    // without this check, the endDate entered by the user might be silently changed by the back-end, which is not user-friendly
    if (value.product && value.product.licenseModel && value.product.licenseModel.licenseActivationType === 'PERIODE' && !value.product.licenseModel.activatableUntil) {
        return this.createError({
            path: 'product',
            message: '"Te activeren tot" ontbreekt op het product. Corigeer dit alstublieft voordat u verder gaat.'
        });
    }

    if (value.startDate < value.endDate) {
        if (
            moment(value.product.endOfLifeDate)
                .add(1, 'days')
                .isAfter(value.endDate)
        ) {
            return true;
        }
        return this.createError({
            path: 'endDate',
            message: 'Einddatum mag niet na einddatum product liggen'
        });
    }
    return this.createError({
        path: 'endDate',
        message: 'Einddatum moet na startdatum liggen.'
    });
}
