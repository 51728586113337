import {Paper, withStyles} from '@material-ui/core';
import * as React from 'react';
import {compose} from 'recompose';

import EditPage from '../../../components/editpage';
import ServiceWindowForm from './servicewindowform';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class ServiceWindowEdit extends React.Component {
    static defaultProps = {
        endpoint: 'servicewindows',
        entityDescription: 'Service window'
    };

    render() {
        const {entityDescription, endpoint, classes} = this.props;
        const {id, product} = this.props.match.params;
        return (
            <Paper className={classes.root}>
                <EditPage
                    endpoint={endpoint}
                    entityDescription={entityDescription}
                    formComponent={ServiceWindowForm}
                    entityId={id}
                    product={product}
                />
            </Paper>
        );
    }
}

export default compose(withStyles(styles))(ServiceWindowEdit);
