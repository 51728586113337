/**
 * Zorgt ervoor dat de actionscreators "zichzelf dispatchen" (wat redux mapDispatchToProps ook doet)
 * en bovendien in redux-thunk stijl kunnen zijn (dus met dispatch als argument).
 *
 * Hiermee kunnen we dezelfde actioncreators blijven gebruiken maar nu met de useReducer hook:
 *
 * const [state,dispatch] = useReducer(reducer,inititalState);
 * const {fetchData} = autodispatch(actioncreators, dispatch);
 */
export default function autodispatch(actioncreators, dispatch) {
    function thunkdispatch(actionOrThunk) {
        if (typeof actionOrThunk === 'function') {
            return actionOrThunk(thunkdispatch);
        } else {
            dispatch(actionOrThunk);
            return actionOrThunk;
        }
    }
    const ret = {};
    for (let name of Object.getOwnPropertyNames(actioncreators)) {
        if (!name.startsWith('_')) {
            ret[name] = function(...args) {
                const actionOrThunk = actioncreators[name](...args);
                return thunkdispatch(actionOrThunk);
            };
        }
    }
    return ret;
}
