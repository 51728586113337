console.log("You are in the new deploy (TO REMOVE)");

const determineEnv = () => {
    if (typeof window === 'undefined') return 'serverbuild';
    const origin = window.location?.origin ?? '';
    if (origin.startsWith('http://localhost:8094')) return 'local';
    if (origin.startsWith('https://test-beheer.toegang.org')) return 'test';
    if (origin.startsWith('https://acc-beheer.toegang.org')) return 'acceptance';
    if (origin.startsWith('https://prod-beheer.toegang.org') || origin.startsWith('https://prod-beheer.toegang.org')) return 'production';
    return 'unknown';
};

// copied from ui/config/config.js (it's only used in this project so it can be here)
// some of the following properties seem to be necessary for react-oidc, but it's unclear which ones 

const config = {
    oauth_idp_base: 'http://localhost:8090/oidc',
    supportURL: 'http://support.toegang.org',
    supportPhoneNumber: '038-7114424',
    oauth_timeout: 3000,
    welcome_text: 'Welkom!',
    core_api: 'http://localhost:8091',
    log_api: 'http://localhost:9000',
    // this property is used
    // toeg_env: process.env.REACT_APP_TOEG_ENV || process.env.TOEG_ENV,
    toeg_env: determineEnv(),
    base: 'http://localhost:8090',
    // with create-react-app, process.env is read at build time
    // so process.env.REACT_APP_BRANCH and process.env.BRANCH will be undefined
    // this is not a problem because we don't use the buildstraat anymore
    branch: process.env.REACT_APP_BRANCH || process.env.BRANCH,
};

if (config.toeg_env === 'local') {
    config.debug = true;
} else if (config.toeg_env === 'test') {
    config.oauth_idp_base = 'https://test-idp.toegang.org/oidc';
    config.core_api = 'https://test-api.toegang.org';
    config.log_api = 'https://test-log.toegang.org';
    config.base = 'https://test-idp.toegang.org';
} else if (config.toeg_env === 'buildstraat') {
    config.oauth_idp_base = `https://${config.branch}-dot-idp-dot-toegang-test.appspot.com/oidc`;
    config.core_api = `https://${config.branch}-dot-toegang-test.appspot.com`;
    config.log_api = `https://${config.branch}-dot-logging-dot-toegang-test.appspot.com`;
    config.base = `https://${config.branch}-dot-idp-dot-toegang-test.appspot.com`;
}
if (config.toeg_env === 'acceptance') {
    config.oauth_idp_base = 'https://acc-idp.toegang.org/oidc';
    config.core_api = 'https://acc-api.toegang.org';
    config.log_api = 'https://acc-log.toegang.org';
    config.base = 'https://acc-idp.toegang.org';
} else if (config.toeg_env === 'production') {
    config.oauth_idp_base = 'https://prod-idp.toegang.org/oidc';
    config.core_api = 'https://prod-api.toegang.org';
    config.log_api = 'https://prod-log.toegang.org';
    config.base = 'https://prod-idp.toegang.org';
}




config.oauth_client = '9397a8e8-0afe-4c77-9fdc-c9f9be8c854a';
config.oauth_callback = 'http://localhost:8094/oauth';
config.base_uri = 'http://localhost:8094/';
config.push_public_key = 'BM0-zOyfqjgITKWq9WQEsH-SBm6iDgA1vTp865zztIyr1kPELmwZ23wRiaC6XDToIHmvgZJIzLkAxwhe5RJjAaE';
config.ph_beheer_url = 'http://localhost:3001';

if (config.toeg_env === 'test') {
    config.base_uri = 'https://test-beheer.toegang.org/';
    config.oauth_callback = config.base_uri + 'oauth';
    config.push_public_key = 'BJDullrk5cN7_UsPC5Raz6jD1oX435e0sQux3HT8uRXL8C_WwPg1lsgKZSOna0AiX-6g0S5INPdKSPD4lEpnBR0';
    config.ph_beheer_url = 'https://profiel.ontwikkel.toegang.org';
}
if (config.toeg_env === 'acceptance') {
    config.base_uri = 'https://acc-beheer.toegang.org/';
    config.oauth_callback = config.base_uri + 'oauth';
    config.push_public_key = 'BE59O9s1iSI5dbmH9oW6qycujwWounbWzSKFmXZGqx4ukBT5w8NCPhAcQCm_o1tMZFMfW0DZhkMfy8RrwNatIKc';
    config.ph_beheer_url = 'https://acc.profiel.thiememeulenhoff.nl';
}
// if (config.toeg_env === 'buildstraat') {
//     config.base_uri = `https://${config.branch}-dot-beheer-dot-toegang-ontwikkel.appspot.com/`;
//     config.oauth_callback = config.base_uri + 'oauth';
//     config.push_public_key = 'BJDullrk5cN7_UsPC5Raz6jD1oX435e0sQux3HT8uRXL8C_WwPg1lsgKZSOna0AiX-6g0S5INPdKSPD4lEpnBR0';
// }
if (config.toeg_env === 'production') {
    config.base_uri = 'https://prod-beheer.toegang.org/';
    config.oauth_callback = config.base_uri + 'oauth';
    config.push_public_key = 'BI4hdqJdmbs3cUigQ0qKDJI9cREvQVw3Lj58KQaOIHzvmap8VtgrIjdbK3gt7-flDBvISaKco6bQS2DH5J_x91o';
    config.ph_beheer_url = 'https://profiel.thiememeulenhoff.nl';
}

export default config;
