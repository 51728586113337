import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';

import {getAccountContactgegeven} from '../../services/util';
import {mapStateToProps} from '../../store/connector';
import GenericField from '../input/genericfield';
import ReadOnly from '../input/readonly';

import {Recht} from 'shared-types';
import {hasRight} from '../../components/permissions/withpermissions';

const styles = (theme) => ({
    grid: {
        padding: theme.spacing(2),
        flexWrap: 'wrap',
        width: '100%',
    },
    confirmed: {
        color: '#339900',
        fontweight: 'bold',
    },
    unconfirmed: {
        color: '#CC3300',
        fontweight: 'bold',
    },
    fill: {
        width: '100%',
    },
    scroll: {
        padding: 0,
        margin: 0,
        maxHeight: 100,
        overflow: 'auto',
        listStyleType: 'none',
    },
    dateContainer: {
        marginBottom: theme.spacing(1),
    },
});

const BasicAccountInfo = (props) => {
    const {classes, account, authUserReducer} = props;

    return (
        <Grid container className={classes.grid}>
            <Grid container direction="row" className={classes.dateContainer}>
                <Grid item xs={3}>
                    <GenericField label="Aangemaakt">
                        <ReadOnly>{new Date(account.createdAt).toLocaleString()}</ReadOnly>
                    </GenericField>
                </Grid>
                <Grid item xs={6}>
                    <GenericField label="Laatst gewijzigd" style={{width: '100%'}}>
                        <ReadOnly>{new Date(account.updatedAt).toLocaleString()}</ReadOnly>
                    </GenericField>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={3}>
                    <GenericField label="Contactgegeven">
                        <ReadOnly>{getAccountContactgegeven(account)}</ReadOnly>
                    </GenericField>
                </Grid>
                <Grid item xs={3}>
                    <GenericField label="Weergavenaam">
                        <ReadOnly>{account?.attributes?.given_name ?? ""}</ReadOnly>
                    </GenericField>
                </Grid>
                {authUserReducer &&
                    authUserReducer.beheerder &&
                    (!authUserReducer.beheerder.uitgever || authUserReducer.beheerder.uitgever.askForVolledigeNaam) && (
                        <React.Fragment>
                            <Grid item xs={3}>
                                <GenericField label="Voornaam">
                                    <ReadOnly>
                                        {account.attributes?.first_name ||
                                            _getAttributeFromFirstExternAccount(account, 'givenName') ||
                                            '-'}
                                    </ReadOnly>
                                </GenericField>
                            </Grid>
                            <Grid item xs={3}>
                                <GenericField label="Tussenvoegsels">
                                    <ReadOnly>
                                        {account.attributes?.tussenvoegsels ||
                                            _getAttributeFromFirstExternAccount(account, 'nlEduPersonTussenvoegsels') ||
                                            '-'}
                                    </ReadOnly>
                                </GenericField>
                            </Grid>
                            <Grid item xs={3}>
                                <GenericField label="Achternaam">
                                    <ReadOnly>
                                        {account.attributes?.family_name ||
                                            _getAttributeFromFirstExternAccount(account, 'sn') ||
                                            '-'}
                                    </ReadOnly>
                                </GenericField>
                            </Grid>
                        </React.Fragment>
                    )}
                <Grid item xs={3}>
                    <GenericField label="Status">
                        <ReadOnly>
                            {account.validated === false || (!account.telephone && !account.email) ? (
                                <span className={classes.unconfirmed}>Niet bevestigd</span>
                            ) : (
                                <span className={classes.confirmed}>Bevestigd</span>
                            )}
                        </ReadOnly>
                    </GenericField>
                </Grid>
                {account.pseudoId && (
                    <Grid item xs={3}>
                        <GenericField label="PseudoId">
                            <ReadOnly>{account.pseudoId}</ReadOnly>
                        </GenericField>
                    </Grid>
                )}
                {hasRight(authUserReducer, Recht.SCHOOLJAARVAKCONTRACTEN_INZIEN) && (
                    <Grid item xs={3}>
                        <GenericField label="Leerjaar">
                            <ReadOnly>{formatGrades(account)}</ReadOnly>
                        </GenericField>
                    </Grid>
                )}
                {hasRight(authUserReducer, Recht.SCHOOLJAARVAKCONTRACTEN_INZIEN) && (
                    <Grid item xs={3}>
                        <GenericField label="Gesynchroniseerd">
                            <ReadOnly>{account.isFromTMProfielFromThisYear ? "Ja" : "Nee"}</ReadOnly>
                        </GenericField>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

BasicAccountInfo.propTypes = {
    account: PropTypes.object.isRequired,
};

const _getAttributeFromFirstExternAccount = (account, attributeName) => {
    if (!account || !account.externAccounts || !account.externAccounts.length > 0) return '';

    let firstExternAccount = account.externAccounts.find((externAccount) => externAccount.accountType === 'KNF');
    if (!firstExternAccount) {
        firstExternAccount = account.externAccounts.find((externAccount) => externAccount.accountType === 'BP');
    }

    const rowValue = firstExternAccount.attributes?.[attributeName] || '';
    console.log(rowValue);
    return rowValue;
};

const formatGrades = rowData => {
    const grades = rowData.grades ?? "-";
    return grades.replace(/JAAR_/g, "").split(",").join(", ");
}

export default compose(
    connect(mapStateToProps, () => {
        return {};
    }),
    withStyles(styles)
)(BasicAccountInfo);
