/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default Yup.setLocale({
    mixed: {
        default: 'Veld is niet geldig',
        notType: ({type}) => {
            switch (type) {
                case 'date':
                    return 'Ongeldige datum';
                case 'number':
                    return 'Moet uit cijfers bestaan';
                default:
                    return 'Verkeerde type';
            }
        },
        required: 'Veld is verplicht'
    },
    string: {
        max: 'Mag maximaal ${max} tekens zijn',
        url: '${path} moet een URL zijn'
    },
    number: {
        min: 'Moet minimaal ${min} zijn',
        max: 'Mag maximaal ${max} zijn',
        required: 'Veld is verplicht',
        integer: 'Veld moet een geheel getal zijn'
    }
});
