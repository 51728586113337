import {OutlinedInput, TextField, withStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, {useCallback, useEffect, useReducer, useState} from 'react';

import organisationStyleReducer, {initialState} from '../../reducers/organisationstyle';
import * as ActionCreators from '../../store/actioncreators';
import autodispatch from '../../store/autodispatch';

const styles = theme => ({
    organisationStyle: {
        width: 250
    }
});

function OrganisationStyleSelect(props) {
    const {organisation, classes, endpoint} = props;

    const [state, dispatch] = useReducer(organisationStyleReducer, initialState);
    const {item, success, error, loading} = state;
    const {fetchData, updateData, postData} = autodispatch(ActionCreators, dispatch);

    useEffect(() => {
        fetchData({endpoint: 'organisationstyles', filter: {organisation}});
    }, [endpoint, organisation]);

    function handleInput(value) {
        const entity = {...item, organisation: organisation, name: value};
        if (value === 'THIEME') {
            entity.abbreviation = 'TM';
        } else {
            entity.abbreviation = null;
        }
        if (item && item.id) {
            updateData({
                endpoint: `organisationstyles/${item.id}`,
                entity
            }).then(ok => {
                if (ok) {
                    fetchData({
                        endpoint: 'organisationstyles',
                        filter: {organisation}
                    });
                }
            });
        } else {
            postData({endpoint: 'organisationstyles', entity, responseType: 'ENTITIES'});
        }
    }

    return (
        <TextField
            className={classes.organisationStyle}
            select
            margin="dense"
            label="Stijl"
            variant="outlined"
            value={(item && item.name) || 'TOEGANG'}
            onChange={e => handleInput(e.target.value)}
            input={<OutlinedInput />}
        >
            <MenuItem value="TOEGANG">Toegang.org</MenuItem>
            <MenuItem value="THIEME">ThiemeMeulenhoff</MenuItem>
        </TextField>
    );
}

export default withStyles(styles)(OrganisationStyleSelect);
