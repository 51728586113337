import DateFnsUtils from '@date-io/date-fns';
import {Button, CardContent, FormControl, Grid, Input, InputLabel, TextField, Typography, withStyles} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import classNames from 'classnames';
import {nl} from 'date-fns/locale';
import {Form, Formik} from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import MarkdownRenderer from 'react-markdown-renderer';
import {withRouter} from 'react-router-dom';

import GenericField from '../../../components/input/genericfield';
import ReadOnly from '../../../components/input/readonly';
import {toastError, toastPromise, toastSuccess} from '../../../components/toast';
import getValidationSchema from './getValidationSchema';

const endpoint = 'servicewindows';

const styles = (theme) => ({
    root: {
        padding: 0,
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    checkbox: {
        marginLeft: theme.spacing(1),
    },
    checkboxContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    gridContainer: {
        padding: theme.spacing(5),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: 'absolute',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(10),
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
});

function ServiceWindowForm(props) {
    const {classes, entity, product, history, postSuccess} = props;

    React.useEffect(() => {
        if (postSuccess) {
            redirectToOverviewScreen();
        }
    });
    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };

    const redirectToOverviewScreen = () => {
        history.push(`/producten/${product}/extended-view`);
    };
    return (
        <CardContent className={classes.root}>
            <Typography component="h1" variant="h5" noWrap GutterBottom>
                {entity ? `Service window bewerken` : `Service window instellen`}
            </Typography>
            <Formik
                initialValues={{
                    id: entity ? entity.id : null,
                    startDate: entity ? entity.startDate : moment(),
                    endDate: entity && entity.endDate ? entity.endDate : null,
                    redirectAfterSec: entity ? entity.redirectAfterSec : -1,
                    message: entity ? entity.message : '',
                    // Dit veld wordt niet getoond in de UI:
                    product: product,
                }}
                validationSchema={getValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;

                    const submitting = _doSubmit(
                        values,
                        values.id ? updateData : postData,
                        values.id ? `${endpoint}/${values.id}` : endpoint
                    );
                    toastPromise(
                        submitting,
                        'Aan het opslaan...',
                        'De Service Window is opgeslagen.',
                        (e) => `De Service Window kon niet worden opgeslagen. ${e.message || e.error.message || ''}.`,
                        null,
                        true
                    )
                        .then((result) => {
                            actions.setSubmitting(false);
                        })
                        .catch((e) => {
                            actions.setSubmitting(false);
                        });
                }}
                render={({values, touched, errors, isSubmitting, setFieldValue}) => (
                    <Form onKeyDown={_detectEsc}>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField
                                multiline
                                error={Boolean(errors.message)}
                                value={values.message}
                                label="Servicebericht"
                                onChange={(e) => {
                                    setFieldValue('message', e.target.value, false);
                                }}
                            />
                            {errors.message && <span className={classes.error}>{errors.message}</span>}
                        </FormControl>
                        <Grid container direction="row">
                            <Grid item xs={2}>
                                <FormControl className={classes.formControl}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            format="dd-MM-yyyy HH:mm"
                                            ampm={false}
                                            label="Begindatum"
                                            margin="normal"
                                            onChange={(e) => {
                                                setFieldValue('startDate', e, false);
                                            }}
                                            value={values.startDate}
                                            invalidDateMessage=""
                                            error={Boolean(errors.startDate)}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {errors.startDate && <span className={classes.error}>{errors.startDate}</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl className={classes.formControl}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            format="dd-MM-yyyy HH:mm"
                                            ampm={false}
                                            label="Einddatum"
                                            margin="normal"
                                            onChange={(e) => {
                                                setFieldValue('endDate', e, false);
                                            }}
                                            value={values.endDate}
                                            invalidDateMessage=""
                                            error={Boolean(errors.endDate)}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {errors.endDate && <span className={classes.error}>{errors.endDate}</span>}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl className={classes.formControl}>
                            <TextField
                                value={values.redirectAfterSec}
                                label="Redirect na (sec)"
                                onChange={(e) => {
                                    setFieldValue('redirectAfterSec', e.target.value, false);
                                }}
                                helperText="-1 voor geen redirect"
                            />
                            {errors.ean && <span className={classes.error}>{errors.redirectAfterSec}</span>}
                        </FormControl>
                        {values.message && (
                            <Grid container>
                                <FormControl className={classes.formControl}>
                                    <GenericField>
                                        <ReadOnly>Preview:</ReadOnly>
                                    </GenericField>
                                    <MarkdownRenderer markdown={values.message} />
                                </FormControl>
                            </Grid>
                        )}
                        <Grid container direction="row" className={classes.actionButtonContainer}>
                            <Button
                                className={classNames(classes.button, classes.saveButton)}
                                variant="contained"
                                size="medium"
                                color="secondary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Opslaan
                            </Button>
                            <Button
                                variant="contained"
                                size="medium"
                                className={classNames(classes.button, classes.cancelButton)}
                                onClick={(e) => history.goBack()}
                                disabled={isSubmitting && !errors}
                            >
                                <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Annuleren
                            </Button>
                        </Grid>
                    </Form>
                )}
            />
        </CardContent>
    );
}

const _doSubmit = (values, syncData, endpoint) => {
    // Hack, omdat Yup moeilijk doet over nullable nummer velden, in combinatie met de cast van hieronder (TOEG-993)
    if (!values.redirectAfterSec) {
        values.redirectAfterSec = -1;
    }
    return syncData({
        endpoint: endpoint,
        entity: getValidationSchema().cast(values),
    });
};

export default withRouter(withStyles(styles)(ServiceWindowForm));
