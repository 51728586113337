import config from '../config';
import store from '../store/store';

export function getAccessToken() {
    const user = store.getState().oidc.user;
    return user && user.access_token;
}

export async function fetchAdministrator() {
    const access_token = getAccessToken();
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${access_token}`,
    };
    return fetch(`${config.core_api}/administrators/me`, {headers}).then((res) => {
        if (res.ok) {
            return res.json().then((beheerder) => beheerder);
        } else {
            throw Object.assign(new Error(), {status: res.status});
        }
    });
}

export function fetchData({endpoint, sortBy, sortDirection, filter, range, signal}) {
    const accessToken = getAccessToken();
    const headers = {Accept: 'application/json'};
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }

    if (range && range.start >= 0) {
        const end = range.end ? range.end : range.start + 99;
        headers.Range = `items=${range.start}-${end}`;
    }

    const params = Object.assign({}, filter);
    if (sortBy) {
        const sortPrefix = sortDirection ? sortDirection + '-' : '';
        params['sort'] = sortPrefix + sortBy;
    }
    let paramString = Object.keys(params)
        .filter((k) => params[k])
        .map((k) => `${k}=${encodeURIComponent(params[k])}`)
        .join('&');
    paramString = paramString && '?' + paramString;

    return fetch(`${config.core_api}/${endpoint}${paramString}`, {headers, signal}).then((res) => {
        if (!res.ok) {
            throw Object.assign(new Error(), {status: res.status});
        }

        return res.json().then((data) => {
            const range = getResponseRange(res) || {start: 0, end: data.length - 1, totalSize: data.length};
            return {data, range};
        });
    });
}

export function fetchItem({endpoint, id}) {
    const access_token = getAccessToken();
    const headers = {Accept: 'application/json'};
    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }

    return fetch(`${config.core_api}/${endpoint}/${id}`, {headers}).then((res) => {
        if (!res.ok) {
            throw Object.assign(new Error(), {status: res.status});
        }
        return res.json().then((data) => {
            return data;
        });
    });
}

export async function deleteItem({endpoint, id}) {
    const access_token = getAccessToken();
    const headers = {Authorization: `Bearer ${access_token}`};
    const method = 'DELETE';
    const res = await fetch(`${config.core_api}/${endpoint}/${id}`, {headers, method});
    if (!res.ok) {
        const data = await res.json();
        const err = Object.assign(new Error(), {status: res.status});
        if (res.status === 409) {
            err.failedEntities = data;
        } else {
            err.message = data.message;
        }
        throw err;
    }
}

export async function postData({endpoint, entity, parameters, responseType}) {
    const access_token = getAccessToken();
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }
    if (responseType) {
        headers['X-Toegang-Response-Type'] = responseType;
    }

    let params = Object.assign({}, parameters);
    let paramString = Object.keys(params)
        .filter((k) => params[k] !== undefined)
        .map((k) => `${k}=${params[k]}`)
        .join('&');
    paramString = paramString && '?' + paramString;
    const res = await fetch(`${config.core_api}/${endpoint}${paramString}`, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify(entity),
    });

    if (!res.ok) {
        const data = await res.json();
        throw Object.assign(new Error(), {status: res.status, message: data.message});
    }

    // 204 = No content
    if (res.status !== 204) {
        return res.json();
    }
}

export function updateData({endpoint, entity, parameters}) {
    const access_token = getAccessToken();
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }

    let params = Object.assign({}, parameters);
    let paramString = Object.keys(params)
        .filter((k) => params[k] !== undefined)
        .map((k) => `${k}=${params[k]}`)
        .join('&');
    paramString = paramString && '?' + paramString;

    return fetch(`${config.core_api}/${endpoint}${paramString}`, {
        headers: headers,
        method: 'PUT',
        body: JSON.stringify(entity),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((data) => {
                throw Object.assign(new Error(), {status: res.status, message: data.message});
            });
        }
        return true;
    });
}

export function resetClientSecret(entity) {
    const access_token = getAccessToken();
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }

    return fetch(`${config.core_api}/oauthclients/${entity.id}/reset`, {
        headers: headers,
        method: 'GET',
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((data) => {
                throw Object.assign(new Error(), {status: res.status, message: data.message});
            });
        }
        return res.json();
    });
}

function getResponseRange(resp) {
    const ranges = resp.headers.get('Content-Range');
    if (!ranges) return;

    const resp_range = new RegExp('items (\\d+)-(\\d+)/(\\d+)').exec(ranges);
    const start = parseInt(resp_range[1]);
    const end = parseInt(resp_range[2]);
    const totalSize = parseInt(resp_range[3]);
    return {start, end, totalSize};
}

export function closeSupportTicket(key) {
    const access_token = getAccessToken();
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }

    return fetch(`${config.core_api}/supportentries`, {
        headers: headers,
        method: 'PUT',
        body: JSON.stringify({key}),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((data) => {
                throw Object.assign(new Error(), {status: res.status, message: data.message});
            });
        }
        return true;
    });
}

export function destroySession(session) {
    const access_token = getAccessToken();
    return fetch(`${config.oauth_idp_base}/account/destroy-session/${session.id}`, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
        method: 'PUT',
    }).then((res) => {
        if (res.ok) {
            return true;
        } else {
            throw Object.assign(new Error(), {status: res.status});
        }
    });
}

export function removeOAuthClientFromCache(id) {
    const access_token = getAccessToken();
    return fetch(`${config.oauth_idp_base}/cache/${id}`, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
        method: 'DELETE',
    }).then((res) => {
        if (res.ok) {
            return true;
        } else {
            throw Object.assign(new Error(), {status: res.status});
        }
    });
}

export function resendAccessUrl(accountId) {
    const access_token = getAccessToken();
    return fetch(`${config.oauth_idp_base}/account/resend`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({accountId}),
    }).then((res) => {
        if (!res.ok) {
            throw Object.assign(new Error(), {status: res.status});
        }
    });
}

export function exportProducts() {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/products/export`, {
        mode: 'cors',
        method: 'GET',
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    }).then((response) => {
        response.blob().then((blob) => {
            const file = window.URL.createObjectURL(blob);
            window.location.assign(file);
        });
    });
}

export function importProducts(body) {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/products/import`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        body: body,
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((data) => {
                throw Object.assign(new Error(), {status: res.status, message: data.message});
            });
        } else {
            return res.json();
        }
    });
}

export function mergeAccounts(sourceAccountId, destinationAccountId) {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/accounts/actions/merge`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({sourceAccountId, destinationAccountId}),
    }).then((res) => {
        if (!res.ok) {
            return new Promise((resolve, rej) => {
                res.json().then((msg) => {
                    rej(msg);
                });
            });
        }
    });
}

export function deleteFidpKoppeling(identiteitId, tiamSubject) {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/accounts/actions/deleteFidpKoppeling`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({identiteitId, tiamSubject}),
    }).then((res) => {
        if (!res.ok) {
            throw Object.assign(new Error(), {status: res.status});
        }
    });
}

export function koppelProfileIdAanFidp(accountId, profileId) {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/accounts/actions/koppelAanBestaandTmProfileId`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({accountId, profileId}),
    });
}

export function retryGenerateLicenseMessage(generateLicenseMessageId) {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/generatelicensemessages/${generateLicenseMessageId}/actions/retry`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
    }).then(async (res) => {
        if (!res.ok) {
            let msg;
            try {
                msg = JSON.parse(await res.text())?.message;
            } catch (e) {
                msg = `Something went wrong: ${e.message}`;
            }
            throw Object.assign(new Error(), {status: res.status, message: msg});
        }
    });
}

export function retryAllGenerateLicenseMessages() {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/generatelicensemessages/actions/retryall`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
    }).then(async (res) => {
        if (!res.ok) {
            let msg;
            try {
                msg = JSON.parse(await res.text())?.message;
            } catch (e) {
                msg = `Something went wrong: ${e.message}`;
            }
            throw Object.assign(new Error(), {status: res.status, message: msg});
        }
    });
}

export function retryAllFailedOrders() {
    const access_token = getAccessToken();
    return fetch(`${config.core_api}/orderdetails/actions/resendAllToPrius`, {
        mode: 'cors',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
    }).then(async (res) => {
        if (!res.ok) {
            let msg;
            try {
                msg = JSON.parse(await res.text())?.message;
            } catch (e) {
                msg = `Something went wrong: ${e.message}`;
            }
            throw Object.assign(new Error(), {status: res.status, message: msg});
        }
    });
}

export function fetchCsv({endpoint, sortBy, sortDirection, filter, csvHeaders}) {
    const access_token = getAccessToken();
    const headers = {Accept: 'text/csv; charset=utf-8'};
    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }
    let params = Object.assign({}, filter, {
        csvHeaders: Object.entries(csvHeaders)
            .map((o) => o.join('='))
            .join('|'),
    });
    if (sortBy) {
        const sortPrefix = sortDirection ? sortDirection + '-' : '';
        params['sort'] = sortPrefix + sortBy;
    }
    headers.range = 'items=0-';
    let paramString = Object.keys(params)
        .filter((k) => params[k] !== undefined && params[k] !== null && params[k] !== '')
        .map((k) => `${k}=${encodeURIComponent(params[k])}`)
        .join('&');
    if (endpoint.includes('?')) {
        if (endpoint.slice(-1) != '?') {
            paramString = paramString && '&' + paramString;
        }
    } else {
        paramString = paramString && '?' + paramString;
    }
    return fetch(`${config.core_api}/${endpoint}${paramString}`, {headers}).then((res) => {
        if (!res.ok) {
            throw Object.assign(new Error(), {status: res.status});
        } else {
            return {};
        }
    });
}

export function adjustInvoice(invoice) {
    const access_token = getAccessToken();

    return fetch(`${config.core_api}/invoices/actions/adjustInvoice`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        method: 'POST',
        body: JSON.stringify({invoice}),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((data) => {
                throw Object.assign(new Error(), {status: res.status, message: data.message});
            });
        }
    });
}

export function adjustGroups(id, groepen) {
    const access_token = getAccessToken();

    return fetch(`${config.core_api}/policenses/actions/adjustGroups`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
        },
        method: 'POST',
        body: JSON.stringify({id, groepen}),
    }).then((res) => {
        if (!res.ok) {
            return res.json().then((data) => {
                throw Object.assign(new Error(), {status: res.status, message: data.message});
            });
        }
    });
}
