import {IconButton} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import React from 'react';

import ConfirmationDialog from '../../components/confirmationdialog';
import {toastPromise} from '../../components/toast';
import * as RestClient from '../../services/restclient';
import defaultConnect from '../../store/connector';

function RetryPrius(props) {
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const entity = props.rowData;

    const resendToPrius = React.useCallback(async () => {
        const resendingToPrius = RestClient.postData({
            endpoint: `orderdetails/${entity.id}/actions/resendToPrius`,
            entity: {},
        }).then(() => {
            if (props.onFinish) {
                props.onFinish();
            }
        });

        toastPromise(
            resendingToPrius,
            'Aan het sturen...',
            'De order is klaargezet om opnieuw naar prius verstuurd te worden.',
            (e) => `Er is iets misgegaan met het klaarzetten voor prius: ${e.message}`
        );
    }, [entity]);
    return (
        <React.Fragment>
            <ConfirmationDialog
                title={`Opnieuw versturen ${entity.referenceId}`}
                open={openConfirmDialog}
                onConfirm={() => {
                    resendToPrius();
                }}
                onClose={() => setOpenConfirmDialog(false)}
            >
                Weet je zeker dat je order met referenceid <strong>{entity.referenceId}</strong> opnieuw naar prius wil versturen?
            </ConfirmationDialog>

            <IconButton onClick={() => setOpenConfirmDialog(true)} variant="outlined" size="small">
                <ReplayIcon />
            </IconButton>
        </React.Fragment>
    );
}

export default defaultConnect(RetryPrius);
