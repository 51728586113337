import * as React from 'react';

import EditPage from '../../../../components/editpage';
import PasswordForm from './passwordform';

export default class PasswordEdit extends React.Component {
    static defaultProps = {
        endpoint: 'administrators',
        entityDescription: 'Administrator'
    };

    render() {
        const {entityDescription, endpoint} = this.props;
        const {id} = this.props.match.params;
        return <EditPage endpoint={endpoint} entityDescription={entityDescription} formComponent={PasswordForm} entityId={id} />;
    }
}
