import {Fab, Grid, Paper, Typography, MenuItem, TextField, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Add as AddIcon, Info} from '@material-ui/icons';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import Tooltip from '@material-ui/core/Tooltip';

import React from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import DataTable from '../../components/datatable';
import {BooleanRenderer, InfoResolveRenderer} from '../../components/datatable/contentrenderers';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    formInputBoxSmall: {
        maxWidth: 100,
        minWidth: 100,
        margin: theme.spacing(1)
    },
    formInputBox: {
        maxWidth: 200,
        minWidth: 200,
        margin: theme.spacing(1)
    },
    formInputBoxLarge: {
        maxWidth: 350,
        minWidth: 350,
        margin: theme.spacing(1)
    },
    formInputBoxExtraLarge: {
        maxWidth: 550,
        minWidth: 550,
        margin: theme.spacing(1)
    },
    labelRoot: {
        fontSize: 'small'
    },
    fab: {
        color: '#fff'
    },
    fabContainer: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        bottom: theme.spacing(5),
        right: theme.spacing(5),

        '& a:not(:last-child)': {
            marginBottom: theme.spacing(1),
            position: 'absolute',
            transform: 'scale(0)',
            transitionDelay: '50ms',
            transition: 'transform cubic-bezier(0.94, 0.15, 1, 0.11) 300ms'
        },

        '&:hover a:not(:last-child)': {
            transition: 'transform ease 250ms',
            transform: 'scale(.85) translateY(-70px)'
        }
    }
});

class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    input: Types.STRING,
                    brin: Types.STRING,
                    userid: Types.STRING,
                    voornaam: Types.STRING,
                    tussenvoegsels: Types.STRING,
                    achternaam: Types.STRING,
                    rol: Types.NUMBER,
                    externid: Types.STRING,
                    digiDeliveryId: Types.STRING
                },
                props.history
            ),
            version: 0
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    getAllowedActions() {
        let actions = ['EXTENDEDVIEW'];
        if (hasRight(this.props.authUserReducer, Recht.GEBRUIKERS_AANPASSEN)) {
            actions.unshift('EDIT');
        }
        return actions;
    }

    getColumns = () => {
        const {authUserReducer} = this.props;

        const columns = [
            {
                label: 'Gegeven',
                dataKey: 'username'
            },
            {
                label: 'Naam',
                dataKey: 'name',
                component: <InfoResolveRenderer infoResolver={_getDisplayNameFromAccount} />
            },
            {
                label: 'BRIN',
                dataKey: 'nlEduPersonHomeOrganizationId',
                component: (
                    <InfoResolveRenderer
                        infoResolver={_getAttributeFromFirstKNFAccount('nlEduPersonHomeOrganizationId')}
                    />
                )
            },
            {
                label: 'Rol kennisnet',
                dataKey: 'eduPersonAffiliation',
                component: (
                    <InfoResolveRenderer
                        infoResolver={_getAttributeFromFirstKNFAccount('eduPersonAffiliation')}
                    />
                )
            },
            {
                label: 'DigiDeliveryid',
                dataKey: 'digiDeliveryId',
                component: (
                    <InfoResolveRenderer infoResolver={_getAttributeFromFirstKNFAccount('digiDeliveryId')} />
                )
            },
            {
                label: 'Bevestigd',
                dataKey: 'validated',
                component: <BooleanRenderer />,
                styling: {justifyContent: 'center'}
            }
        ];

        // show grades only to Thieme since the data comes mainly from TMProfiel
        if (hasRight(authUserReducer, Recht.SCHOOLJAARVAKCONTRACTEN_INZIEN)) {
            columns.push(                                {
                label: 'Leerjaar',
                dataKey: 'grades',
                component: (
                    <InfoResolveRenderer infoResolver={_formatGrades} />
                )
            },
            {
                label: (
                    <span>
                        Gesynchroniseerd
                        <Tooltip
                            placement={'top'}
                            title="Als het leerjaar is van TMProfiel gekomen dit schooljaar."
                        >
                            <Info fontSize={'small'} />
                        </Tooltip>
                    </span>
                ),
                dataKey: 'isFromTMProfielFromThisYear',
                component: <BooleanRenderer />
            });
        }

        return columns;
    }

    render() {
        const {classes, authUserReducer, history} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Grid container justify={'space-between'}>
                    <Grid>
                        <Typography component="h1" variant="h5">
                            Gebruikers
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify={'flex-start'} className={classes.inputContainer}>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Contactgegeven'}
                                autoFocus={true}
                                value={this.state.filters.input}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, input: v}});
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Voornaam'}
                                value={this.state.filters.voornaam}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, voornaam: v}});
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formInputBoxSmall}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Tussenvoegsels'}
                                value={this.state.filters.tussenvoegsels}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, tussenvoegsels: v}});
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Achternaam'}
                                value={this.state.filters.achternaam}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, achternaam: v}});
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Neppi, Nepri of EckId'}
                                value={this.state.filters.userid}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({
                                        filters: {...this.state.filters, userid: v}
                                    });
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBoxSmall}>
                            <SearchTextField
                                fullWidth={true}
                                label={'Brin'}
                                value={this.state.filters.brin}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, brin: v}});
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'DigiDeliveryId'}
                                value={this.state.filters.digiDeliveryId}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({
                                        filters: {...this.state.filters, digiDeliveryId: v}
                                    });
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {hasRight(authUserReducer, Recht.SCHOOLJAARVAKCONTRACTEN_INZIEN) && (
                        <Grid item>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={classes.formInputBox}
                                label="Leerjaar"
                                value={this.state.filters.grade}
                                onChange={e => {
                                    this.setState({filters: {...this.state.filters, grade: e.target.value}});
                                }}
                            >
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="7">7</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="">Alles</MenuItem>
                            </TextField>
                        </Grid>
                    )}
                    <Grid item className={classes.searchButton}>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        endpoint="accounts"
                        csv
                        customCsvHeaders={[
                            {
                                label: 'Voornaam',
                                dataKey: 'first_name',
                            },
                            {
                                label: 'Tussenvoegels',
                                dataKey: 'tussenvoegsels',
                            },
                            {
                                label: 'Achternaam',
                                dataKey: 'family_name',
                            },
                            {
                                label: 'nlEduPersonProfileId',
                                dataKey: 'nlEduPersonProfileId',
                            },
                            {
                                label: 'nlEduPersonRealId',
                                dataKey: 'nlEduPersonRealId',
                            },
                        ]}
                        version={this.state.version}
                        filter={this.state.filters}
                        columns={this.getColumns()}
                        restrictSearchLength={[
                            {name: 'input', minimalLength: 3},
                            {name: 'voornaam', minimalLength: 3},
                            {name: 'tussenvoegsels', minimalLength: 2},
                            {name: 'achternaam', minimalLength: 3},
                            {name: 'userid', minimalLength: 4},
                            {name: 'brin', minimalLength: 4},
                            {name: 'externid', minimalLength: 3}
                        ]}
                        onRowClick={data => {
                            if (data.id) {
                                history.push(`/accounts/${data.id}/extended-view`);
                            }
                        }}
                    />
                    <Grid className={classes.fabContainer}>
                        {hasRight(authUserReducer, Recht.GEBRUIKERS_SAMENVOEGEN) && (
                            <Fab
                                color="secondary"
                                className={classes.fab}
                                aria-label="Merge"
                                title="Gebruikers samenvoegen"
                                component={Link}
                                to="/accounts/merge"
                            >
                                <CallMergeIcon />
                            </Fab>
                        )}
                        {hasRight(authUserReducer, Recht.GEBRUIKERS_TOEVOEGEN) && (
                            <Fab
                                color="secondary"
                                className={classes.fab}
                                aria-label="Add"
                                title="Gebruiker toevoegen"
                                component={Link}
                                to="/accounts/new"
                            >
                                <AddIcon />
                            </Fab>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

const _getDisplayNameFromAccount = rowData => {
    return {title: 'Weergavenaam', rowValue: rowData?.attributes?.given_name ?? ""};
};

const _getAttributeFromFirstKNFAccount = knfAttribute => rowData => {
    if (!rowData || !rowData.externAccounts || !rowData.externAccounts.length > 0) return {title: '', rowValue: ''};

    const firstExternAccount = rowData.externAccounts.find(externAccount => externAccount.accountType === 'KNF');

    let title = rowData.externAccounts.map(a => (a.attributes && a.attributes[knfAttribute]) || 'N/A').join(', ');
    let rowValue = (firstExternAccount && firstExternAccount.attributes && firstExternAccount.attributes[knfAttribute]) || '';

    return {title, rowValue};
};

const _formatGrades = rowData => {
    const grades = rowData.grades ?? "";
    const rowValue = grades.replace(/JAAR_/g, "").split(",").join(", ");
    return {title: 'Leerjaar', rowValue};
}

export default compose(defaultConnect, withPermissions(Recht.GEBRUIKERS_INZIEN), withStyles(styles))(Accounts);
