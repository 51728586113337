export function loadFromSessionStorage(key) {
    let item = undefined;
    if (sessionStorageSupported()) {
        try {
            item = JSON.parse(sessionStorage.getItem(key));
        } catch (e) {
            console.log(
                'sessionStorage mag nu alleen nog maar JSON bevatten. Gooi je sessionStorage leeg om de applicatie weer te fixen.'
            );
        }
    }
    return item;
}

export function removeFromSessionStorage(key) {
    let item = undefined;
    if (sessionStorageSupported()) {
        try {
            sessionStorage.removeItem(key);
        } catch (e) {
            console.log('sessionStorage kon item niet verwijderen.');
        }
    }
    return item;
}

export function clearSessionStorage() {
    if (sessionStorageSupported()) {
        try {
            sessionStorage.clear();
        } catch (e) {
            console.log('sessionStorage kon niet worden geleegd.');
        }
    }
}

export function saveToSessionStorage(key, item) {
    if (sessionStorageSupported()) {
        sessionStorage.setItem(key, JSON.stringify(item));
    }
}

export function sessionStorageContains(key) {
    return sessionStorageSupported() && sessionStorage.getItem(key);
}

export function isSessionStorageEmpty() {
    return sessionStorageSupported() && sessionStorage.length > 0;
}

export function sessionStorageSupported() {
    return typeof window !== 'undefined' && sessionStorage;
}

export function loadFromLocalStorage(key) {
    let item = undefined;
    if (localStorageSupported()) {
        try {
            item = JSON.parse(localStorage.getItem(key));
        } catch (e) {
            console.log(
                'localStorage mag nu alleen nog maar JSON bevatten. Gooi je sessionStorage leeg om de applicatie weer te fixen.'
            );
        }
    }
    return item;
}

export function removeFromLocalStorage(key) {
    let item = undefined;
    if (localStorageSupported()) {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.log('localStorage kon item niet verwijderen.');
        }
    }
    return item;
}

export function clearLocalStorage() {
    if (localStorageSupported()) {
        try {
            localStorage.clear();
        } catch (e) {
            console.log('localStorage kon niet worden geleegd.');
        }
    }
}

export function saveToLocalStorage(key, item) {
    if (localStorageSupported()) {
        localStorage.setItem(key, JSON.stringify(item));
    }
}

export function localStorageContains(key) {
    return localStorageSupported() && localStorage.getItem(key);
}

export function isLocalStorageEmpty() {
    return localStorageSupported() && localStorage.length > 0;
}

export function localStorageSupported() {
    return typeof window !== 'undefined' && localStorage;
}

// is het access token verlopen of verloopt het binnen 1 minuut?
export function is_stale(access_token_expires_at) {
    return access_token_expires_at < new Date().getTime() + 60000;
}

export function hasSeenProduct(code) {
    return loadFromLocalStorage(`TGProduct${code}`) === true;
}

export function setSeenProduct(code) {
    saveToLocalStorage(`TGProduct${code}`, true);
}

export function setEditValidation(value) {
    if (value) {
        saveToSessionStorage('TGpref_editvalidation', value);
    } else {
        if (sessionStorage) {
            sessionStorage.removeItem('TGpref_editvalidation');
        }
    }
}

export function userWantsToEditValidation(value) {
    let setting = loadFromSessionStorage('TGpref_editvalidation');
    return setting ? setting : false;
}

export const Types = {
    STRING: 'string',
    OBJECT: 'object',
    BOOLEAN: 'boolean',
    NUMBER: 'number',
    DATE: 'date',
    ARRAY: 'array'
};

export function parseURLSearchParams(fields, history) {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const filters = {};
    for (let [key, value] of Object.entries(fields)) {
        const param = urlSearchParams.get(key);
        switch (value.type || value) {
            case Types.OBJECT:
                filters[key] = (param && JSON.parse(param)) || value.default;
                break;
            case Types.BOOLEAN:
                filters[key] = param !== null ? param === 'true' : value.default === true;
                break;
            case Types.STRING:
                filters[key] = param || value.default;
                break;
            case Types.NUMBER:
                filters[key] = Number(param) || value.default;
                break;
            case Types.DATE:
                filters[key] = param || null;
                break;
            case Types.ARRAY:
                filters[key] = (param && JSON.parse(param)) || [];
                break;
            default:
                break;
        }
    }
    return filters;
}

export function getAccountContactgegeven(account) {
    return account.email || account.telephone || '-';
}

export function convertArrayToCSV(arr, keys, delimiter = ';', lineBreak = '\n') {
    return keys.join(delimiter) + lineBreak + arr.map(it => keys.map(k => it[k]).join(delimiter)).join(lineBreak);
}
