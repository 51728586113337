import DateFnsUtils from '@date-io/date-fns';
import {
    Button,
    CardContent,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForever from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import classNames from 'classnames';
import {nl} from 'date-fns/locale';
import {Field, FieldArray, Form, Formik} from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import {Row} from 'react-materialize';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {BillingModelType, Recht} from 'shared-types';

import AutoComplete from '../../../components/autocomplete';
import ConfirmationDialog from '../../../components/confirmationdialog';
import {hasRight} from '../../../components/permissions/withpermissions';
import {toastPromise} from '../../../components/toast';
import {mapStateToProps} from '../../../store/connector';
import getValidationSchema from './getValidationSchema';

const endpoint = 'organisations';
const styles = (theme) => ({
    button: {
        marginLeft: theme.spacing(2),
    },
    checkbox: {
        marginLeft: theme.spacing(1),
    },
    checkboxContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
    flexDirectionRow: {
        flexDirection: 'row',
    },
});

const _doSubmit = (values, syncData, endpoint) => {
    return syncData({
        endpoint: endpoint,
        entity: getValidationSchema().cast(values),
    });
};

const _updateArpSince = (values, setFieldValue, value) => {
    if (!values.arpSince) {
        setFieldValue('arpSince', moment(), false);
    }
    setFieldValue('arp', value, false);
};

function OrganisatieForm(props) {
    const {classes, entity, deleteItem, history, authUserReducer, postSuccess, postError, postErrorMessage} = props;
    const [open, setOpen] = React.useState(false);
    const [canSelectBillingModel, setCanSelectBillingModel] = React.useState(false);
    const [canDelete, setCanDelete] = React.useState(false);

    React.useEffect(() => {
        if (hasRight(authUserReducer, Recht.ORGANISATIES_VERWIJDEREN) !== canDelete) {
            setCanDelete(hasRight(authUserReducer, Recht.ORGANISATIES_VERWIJDEREN));
        }
        if (hasRight(authUserReducer, Recht.IS_SUPERUSER) !== canSelectBillingModel) {
            setCanSelectBillingModel(hasRight(authUserReducer, Recht.IS_SUPERUSER));
        }
        if (postSuccess) {
            redirectToOverviewScreen();
        }
    });

    const _doDelete = async (entity, deleteItem) => {
        toastPromise(
            deleteItem({endpoint, id: entity.id}),
            'Aan het verwijderen...',
            'De organisatie is verwijderd.',
            (err) => {
                if (err.status === 409) {
                    let msg =
                        'Dit kan nu niet worden verwijderd omdat er nog andere entiteiten aan zijn gekoppeld. Verwijder eerst:\n';
                    if (err.failedEntities.summary) {
                        msg += err.failedEntities.summary;
                    } else {
                        for (let entity of err.failedEntities) {
                            msg += `${entity.toString} (${entity.entityType}#${entity.id})\n`;
                        }
                    }
                    return msg;
                } else {
                    return 'De organisatie kon niet worden verwijderd.';
                }
            },
            null,
            true
        )
            .then(() => redirectToOverviewScreen())
            .catch(() => redirectToOverviewScreen());
    };

    const _handleOrganisationTypeChange = (setFieldValue) => {
        setFieldValue('billingModel', null, false);
        setFieldValue('billingPrice', null, false);
        setFieldValue('billingPercentage', null, false);
    };

    const redirectToOverviewScreen = () => {
        history.push('/organisaties');
    };

    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };

    const _getAllowedOrganisationTypes = () => {
        if (hasRight(authUserReducer, Recht.ORGANISATIES_UITGEVERS_DISTRIBUTEURS_TOEGANG)) {
            return ['School', 'Uitgever', 'Distributeur'];
        } else {
            return ['School'];
        }
    };

    return (
        <CardContent>
            <ConfirmationDialog
                title="Weet je het zeker?"
                open={open}
                onConfirm={() => {
                    _doDelete(entity, deleteItem);
                }}
                onClose={(val) => setOpen(false)}
            >
                {entity && entity.name
                    ? `Wil je organisatie '${entity.name}' echt verwijderen?`
                    : 'Wil je deze organisatie echt verwijderen?'}
            </ConfirmationDialog>
            <Typography component="h1" variant="h5" gutterBottom noWrap>
                {entity ? `Organisatie bewerken` : `Organisatie toevoegen`}
            </Typography>
            <Formik
                initialValues={{
                    id: entity ? entity.id : null,
                    esn: entity ? entity.esn : '',
                    name: entity ? entity.name : '',
                    type: entity ? entity.type : ['School'],
                    websiteUrl: entity ? entity.websiteUrl : '',
                    brin: entity ? entity.brin : '',
                    instellingBrin: entity ? entity.instellingBrin : '',
                    sector: entity ? entity.sector : 'VO',
                    city: entity ? entity.city : '',
                    verified: entity ? entity.verified : false,
                    arp: entity ? entity.arp : false,
                    arpSince: entity ? entity.arpSince : null,
                    askForVolledigeNaam: entity ? entity.askForVolledigeNaam : false,
                    billingModel: entity && entity.billingModel ? entity.billingModel : null,
                    billingPrice: entity ? entity.billingPrice : null,
                    billingPercentage: entity ? entity.billingPercentage : null,
                    abonnementUrl: entity ? entity.abonnementUrl : '',
                    restrictedTo: entity ? entity.restrictedTo : null,
                }}
                validationSchema={getValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;
                    const submitting = _doSubmit(
                        values,
                        values.id ? updateData : postData,
                        values.id ? `${endpoint}/${values.id}` : endpoint
                    );
                    submitting
                        .then((result) => {
                            actions.setSubmitting(false);
                        })
                        .catch((e) => {
                            actions.setSubmitting(false);
                        });
                    toastPromise(
                        submitting,
                        'Aan het opslaan...',
                        'De organisatie is opgeslagen.',
                        (e) =>
                            `De organisatie kon niet worden opgeslagen${
                                e.message || e.error.message ? ': ' + (e.message ?? e.error.message) : ''
                            }.`
                    );
                }}
                render={({values, touched, errors, isSubmitting, setFieldValue, setFieldTouched}) => (
                    <Form onKeyDown={_detectEsc}>
                        <Grid container direction="column" className={classes.formContainer}>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    error={Boolean(errors.esn)}
                                    label={'ESN'}
                                    autoFocus={true}
                                    value={values.esn}
                                    onChange={(e) => {
                                        setFieldValue('esn', e.target.value, false);
                                    }}
                                />
                                {errors.esn && <span className={classes.error}>{errors.esn}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    error={Boolean(errors.name)}
                                    value={values.name}
                                    label={'Naam'}
                                    onChange={(e) => {
                                        setFieldValue('name', e.target.value, false);
                                    }}
                                />
                                {errors.name && <span className={classes.error}>{errors.name}</span>}
                            </FormControl>
                            <FormControl className={classes.checkboxContainer} required>
                                <FormLabel component="legend" className={classes.topSpacing}>
                                    Type
                                </FormLabel>
                                <FormGroup className={classes.flexDirectionRow}>
                                    <FieldArray
                                        name={'type'}
                                        render={(arrayHelpers) => (
                                            <React.Fragment>
                                                {values.type &&
                                                    _getAllowedOrganisationTypes().map((v, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <Field
                                                                    component={MuiCheckBox}
                                                                    checked={values.type.includes(v)}
                                                                    label={v}
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    onBlur={setFieldTouched}
                                                                    onChange={(e) => {
                                                                        const index = values.type.findIndex((e) => e === v);
                                                                        if (e.target.checked && index === -1) {
                                                                            arrayHelpers.push(v);
                                                                        } else if (!e.target.checked && index !== -1) {
                                                                            arrayHelpers.remove(index);
                                                                        }
                                                                        if (v === 'Uitgever' && index !== -1) {
                                                                            _handleOrganisationTypeChange(setFieldValue);
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        )}
                                    />
                                </FormGroup>
                                {errors.type && <span className={classes.error}>{errors.type}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <React.Fragment>
                                    <AutoComplete
                                        name={'restrictedTo'}
                                        endpoint={'organisations'}
                                        endpointFilter={{type: 'Uitgever'}}
                                        value={values.restrictedTo}
                                        labelKey={'name'}
                                        secondLabelKey={'esn'}
                                        valueKey={'id'}
                                        placeholder={'Specifiek voor organisatie...'}
                                        onChange={(property, value) => {
                                            setFieldValue(property, value);
                                        }}
                                    />
                                    <span className={classes.error}>
                                        {errors.organisation && <span className={classes.error}>{errors.organisation}</span>}
                                    </span>
                                </React.Fragment>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    error={Boolean(errors.websiteUrl)}
                                    value={values.websiteUrl}
                                    label={'Website'}
                                    onChange={(e) => {
                                        setFieldValue('websiteUrl', e.target.value, false);
                                    }}
                                />
                                {errors.websiteUrl && <span className={classes.error}>{errors.websiteUrl}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    error={Boolean(errors.abonnementUrl)}
                                    value={values.abonnementUrl}
                                    label={'Abonnementen url'}
                                    onChange={(e) => {
                                        setFieldValue('abonnementUrl', e.target.value, false);
                                    }}
                                />
                                {errors.abonnementUrl && <span className={classes.error}>{errors.abonnementUrl}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    error={Boolean(errors.brin)}
                                    value={values.brin}
                                    label={'BRIN'}
                                    onChange={(e) => {
                                        setFieldValue('brin', e.target.value, false);
                                    }}
                                />
                                {errors.brin && <span className={classes.error}>{errors.brin}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    error={Boolean(errors.instellingBrin)}
                                    value={values.instellingBrin}
                                    label={'Instelling BRIN'}
                                    onChange={(e) => {
                                        setFieldValue('instellingBrin', e.target.value, false);
                                    }}
                                />
                                {errors.instellingBrin && <span className={classes.error}>{errors.instellingBrin}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    error={Boolean(errors.sector)}
                                    value={values.sector}
                                    label={'Sector'}
                                    onChange={(e) => {
                                        setFieldValue('sector', e.target.value, false);
                                    }}
                                />
                                {errors.sector && <span className={classes.error}>{errors.sector}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    error={Boolean(errors.city)}
                                    value={values.city}
                                    label={'Stad'}
                                    onChange={(e) => {
                                        setFieldValue('city', e.target.value, false);
                                    }}
                                />
                                {errors.city && <span className={classes.error}>{errors.city}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => {
                                                setFieldValue('verified', e.target.checked, false);
                                            }}
                                            checked={values.verified}
                                        />
                                    }
                                    label="Geverifiëerd"
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => {
                                                _updateArpSince(values, setFieldValue, e.target.checked);
                                            }}
                                            checked={values.arp}
                                        />
                                    }
                                    label="Heeft ARP"
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                    <DatePicker
                                        autoOk
                                        format="dd-MM-yyyy"
                                        variant="outlined"
                                        label="ARP sinds"
                                        onChange={(e) => {
                                            setFieldValue('arpSince', e, false);
                                        }}
                                        value={values.arpSince}
                                        disabled={!values.arp}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => {
                                                setFieldValue('askForVolledigeNaam', e.target.checked, false);
                                            }}
                                            checked={values.askForVolledigeNaam}
                                        />
                                    }
                                    label="Volledige naam uitvragen"
                                />
                            </FormControl>
                            {canSelectBillingModel && values?.type?.includes('Uitgever') && (
                                <FormControl className={classes.formControl}>
                                    <InputLabel>Facturatiemodel</InputLabel>
                                    <Select
                                        name="billingModel"
                                        value={values.billingModel}
                                        onChange={(e) => {
                                            setFieldValue('billingModel', e.target.value, false);
                                        }}
                                    >
                                        <MenuItem value={BillingModelType.PERCENTAGE}>Percentage</MenuItem>
                                        <MenuItem value={BillingModelType.VASTE_PRIJS}>Vaste Prijs</MenuItem>
                                    </Select>
                                    {errors.billingModel && touched.billingModel && (
                                        <span className={classes.error}>{errors.billingModel}</span>
                                    )}
                                </FormControl>
                            )}
                            {canSelectBillingModel && values?.billingModel === BillingModelType.VASTE_PRIJS && (
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        error={Boolean(errors.billingPrice)}
                                        value={values.billingPrice}
                                        label="Facturatie prijs"
                                        onChange={(e) => {
                                            setFieldValue('billingPrice', e.target.value, false);
                                            setFieldValue('billingPercentage', null, false);
                                        }}
                                    />
                                    {errors.billingPrice && <span className={classes.error}>{errors.billingPrice}</span>}
                                </FormControl>
                            )}
                            {canSelectBillingModel && values?.billingModel === BillingModelType.PERCENTAGE && (
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        error={Boolean(errors.billingPercentage)}
                                        value={values.billingPercentage}
                                        label="Facturatie percentage"
                                        onChange={(e) => {
                                            setFieldValue('billingPercentage', e.target.value, false);
                                            setFieldValue('billingPrice', null, false);
                                        }}
                                    />
                                    {errors.billingPercentage && (
                                        <span className={classes.error}>{errors.billingPercentage}</span>
                                    )}
                                </FormControl>
                            )}
                            <Row className={classes.actionButtonContainer}>
                                <Button
                                    className={classNames(classes.button, classes.saveButton)}
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Opslaan
                                </Button>
                                <Button
                                    size="medium"
                                    className={classNames(classes.button, classes.cancelButton)}
                                    onClick={(e) => history.goBack()}
                                >
                                    <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Annuleren
                                </Button>
                                {entity && canDelete && (
                                    <Fab
                                        className={classes.fab}
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    >
                                        <DeleteForever />
                                    </Fab>
                                )}
                            </Row>
                        </Grid>
                    </Form>
                )}
            />
        </CardContent>
    );
}

const MuiCheckBox = ({checked, className, color, label, onChange}) => {
    return (
        <FormControlLabel
            label={label}
            control={<Checkbox checked={checked} className={className} color={color || 'secondary'} onChange={onChange} />}
        />
    );
};

export default compose(
    connect(mapStateToProps, () => {
        return {};
    }),
    withRouter,
    withStyles(styles)
)(OrganisatieForm);
