import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect} from 'react';

import * as RestClient from '../../services/restclient';
import ExternAccountCollapsible from './externaccountcollapsible';

const ExternAccountsList = (props) => {
    const {onDelete, account, editable} = props;
    const [externAccounts, setExternAccounts] = React.useState([]);

    useEffect(() => {
        async function fetchData() {
            return await RestClient.fetchData({endpoint: `accounts/${account.id}/externalaccounts/`});
        }

        if (account) {
            fetchData().then((response) => setExternAccounts(response.data));
        }
    }, [account]);

    return (
        <React.Fragment>
            {externAccounts.map((externAccount, index) => (
                <ExternAccountCollapsible
                    editable={editable}
                    key={'ext-id-' + index}
                    externAccount={externAccount}
                    onDelete={() => {
                        onDelete(externAccount, index);
                        externAccounts.splice(index, 1);
                    }}
                    index={index}
                />
            )) || 'Geen externe accounts gevonden voor dit account.'}
        </React.Fragment>
    );
};

ExternAccountsList.propTypes = {
    account: PropTypes.object.isRequired,
    editable: PropTypes.bool,
};

ExternAccountsList.defaultProps = {
    editable: false,
};

export default ExternAccountsList;
