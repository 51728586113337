// Custom Javascript Errors.
//
// Error subclassen is misschien niet zo'n goed idee
// https://stackoverflow.com/questions/1382107/whats-a-good-way-to-extend-error-in-javascript#5251506
//
// om custom errors te maken zetten we een veld 'type' op Error met een van de volgende waarden

export const STALE_ACCESS_TOKEN = 'STALE_ACCESS_TOKEN';

export function create(type, msg) {
    const err = new Error(msg);
    err.type = type;
    return err;
}
