import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';

import {MenuItem, withStyles} from '@material-ui/core';
import isEqual from 'lodash.isequal';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import * as React from 'react';
import Select from 'react-virtualized-select';

import * as RestClient from '../../services/restclient';
import AbstractFormSelectComponent from '../AbstractFormComponent';
import GenericField from '../input/genericfield';

const styles = theme => ({
    fullWidth: {
        width: '100%'
    }
});

const Option = secondLabelKey => props => {
    const handleClick = event => {
        props.onSelect(props.option, event);
    };

    const {isFocused, onFocus, style, labelKey} = props;
    const {height, ...rest} = style;
    return (
        <MenuItem key={props.key} onFocus={onFocus} selected={isFocused} onClick={handleClick} style={rest}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>{props.option[labelKey]}</span>
                <span style={{fontSize: '0.6em', color: 'rgba(0, 0, 0, 0.54)'}}>{props.option[secondLabelKey]}</span>
            </div>
        </MenuItem>
    );
};

/**
 * Generiek autocomplete component. Zie ook: https://github.com/bvaughn/react-virtualized-select/
 */
class AutoComplete extends AbstractFormSelectComponent {
    controller = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            elements: [],
            loaded: false,
            lastInput: '',
            lastVirtualValue: '',
        };
    }

    // trim the inputed value if it comes from a paste (so the option "CD-ID" appears if the user pastes " CD-ID")
    handleInputChange = (newVirtualValue) => {
        // onPaste is not supported on 'react-virtualized-select'
        // if there is a length difference higher than 1, then we will suppose it was a paste
        const lastVirtualValue = this.state.lastVirtualValue ?? '';
        newVirtualValue = newVirtualValue ?? '';
        const isPasted = Math.abs(lastVirtualValue.length - newVirtualValue.length) > 1;
        if (isPasted) {
            newVirtualValue = newVirtualValue.trim();   
        }
        this.setState({ lastVirtualValue: newVirtualValue });
        return newVirtualValue;
    }

    render() {
        const {elements} = this.state;
        const {
            label,
            name,
            value,
            valueKey,
            labelKey,
            secondLabelKey,
            placeholder,
            minimumInput,
            classes,
            className,
            disabled
        } = this.props;
        return (
            <React.Fragment>
                <GenericField label={label} className={classes.fullWidth}>
                    <Select
                        async
                        ignoreAccents
                        name={name}
                        value={value}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        placeholder={placeholder}
                        noResultsText={'Geen resultaten'}
                        options={elements}
                        isSearchable={true}
                        cache={false}
                        minimumInput={minimumInput}
                        loadOptions={this._onLoad.bind(this)}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        optionRenderer={Option(secondLabelKey)}
                        optionHeight={48}
                        fullWidth
                        className={className}
                        disabled={disabled}
                        filterOption={() => true}
                        onInputChange={this.handleInputChange}
                    />
                </GenericField>
            </React.Fragment>
        );
    }

    _onLoad = input => {
        const {endpoint, endpointFilter, sortDirection, range, minimumInput, value, valueKey} = this.props;
        const sortBy = this.props.sortBy ? this.props.sortBy : this.props.labelKey;

        const filter = endpointFilter || {};
        filter.q = this.state.lastInput.length >= 2 && input.length < 3 ? '' : input;
        if (this.state.lastInput === input || (input.length < 3 && this.state.lastInput.length < 3)) {
            return Promise.resolve({options: this.state.elements});
        }
        this.setState({lastInput: input});

        return this._onLoadFetch(() =>
            RestClient.fetchData({
                endpoint,
                sortBy,
                sortDirection,
                filter,
                range,
                signal: this.controller.signal
            })
                .then(result => result.data)
                .then(result => {
                    if (value && typeof value !== 'object') {
                        return this._selectedValueFetcher(result);
                    } else {
                        return result;
                    }
                })
                .then(result => {
                    this.setState({elements: result});
                    if (!this.state.loaded) {
                        this.setState({loaded: true});
                        this.props.onAfterInitialLoad(result);
                    }
                    this.props.onAfterLoad(result);
                    return {options: result};
                })
        );
    };

    _onLoadFetch = debounce(
        f => {
            return f();
        },
        this.props.debounceDelay,
        {leading: true}
    );

    componentWillUnmount() {
        this.controller.abort();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.endpointFilter, this.props.endpointFilter)) {
            const {endpoint, endpointFilter, sortDirection, range, value} = this.props;
            const sortBy = this.props.sortBy ? this.props.sortBy : this.props.labelKey;
            const filter = endpointFilter || {};
            RestClient.fetchData({
                endpoint,
                sortBy,
                sortDirection,
                filter,
                range,
                signal: this.controller.signal
            })
                .then(result => result.data)
                .then(result => {
                    if (value && typeof value !== 'object') {
                        return this._selectedValueFetcher(result);
                    } else {
                        return result;
                    }
                })
                .then(result => {
                    this.setState({elements: result});
                    this.props.onAfterLoad(result);
                    return {options: result};
                });
        }
    }

    _selectedValueFetcher(result) {
        const {endpoint, value, valueKey} = this.props;
        return RestClient.fetchData({
            endpoint: `${endpoint}/${value}`,
            signal: this.controller.signal
        })
            .then(valueResult => {
                if (!result.some(e => e[valueKey] === valueResult.data[valueKey])) {
                    result.push(valueResult.data);
                }
                return result;
            })
            .catch(() => result);
    }
}

AutoComplete.propTypes = {
    endpoint: PropTypes.string.isRequired,
    labelKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    debounceDelay: PropTypes.number, // in ms
    disabled: PropTypes.bool
};

AutoComplete.defaultProps = {
    valueKey: 'id',
    labelKey: 'toString',
    secondLabelKey: undefined,
    sortBy: null,
    sortDirection: 'ASC',
    endpointFilter: {},
    range: {start: 0, end: 24},
    minimumInput: 2,
    onAfterInitialLoad: () => {},
    onAfterLoad: () => {},
    debounceDelay: 300,
    disabled: false
};

export default withStyles(styles)(AutoComplete);
