import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import * as React from 'react';
import Collapsible from 'react-collapsible';
import Grid from '@material-ui/core/Grid';

const styles = () => ({
    iconClose: {
        float: 'right',
        '&:hover': {
            color: '#e81704',
        },
    },
    iconLock: {
        float: 'right',
    },
    externAccountHeader: {
        fontSize: '18px',
        color: '#4a474c',
        '&:hover': {
            color: '#898989',
        },
    },
    dateContainer: {
        fontSize: '12px',
    }
});

const ExternAccountCollapsible = (props) => {
    const {classes, onDelete, editable, externAccount, index} = props;

    return (
        <Collapsible
            open={false}
            trigger={
                <Grid container className={classes.externAccountHeader}>
                    <Grid container direction="row" className={classes.dateContainer}>
                        <Grid item xs={3}>
                            {'Aangemaakt: '}
                            {new Date(externAccount.createdAt).toLocaleString()}
                        </Grid>
                        <Grid item xs={8}>
                            {'Laatst gewijzigd: '}
                            {new Date(externAccount.updatedAt).toLocaleString()}
                        </Grid>
                        <Grid item xs={1}>
                            {editable && (
                                <CloseIcon
                                    className={classes.iconClose}
                                    onClick={(e) => {
                                        onDelete(externAccount, index);
                                    }}
                                />
                            )}
                            {externAccount.isUndetachable && <LockIcon className={classes.iconLock} />}
                        </Grid>
                    </Grid>
                    <Grid item xs={11}>
                        {externAccount.accountType} {externAccount.attributes ? ' - ' + externAccount.nameId : ''}
                    </Grid>

                </Grid>
            }
            transitionTime={200}
        >
            {externAccount.attributes ? (
                <React.Fragment>
                    <pre>
                        <code>{JSON.stringify(externAccount.attributes, undefined, 2)}</code>
                    </pre>
                </React.Fragment>
            ) : (
                <div>Geen attributen</div>
            )}
        </Collapsible>
    );
};

ExternAccountCollapsible.propTypes = {
    externAccount: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    editable: PropTypes.bool,
};

export default withStyles(styles)(ExternAccountCollapsible);
