/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default function getValidationSchema() {
    return Yup.object().shape({
        name: Yup.string()
            .max(255)
            .required('Naam is verplicht'),
        email: Yup.string()
            .max(255)
            .required('E-mail is verplicht')
            .email('Vul een geldig e-mailadres in'),
        password: Yup.string().when('id', {
            is: val => val === null,
            then: Yup.string()
                .required('Wachtwoord is verplicht')
                .min(9, 'Wachtwoord moet minimaal 9 karakters lang zijn')
        }),
        confirmPassword: Yup.string().when('password', {
            is: val => val && val.length > 0,
            then: Yup.string().oneOf([Yup.ref('password'), null], 'Wachtwoord moet overeenkomen')
        }),
        uitgever: Yup.mixed().nullable(),
        rol: Yup.mixed().required('Rol is verplicht')
    });
}
