import {Button, Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as React from 'react';

import SimpleTable from '../simpletable';

const styles = () => ({
    button: {
        textDecoration: 'none',
        color: '#fff',
    },
});

const tlinkCols = [
    {
        header: 'Code',
        linksTo: (row) => `/tlinks/${row.id}/edit`,
        renderer: (row) => row.licenseCode,
    },
    {
        header: 'EAN',
        renderer: (row) => row.product.ean,
    },
    {
        header: 'Titel',
        linksTo: (row) => `/producten/${row.product.id}/extended-view`,
        renderer: (row) => row.product.toString,
    },
    {
        header: 'Status',
        renderer: (row) => row.licenseState,
    },
    {
        header: 'Geldig tot',
        renderer: (row) => {
            return row.endDate ? moment(row.endDate).format('DD-MM-YY') : '';
        },
    },
];

const AccountTLinksTable = (props) => {
    const {classes, entity} = props;

    const [showExpirableStates, setShowExpirableStates] = React.useState(true);

    // hideExpirableStates is necessary because when showExpirableStates is false, the property is absent in the query
    const filter = {account: entity.id, showExpirableStates, hideExpirableStates: !showExpirableStates};
    
    return (
        <React.Fragment>
            <Grid container justify={'space-between'}>
                <Grid>
                    <Typography component="h1" variant="h5" gutterBottom noWrap>
                        TLinks
                    </Typography>
                </Grid>
                <Grid>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                            if (showExpirableStates) setShowExpirableStates(false);
                            else setShowExpirableStates(true);
                        }}
                    >
                        {showExpirableStates ? 'Toon verlopen Tlinks' : 'Toon niet-verlopen Tlinks'}
                    </Button>
                </Grid>
            </Grid>
            <SimpleTable endpoint="tlinklicenses" sortBy="updatedAt" sortDirection="DESC" filter={filter} columns={tlinkCols} />
        </React.Fragment>
    );
};

AccountTLinksTable.propTypes = {
    entity: PropTypes.object.isRequired,
};

export default withStyles(styles)(AccountTLinksTable);
