import React from 'react';
import {Link} from 'react-router-dom';
import {Recht} from 'shared-types';

import {hasRight} from '../permissions/withpermissions';

const tlinkRegexPrefix = /(tlink:) ([a-zA-Z0-9]{8})/i;
const eanRegexPrefix = /(ean:) ([0-9]{13})/i;
const accountRegexPrefix = /\s(account:) ([0-9]*)($|\s)/i;

export const infoToLink = (text, authUserReducer) => {
    if (text && authUserReducer) {
        if (text.match(tlinkRegexPrefix)) {
            if (
                hasRight(authUserReducer, Recht.TLINKLICENTIES_AANPASSEN) ||
                hasRight(authUserReducer, Recht.TLINKLICENTIES_INZIEN)
            ) {
                const match = tlinkRegexPrefix.exec(text);
                return createCorrectLink(text, match, getLink(match[2], `/tlinks/code/${match[2]}`), authUserReducer);
            }
        } else if (text.match(eanRegexPrefix)) {
            const match = eanRegexPrefix.exec(text);
            if (hasRight(authUserReducer, Recht.PRODUCTEN_INZIEN)) {
                return createCorrectLink(
                    text,
                    match,
                    getLink(match[2], `/producten/ean/${match[2]}/extended-view`),
                    authUserReducer
                );
            } else if (hasRight(authUserReducer, Recht.PRODUCTEN_AANPASSEN)) {
                return createCorrectLink(text, match, getLink(match[2], `/producten/ean/${match[2]}`), authUserReducer);
            }
        } else if (text.match(accountRegexPrefix)) {
            if (hasRight(authUserReducer, Recht.GEBRUIKERS_INZIEN)) {
                const match = accountRegexPrefix.exec(text);
                return createCorrectLink(text, match, getLink(match[2], `/accounts/${match[2]}/extended-view`), authUserReducer);
            }
        }
    }

    return text;
};

const createCorrectLink = (text, match, link, authUserReducer) => {
    const rearText = text.substring(match.index + match[0].length);
    return getInfoRowWithLink(
        text.substring(0, match.index + match[1].length),
        link,
        infoToLink(rearText, authUserReducer, true)
    );
};

const getInfoRowWithLink = (start, link, end) => (
    <b>
        {start} {link} {end}
    </b>
);

const getLink = (text, url) => {
    return (
        <Link to={url} target={'_blank'}>
            {text}
        </Link>
    );
};
