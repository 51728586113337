import {
    Button,
    CardContent,
    Fab,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    withStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForever from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import {Form, Formik} from 'formik';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../../components/autocomplete';
import ConfirmationDialog from '../../../components/confirmationdialog';
import GenericField from '../../../components/input/genericfield';
import ReadOnly from '../../../components/input/readonly';
import {hasRight} from '../../../components/permissions/withpermissions';
import {toastPromise} from '../../../components/toast';
import {mapStateToProps} from '../../../store/connector';
import getValidationSchema from './getValidationSchema';

const endpoint = 'roles';
const styles = (theme) => ({
    root: {
        padding: 0,
    },
    button: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    checkbox: {
        marginLeft: theme.spacing(1),
    },
    checkboxContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    gridContainer: {
        padding: theme.spacing(5),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff',
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
    rolContainer: {
        display: 'inline-grid'
    }
});

const _doSubmit = (values, syncData, endpoint) => {
    const syncing = syncData({
        endpoint: endpoint,
        entity: getValidationSchema().cast(values),
    });

    return toastPromise(
        syncing,
        'Aan het opslaan...',
        'De rol is opgeslagen.',
        (e) => `De rol kon niet worden opgeslagen${e.message || e.error.message ? ': ' + (e.message ?? e.error.message) : ''}.`,
        null,
        true
    );
};

function _getZichtbareRechten(admin, rol) {
    const accountRechten = (admin && admin.rol.rechten) || [];
    const rolRechten = (rol && rol.rechten) || [];

    if (accountRechten.includes(Recht.IS_SUPERUSER)) {
        return Object.values(Recht).filter((r) => r !== Recht.IS_SUPERUSER);
    }
    return [...new Set([...accountRechten, ...rolRechten])];
}

function RolForm(props) {
    const {classes, entity, deleteItem, history, authUserReducer, postSuccess, postError, postErrorMessage} = props;
    const [open, setOpen] = React.useState(false);
    const zichtbareRechten = _getZichtbareRechten(authUserReducer.beheerder, entity);
    const redirectToOverviewScreen = React.useCallback(() => {
        history.push('/rollen');
    }, [history]);

    const [canDelete, setCanDelete] = React.useState(false);
    const [canSelectUitgever, setCanSelectUitgever] = React.useState(false);
    const [preselectedUitgever, setPreselectedUitgever] = React.useState(undefined);

    React.useEffect(() => {
        if (hasRight(authUserReducer, Recht.ROLLEN_VERWIJDEREN) !== canDelete) {
            setCanDelete(hasRight(authUserReducer, Recht.ROLLEN_VERWIJDEREN));
        }
        if (hasRight(authUserReducer, Recht.IS_SUPERUSER) || (authUserReducer.beheerder && !authUserReducer.beheerder.uitgever)) {
            // gebruiker is superuser of is overkoepelende beheerder (als in: wel Topicus maar geen superuser)
            setCanSelectUitgever(true);
        }
        if (entity) {
            setPreselectedUitgever(entity.uitgever);
        } else if (!hasRight(authUserReducer, Recht.IS_SUPERUSER) && authUserReducer.beheerder) {
            setPreselectedUitgever(authUserReducer.beheerder.uitgever);
        }

        if (postSuccess) {
            redirectToOverviewScreen();
        }
    }, [canDelete, authUserReducer, entity, postSuccess, redirectToOverviewScreen]);

    const _doDelete = async (entity, deleteItem) => {
        const deletingItem = deleteItem({
            endpoint,
            id: entity.id,
        }).then(() => {
            redirectToOverviewScreen();
        });

        toastPromise(deletingItem, 'Aan het verwijderen..', 'De rol is verwijderd.', (err) => {
            if (err.status === 409) {
                return (
                    <div>
                        Dit kan nu niet worden verwijderd omdat er nog andere entiteiten aan zijn gekoppeld. Verwijder eerst:
                        <ul>
                            {err.failedEntities.map((entity) => (
                                <li>
                                    {entity.toString} {entity.entityType}#{entity.id}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            } else {
                return `Deze rol kan nu niet worden verwijderd${
                    err.message || err.error.message ? ': ' + (err.message ?? err.error.message) : ''
                }.`;
            }
        });
    };
    const _detectEsc = (e) => {
        if (e.keyCode === 27) {
            redirectToOverviewScreen();
        }
    };

    return (
        <CardContent className={classes.root}>
            <ConfirmationDialog
                title="Weet je het zeker?"
                open={open}
                onConfirm={() => {
                    _doDelete(entity, deleteItem);
                }}
                onClose={() => setOpen(false)}
            >
                {entity && entity.naam ? `Wil je rol '${entity.naam}' echt verwijderen?` : 'Wil je deze rol echt verwijderen?'}
            </ConfirmationDialog>
            <Typography component="h1" variant="h5" gutterBottom noWrap>
                {entity ? `Rol bewerken` : `Rol toevoegen`}
            </Typography>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    id: entity ? entity.id : null,
                    naam: entity ? entity.naam : '',
                    rechten: entity ? entity.rechten : [],
                    uitgever: preselectedUitgever ? preselectedUitgever : null,
                    isKiesbaar: entity ? entity.isKiesbaar : true,
                }}
                validationSchema={getValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;
                    await _doSubmit(values, values.id ? updateData : postData, values.id ? `${endpoint}/${values.id}` : endpoint)
                        .then((result) => {
                            actions.setSubmitting(false);
                        })
                        .catch(() => {
                            actions.setSubmitting(false);
                        });
                }}
                render={({values, touched, errors, isSubmitting, setFieldValue}) => {
                    return (
                        <Form onKeyDown={_detectEsc}>
                            <Grid container direction="column">
                                <FormControl className={classes.formControl}>
                                    <InputLabel required>Naam</InputLabel>
                                    <Input
                                        error={Boolean(errors.naam)}
                                        autoFocus={true}
                                        value={values.naam}
                                        onChange={(e) => {
                                            setFieldValue('naam', e.target.value, false);
                                        }}
                                    />
                                    {errors.naam && <span className={classes.error}>{errors.naam}</span>}
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    {canSelectUitgever ? (
                                        <React.Fragment>
                                            <AutoComplete
                                                name={'uitgever'}
                                                endpoint={'organisations'}
                                                endpointFilter={{type: 'Uitgever'}}
                                                value={values.uitgever}
                                                labelKey={'name'}
                                                valueKey={'id'}
                                                placeholder={'Kies een uitgever...'}
                                                onChange={(property, value) => {
                                                    setFieldValue(property, value, false);
                                                }}
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <GenericField label="Uitgever">
                                            <ReadOnly>{values.uitgever ? values.uitgever.name : 'Geen'}</ReadOnly>
                                        </GenericField>
                                    )}
                                </FormControl>
                                <Grid className={classes.rolContainer}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Rechten</InputLabel>
                                        <Select
                                            multiple
                                            name="rechten"
                                            error={Boolean(errors.rechten)}
                                            value={values.rechten}
                                            onChange={(e) => {
                                                setFieldValue('rechten', e.target.value, false);
                                            }}
                                        >
                                            {zichtbareRechten.map((r) => (
                                                <MenuItem key={r} value={r}>
                                                    {r}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.rechten && <span className={classes.error}>{errors.rechten}</span>}
                                    </FormControl>
                                </Grid>
                                <Grid className={classes.actionButtonContainer}>
                                    <Button
                                        className={classNames(classes.button, classes.saveButton)}
                                        variant="contained"
                                        size="medium"
                                        color="secondary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                        Opslaan
                                    </Button>
                                    <Button
                                        size="medium"
                                        className={classNames(classes.button, classes.cancelButton)}
                                        onClick={(e) => history.goBack()}
                                    >
                                        <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                        Annuleren
                                    </Button>
                                    {entity && canDelete && (
                                        <Fab
                                            className={classes.fab}
                                            onClick={() => {
                                                setOpen(true);
                                            }}
                                        >
                                            <DeleteForever />
                                        </Fab>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            />
        </CardContent>
    );
}

export default compose(
    connect(mapStateToProps, () => {
        return {};
    }),
    withRouter,
    withStyles(styles)
)(RolForm);
