import {InputAdornment, TextField} from '@material-ui/core';
import {SearchRounded} from '@material-ui/icons';
import PropTypes from 'prop-types';
import * as React from 'react';

export default class SearchTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
            this.setState({
                value: this.props.value,
            });
        }
    }

    // trim pasted values to avoid searching with unwanted empty spaces
    handlePaste = () => {
        // we use setTimeout to access the value AFTER the paste was performed
        setTimeout(() => {
            const value = this.state.value ?? '';
            const trimmedValue = value.trim();
            this.setState({ value: trimmedValue }, () => this.props.onChange(this.state.value));
        }, 0);
    }

    render() {
        const {onEnter, ...rest} = this.props;
        return (
            <TextField
                {...rest}
                onChange={(e) => {
                    this.setState({value: e.target.value}, () => this.props.onChange(this.state.value));
                }}
                onKeyUp={(e) => {
                    if (e.key === 'Enter' && onEnter) {
                        onEnter();
                    }
                }}
                onPaste={this.handlePaste}
                variant="outlined"
                margin="dense"
                value={this.state.value}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchRounded style={{opacity: 0.2}} />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

SearchTextField.propTypes = {
    ...TextField.propTypes,
    onEnter: PropTypes.func,
};

SearchTextField.defaultProps = {
    ...TextField.defaultProps,
    value: '',
};
