import DateFnsUtils from '@date-io/date-fns';
import {Box, Button} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import classNames from 'classnames';
import {nl} from 'date-fns/locale';
import {Form, Formik} from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {LicenseActivationType, LicenseType} from 'shared-types';
import uuid from 'uuid';

import AutoComplete from '../../../components/autocomplete';
import LinkButton from '../../../components/linkbutton';
import {toastError, toastPromise} from '../../../components/toast';
import {saveToSessionStorage} from '../../../services/util';
import getValidationSchema from './getValidationSchema';

const endpoint = 'tlinklicenses';
const dateFns = new DateFnsUtils();

const styles = (theme) => ({
    root: {
        padding: 0,
    },
    button: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    checkbox: {
        marginLeft: theme.spacing(1),
    },
    formInputBox: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    dateContainer: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    dateBox: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
});

const _doSubmit = async (values, syncData, endpoint) => {
    let syncing = syncData({
        endpoint: endpoint,
        entity: getValidationSchema().cast(values),
        parameters: {
            amount: values.amountToCreate || 1,
        },
        responseType: 'ENTITIES',
    });

    syncing = toastPromise(
        syncing,
        'TLink aan het opslaan...',
        'De TLink is opgeslagen.',
        (e) => `Probleem tijdens het opslaan van de TLink${e.message ? ': ' + e.message : ''}.`,
        {},
        true
    );

    return syncing;
};

// Gelinkt aan getLicenseActivatableFromForProduct van ProductService Als je hier iets aanpast dan ook daar misschien nodig.

const getStartDate = (product) => {
    if (product.productType !== 'WEB_BROWSER') {
        return null;
    }
    const activatableFrom = product.licenseModel.activatableFrom ? moment(product.licenseModel.activatableFrom).toDate() : null;
    const introductionDate = moment(product.introductionDate).toDate();

    let finalActivatableFrom;
    if (product.licenseModel.licenseActivationType === LicenseActivationType.PERIODE && activatableFrom >= new Date()) {
        finalActivatableFrom = activatableFrom;
    } else if (product.licenseModel.licenseActivationType === LicenseActivationType.PERIODE && activatableFrom < new Date()) {
        finalActivatableFrom = new Date();
    } else if (introductionDate > new Date()) {
        finalActivatableFrom = introductionDate;
    } else {
        finalActivatableFrom = new Date();
    }

    finalActivatableFrom.setHours(0, 0, 0, 0);
    return finalActivatableFrom;
};

// Gelinkt aan getLicenseActivatableUntilForProduct van ProductService Als je hier iets aanpast dan ook daar misschien nodig.
const getEndDate = (product) => {
    if (product.productType !== 'WEB_BROWSER') {
        return null;
    } else if (product.licenseModel?.licenseType === LicenseType.ABONNEMENT) {
        return moment().add(product.licenseModel.duration, 'd').toDate();
    }

    const endOfLifeDate = moment(product.endOfLifeDate).toDate();
    let finalActivatableUntil;

    if (product.licenseModel.licenseActivationType === LicenseActivationType.PERIODE) {
        if (product.licenseModel.activatableUntil) {
            finalActivatableUntil = moment(product.licenseModel.activatableUntil).toDate();
        } else {
            toastError('"Te activeren tot" ontbreekt op het product. Corigeer dit alstublieft voordat u verder gaat.');
            finalActivatableUntil = endOfLifeDate;
        }
    } else if (product.licenseModel.licenseActivationType === LicenseActivationType.KANTELDATUM) {
        const today = new Date();
        const kantelDatum = moment(product.licenseModel.tiltDate).toDate() ?? new Date(today.getFullYear(), 5, 1);
        kantelDatum.setFullYear(today.getFullYear());

        let finalDate = today;
        if (today > kantelDatum) {
            finalDate.setFullYear(finalDate.getFullYear() + 1);
        }

        finalDate.setMonth(kantelDatum.getMonth());
        finalDate.setDate(kantelDatum.getDate());

        finalActivatableUntil = finalDate;
    } else if (product.licenseModel.licenseActivationType === LicenseActivationType.AANTAL_DAGEN) {
        let date = product.introductionDate > new Date() ? product.introductionDate : new Date();
        date.setDate(date.getDate() + product.licenseModel.activatableAmountOfDays);
        finalActivatableUntil = date;
    } else {
        finalActivatableUntil = endOfLifeDate;
    }

    finalActivatableUntil.setHours(0, 0, 0, 0);
    return finalActivatableUntil;
};

function TLinkForm(props) {
    const {classes, entity, history, postSuccess, postError, postErrorMessage} = props;
    const redirectToOverviewScreen = (lastCreatedEntities) => {
        if (lastCreatedEntities && lastCreatedEntities.entities) {
            lastCreatedEntities.timestamp = Date.now();
            saveToSessionStorage('last-created-entities', lastCreatedEntities);
        }
        history.push('/tlinks');
    };
    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };

    React.useEffect(() => {
        if (postSuccess) {
            redirectToOverviewScreen(props.lastCreatedEntities);
        }
    });

    return (
        <CardContent className={classes.root}>
            <Typography component="h1" variant="h5" gutterBottom noWrap>
                {entity ? `TLink ${entity.licenseCode} bewerken` : 'Nieuwe TLink'}
            </Typography>
            <Formik
                initialValues={{
                    id: entity ? entity.id : undefined,
                    product: entity ? entity.product : undefined,
                    organisation: entity ? entity.organisation : undefined,
                    startDate: entity ? dateFns.date(entity.startDate) : null,
                    activationDate: entity ? (entity.activationDate ? dateFns.date(entity.activationDate) : null) : null,
                    endDate: entity ? dateFns.date(entity.endDate) : null,
                    account: entity ? entity.account : undefined,
                    referenceId: entity ? entity.referenceId : uuid.v4(),
                    distributor: entity ? entity.distributor : 'beheer-toegang',
                    licenseState: entity ? entity.licenseState : 'AVAILABLE',
                    amount: entity ? entity.amount || entity.product.licenseModel.maximumLoginAmount : undefined,
                    amountToCreate: 1,
                }}
                validationSchema={getValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;
                    await _doSubmit(values, values.id ? updateData : postData, values.id ? `${endpoint}/${values.id}` : endpoint)
                        .then((result) => {
                            actions.setSubmitting(false);
                        })
                        .catch((e) => {
                            actions.setSubmitting(false);
                        });
                }}
                render={({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                }) => (
                    <Form onKeyDown={_detectEsc}>
                        <Grid container direction="column">
                            <FormGroup className={classes.inputContainer}>
                                {!entity && (
                                    <FormControl className={classes.formInputBox}>
                                        <InputLabel required>Aantal aan te maken</InputLabel>
                                        <Input
                                            value={values.amountToCreate}
                                            error={Boolean(errors.amountToCreate)}
                                            autoFocus={true}
                                            inputProps={{
                                                maxLength: 13,
                                            }}
                                            type={'number'}
                                            onChange={(e) => {
                                                setFieldValue('amountToCreate', e.target.value, false);
                                            }}
                                        />
                                        {errors.amountToCreate && <span className={classes.error}>{errors.amountToCreate}</span>}
                                    </FormControl>
                                )}
                                <FormControl>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={values.licenseState}
                                        input={<Input name="licenseState" id="licenseState" />}
                                        error={Boolean(errors.licenseState)}
                                        onChange={(e) => {
                                            const oldValue = values.licenseState;
                                            const newValue = e.target.value;
                                            const deactivatedStates = [
                                                'DESPECIFIED',
                                                'EXPIRED',
                                                'DEACTIVATED',
                                                'REVOKED',
                                                'REVOKED_BY_MERGE',
                                            ];
                                            if (!deactivatedStates.includes(oldValue) && deactivatedStates.includes(newValue)) {
                                                setFieldValue('endDate', new Date(), false);
                                            }
                                            setFieldValue('licenseState', newValue, false);
                                        }}
                                    >
                                        <MenuItem value="AVAILABLE">Available</MenuItem>
                                        <MenuItem value="ACTIVE">Active</MenuItem>
                                        <MenuItem value="EXPIRED">Expired</MenuItem>
                                        <MenuItem value="DEACTIVATED">Deactivated</MenuItem>
                                        <MenuItem value="DESPECIFIED">Despecified</MenuItem>
                                        <MenuItem value="REVOKED">Revoked</MenuItem>
                                        <MenuItem value="REVOKED_BY_MERGE">Revoked by merge</MenuItem>
                                        <MenuItem value="BLOCKED">Blocked</MenuItem>
                                        <MenuItem value="PAUSED">Paused</MenuItem>
                                        <MenuItem value="RESERVED" disabled>
                                            Reserved
                                        </MenuItem>
                                        <MenuItem value="PREACTIVE" disabled>
                                            Preactive
                                        </MenuItem>
                                    </Select>
                                    {errors.licenseState && <span className={classes.error}>{errors.licenseState}</span>}
                                </FormControl>
                            </FormGroup>
                            {entity && values.product?.licenseModel?.licenseType === 'AANTAL' && (
                                <FormGroup>
                                    <FormControl className={classes.formInputBox}>
                                        <InputLabel>Aantal gebruiken resterend</InputLabel>
                                        <Input value={values.amount} type={'number'} disabled={true} />
                                        {errors.amount && <span className={classes.error}>{errors.amount}</span>}
                                    </FormControl>
                                </FormGroup>
                            )}
                            <FormGroup>
                                <Grid container alignItems="center">
                                    <Box flexGrow={1} className={classes.formInputBox}>
                                        <AutoComplete
                                            name="product"
                                            endpoint="products"
                                            endpointFilter={{productType: 'WEB_BROWSER'}}
                                            value={values.product}
                                            labelKey="toString"
                                            placeholder="Kies een product..."
                                            onChange={(property, value) => {
                                                setFieldValue('product', value, false);
                                                if (value !== undefined && value !== null) {
                                                    setFieldValue('startDate', getStartDate(value), false);
                                                    setFieldValue('endDate', getEndDate(value), false);
                                                }
                                            }}
                                        />
                                        {errors.product && <span className={classes.error}>{errors.product}</span>}
                                    </Box>
                                    <LinkButton
                                        endpoint={'producten'}
                                        entity={values.product}
                                        tooltip={'Ga naar product'}
                                        history={props.history}
                                    />
                                </Grid>
                                <Grid container alignItems="center">
                                    <Box flexGrow={1} className={classes.formInputBox}>
                                        <AutoComplete
                                            name="account"
                                            endpoint="accounts"
                                            value={values.account}
                                            labelKey="toString"
                                            placeholder="Kies een account..."
                                            onChange={(property, value) => {
                                                setFieldValue('account', value, false);
                                            }}
                                        />
                                    </Box>
                                    <LinkButton
                                        endpoint={'accounts'}
                                        entity={values.account}
                                        tooltip={'Ga naar account'}
                                        history={props.history}
                                    />
                                </Grid>
                                <Grid container alignItems="center">
                                    <Box flexGrow={1} className={classes.formInputBox}>
                                        <AutoComplete
                                            name="organisation"
                                            endpoint="organisations"
                                            endpointFilter={{type: 'School'}}
                                            value={values.organisation}
                                            labelKey="toString"
                                            placeholder="Kies een organisatie..."
                                            onChange={(property, value) => {
                                                setFieldValue('organisation', value, false);
                                            }}
                                        />
                                        {errors.organisation && <span className={classes.error}>{errors.organisation}</span>}
                                    </Box>
                                    <LinkButton
                                        endpoint={'organisaties'}
                                        entity={values.organisation}
                                        tooltip={'Ga naar organisatie'}
                                        history={props.history}
                                    />
                                </Grid>
                            </FormGroup>
                            <FormGroup row className={classes.dateContainer}>
                                <FormControl className={classes.dateBox}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDatePicker
                                            id="startDate"
                                            label="Startdatum"
                                            autoOk
                                            format="dd-MM-yyyy"
                                            value={values.startDate}
                                            error={Boolean(errors.startDate)}
                                            invalidDateMessage=""
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'startDate',
                                                    dateFns.isValid(e) ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd')) : e,
                                                    false
                                                );
                                            }}
                                        />
                                        {errors.startDate && <span className={classes.error}>{errors.startDate}</span>}
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <FormControl className={classes.dateBox}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDatePicker
                                            id="activationDate"
                                            label="Activatiedatum"
                                            autoOk
                                            format="dd-MM-yyyy"
                                            value={values.activationDate}
                                            error={Boolean(errors.activationDate)}
                                            invalidDateMessage=""
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'activationDate',
                                                    dateFns.isValid(e) ? dateFns.date(dateFns.format(e, 'yyyy-MM-dd')) : e,
                                                    false
                                                );
                                            }}
                                        />
                                        {errors.activationDate && <span className={classes.error}>{errors.activationDate}</span>}
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <FormControl className={classes.dateBox}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDatePicker
                                            id="endDate"
                                            label="Einddatum"
                                            autoOk
                                            format="dd-MM-yyyy"
                                            value={values.endDate}
                                            error={Boolean(errors.endDate)}
                                            invalidDateMessage=""
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'endDate',
                                                    (dateFns.isValid(e) && dateFns.date(dateFns.format(e, 'yyyy-MM-dd'))) || e,
                                                    false
                                                );
                                            }}
                                        />
                                        {errors.endDate && <span className={classes.error}>{errors.endDate}</span>}
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </FormGroup>
                            <FormGroup row className={classes.actionButtonContainer}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    className={classNames(classes.button, classes.saveButton)}
                                    type="submit"
                                    disabled={Boolean(isSubmitting)}
                                >
                                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Opslaan
                                </Button>
                                <Button
                                    size="medium"
                                    className={classNames(classes.button, classes.cancelButton)}
                                    onClick={(e) => history.goBack()}
                                >
                                    <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Annuleren
                                </Button>
                            </FormGroup>
                        </Grid>
                    </Form>
                )}
            />
        </CardContent>
    );
}

export default withRouter(withStyles(styles)(TLinkForm));
