"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileRole = exports.PoRole = exports.IncompleteToegangProduct = exports.AuthenticationType = exports.UitgeverLicenseModel = exports.UitgeverProduct = exports.Designation = exports.ProductSyncErrorCodes = exports.ProductSyncValidationResult = exports.IdentityProvider = exports.PublisherInfo = exports.BasispoortProductProperties = exports.EckProductProperties = exports.BillingModelType = exports.ChainType = exports.Price = exports.EckRestTarget = exports.Order = exports.UitgeverProductInfo = exports.UitgeverLicenseInfo = exports.EndUserRoleType = exports.SectorType = exports.EducationalYearType = exports.ProductUsage = exports.ProductState = exports.DeviceType = exports.PlatformType = exports.ProductType = exports.BrowserType = exports.LicenseActivationType = exports.LicenseType = exports.TLinkLicenseState = exports.Recht = void 0;
/**
 * This module was automatically generated by `ts-interface-builder`
 */
const t = require("ts-interface-checker");
// tslint:disable:object-literal-key-quotes
exports.Recht = t.enumtype({
  "ACCESSURLS_VERWIJDEREN": "accessurls_verwijderen",
  "ORGANISATIES_INZIEN": "organisaties_inzien",
  "ORGANISATIES_TOEVOEGEN": "organisaties_toevoegen",
  "ORGANISATIES_AANPASSEN": "organisaties_aanpassen",
  "ORGANISATIES_VERWIJDEREN": "organisaties_verwijderen",
  "ORGANISATIES_UITGEVERS_DISTRIBUTEURS_TOEGANG": "organisaties_uitgevers_distributeurs_toegang",
  "OAUTHCLIENTS_TOEGANG": "oauthclients_toegang",
  "PRODUCTEN_INZIEN": "producten_inzien",
  "PRODUCTEN_TOEVOEGEN": "producten_toevoegen",
  "PRODUCTEN_AANPASSEN": "producten_aanpassen",
  "PRODUCTEN_VERWIJDEREN": "producten_verwijderen",
  "PRODUCTEN_SERVICEWINDOWS_TOEGANG": "producten_servicewindows_toegang",
  "PRODUCTEN_SUBPRODUCTEN_TOEGANG": "producten_subproducten_toegang",
  "GEBRUIKERS_INZIEN": "gebruikers_inzien",
  "GEBRUIKERS_TOEVOEGEN": "gebruikers_toevoegen",
  "GEBRUIKERS_AANPASSEN": "gebruikers_aanpassen",
  "GEBRUIKERS_SESSIES_AFMELDEN": "gebruikers_sessies_afmelden",
  "GEBRUIKERS_SAMENVOEGEN": "gebruikers_samenvoegen",
  "BEHEERDERS_INZIEN": "beheerders_inzien",
  "BEHEERDERS_TOEVOEGEN": "beheerders_toevoegen",
  "BEHEERDERS_AANPASSEN": "beheerders_aanpassen",
  "BEHEERDERS_VERWIJDEREN": "beheerders_verwijderen",
  "ROLLEN_INZIEN": "rollen_inzien",
  "ROLLEN_TOEVOEGEN": "rollen_toevoegen",
  "ROLLEN_AANPASSEN": "rollen_aanpassen",
  "ROLLEN_VERWIJDEREN": "rollen_verwijderen",
  "TLINKLICENTIES_INZIEN": "tlinklicenties_inzien",
  "TLINKLICENTIES_TOEVOEGEN": "tlinklicenties_toevoegen",
  "TLINKLICENTIES_AANPASSEN": "tlinklicenties_aanpassen",
  "TLINKLICENTIES_RESERVEREN": "tlinklicenties_reserveren",
  "TLINKLICENTIES_DESPECIFICEREN": "tlinklicenties_despecificeren",
  "EXTERNACCOUNTS_TOEVOEGEN": "externaccounts_toevoegen",
  "EXTERNACCOUNTS_VERWIJDEREN": "externaccounts_verwijderen",
  "EXTERNACCOUNTS_KOPPELEN": "externaccounts_koppelen",
  "ECKLICENTIES_INZIEN": "ecklicenties_inzien",
  "SCHOOLJAARVAKCONTRACTEN_INZIEN": "schooljaarvakcontracten_inzien",
  "SUPPORT_INZIEN": "support_inzien",
  "SUPPORT_AFMELDEN": "support_afmelden",
  "RETRY_PRIUS_ORDER": "retry_prius_order",
  "PROFIELHUIS_ACCES": "profielhuis_acces",
  "BEHEER_TOEGANG": "beheer_toegang",
  "LOGENTRIES_INZIEN": "logentries_inzien",
  "ORDERS_INZIEN": "orders_inzien",
  "ORDERS_TOEVOEGEN": "orders_toevoegen",
  "ORDERBALANS_INZIEN": "orderbalans_inzien",
  "FACTUUR_INZIEN": "factuur_inzien",
  "ALLEEN_BASISPOORT_INZIEN": "alleen_basispoort_inzien",
  "POLICENTIES_INZIEN": "policenties_inzien",
  "POLICENTIES_VERWIJDEREN": "policenties_verwijderen",
  "POLICENTIES_GROEPEN_AANPASSEN": "policenties_groepen_aanpassen",
  "WASWORDT_IMPORTEREN": "waswordt_importeren",
  "IS_SUPERUSER": "is_superuser"
});
exports.TLinkLicenseState = t.union(t.lit('AVAILABLE'), t.lit('ACTIVE'), t.lit('EXPIRED'), t.lit('DEACTIVATED'), t.lit('DESPECIFIED'), t.lit('REVOKED'), t.lit('BLOCKED'), t.lit('REVOKED_BY_MERGE'), t.lit('REVOKED_BY_EXTENSION'), t.lit('RESERVED'), t.lit('PAUSED'), t.lit('PREACTIVE'));
exports.LicenseType = t.enumtype({
  "VRIJE_LICENTIE": "VRIJE_LICENTIE",
  "VASTE_DATUM": "VASTE_DATUM",
  "VASTE_PERIODE": "VASTE_PERIODE",
  "SCHOOLJAARLICENTIE": "SCHOOLJAARLICENTIE",
  "AANTAL": "AANTAL",
  "ABONNEMENT": "ABONNEMENT"
});
exports.LicenseActivationType = t.enumtype({
  "PERIODE": "PERIODE",
  "EINDE_PRODUCT": "EINDE_PRODUCT",
  "KANTELDATUM": "KANTELDATUM",
  "AANTAL_DAGEN": "AANTAL_DAGEN"
});
exports.BrowserType = t.enumtype({
  "FIREFOX": "FIREFOX",
  "CHROME": "CHROME",
  "EDGE": "EDGE",
  "SAFARI": "SAFARI",
  "IE": "IE",
  "OPERA": "OPERA",
  "OVERIGE": "OVERIGE"
});
exports.ProductType = t.enumtype({
  "WEB_BROWSER": "WEB_BROWSER",
  "DOWNLOADABLE_INSTALLER": "DOWNLOADABLE_INSTALLER",
  "BOEK": "BOEK",
  "USB": "USB",
  "CD_ROM": "CD_ROM",
  "DVD": "DVD",
  "ANDERS": "ANDERS"
});
exports.PlatformType = t.enumtype({
  "IOS": "IOS",
  "ANDROID": "ANDROID",
  "WINDOWS": "WINDOWS",
  "HTML5": "HTML5"
});
exports.DeviceType = t.enumtype({
  "PC_READY": "PC_READY",
  "MOBILE_READY": "MOBILE_READY",
  "TABLET_READY": "TABLET_READY"
});
exports.ProductState = t.enumtype({
  "NOG_NIET_LEVERBAAR": "NOG_NIET_LEVERBAAR",
  "BEPERKT_LEVERBAAR": "BEPERKT_LEVERBAAR",
  "LEVERBAAR": "LEVERBAAR",
  "TIJDELIJK_NIET_LEVERBAAR": "TIJDELIJK_NIET_LEVERBAAR",
  "NIET_MEER_LEVERBAAR": "NIET_MEER_LEVERBAAR",
  "ZAL_NIET_VERSCHIJNEN": "ZAL_NIET_VERSCHIJNEN"
});
exports.ProductUsage = t.enumtype({
  "LEERBOEK": "LEERBOEK",
  "WERKBOEK": "WERKBOEK",
  "EXAMENBUNDEL": "EXAMENBUNDEL",
  "OEFENMATERIAAL": "OEFENMATERIAAL",
  "NASLAGWERK": "NASLAGWERK",
  "ANDERS": "ANDERS"
});
exports.EducationalYearType = t.enumtype({
  "JAAR_1": "JAAR_1",
  "JAAR_2": "JAAR_2",
  "JAAR_3": "JAAR_3",
  "JAAR_4": "JAAR_4",
  "JAAR_5": "JAAR_5",
  "JAAR_6": "JAAR_6",
  "JAAR_7": "JAAR_7",
  "JAAR_8": "JAAR_8",
  "ONDERBOUW": "ONDERBOUW",
  "BOVENBOUW": "BOVENBOUW"
});
exports.SectorType = t.enumtype({
  "PO": "PO",
  "VO": "VO",
  "MBO": "MBO",
  "HO": "HO",
  "WO": "WO"
});
exports.EndUserRoleType = t.enumtype({
  "ONDERWIJSGEVER": "Onderwijsgever",
  "ONDERWIJSVOLGER": "Onderwijsvolger",
  "BEHEERDER": "Beheerder"
});
exports.UitgeverLicenseInfo = t.iface([], {
  "startDate": t.opt("Date"),
  "activationDate": t.opt("Date"),
  "endDate": t.opt("Date"),
  "product": t.opt("UitgeverProductInfo"),
  "licenseState": t.opt("TLinkLicenseState"),
  "licenseType": t.opt("LicenseType")
});
exports.UitgeverProductInfo = t.iface([], {
  "ean": "string",
  "licenseType": t.opt("LicenseType"),
  "publisherInfo": t.opt("PublisherInfo")
});
exports.Order = t.iface([], {
  "ean": "string",
  "distributor": "string",
  "amount": "number",
  "orderId": "string",
  "orderLineId": t.opt("string"),
  "contractId": t.opt("string"),
  "schoolYear": t.opt("string")
});
exports.EckRestTarget = t.enumtype({
  "TOEGANG": "TOEGANG",
  "THIEME": "THIEME"
});
exports.Price = t.iface([], {
  "amount": "number",
  "vat": "number"
});
exports.ChainType = t.enumtype({
  "ECK": "ECK",
  "BASISPOORT": "BASISPOORT"
});
exports.BillingModelType = t.enumtype({
  "PERCENTAGE": "Percentage",
  "VASTE_PRIJS": "Vaste Prijs"
});
exports.EckProductProperties = t.iface([], {
  "edition": t.opt(t.union("string", "null")),
  "productUrl": t.opt(t.union("string", "null")),
  "authors": t.array("string"),
  "sectors": t.array("SectorType"),
  "courses": t.array("string"),
  "levels": t.array("string"),
  "productThumbnailLocation": t.opt(t.union("string", "null")),
  "productFamilyName": t.opt(t.union("string", "null")),
  "contentLocation": t.opt(t.union("string", "null")),
  "version": t.opt(t.union("string", "null")),
  "productState": t.opt("ProductState"),
  "informationLocation": t.opt(t.union("string", "null")),
  "productUsages": t.array("ProductUsage"),
  "consumptionProduct": "boolean",
  "years": t.opt(t.array("EducationalYearType")),
  "subjects": t.opt(t.array("string")),
  "saleUnitSize": "number",
  "priceIsIndicative": "boolean",
  "description": t.opt(t.union("string", "null")),
  "prices": t.array("Price"),
  "consumerPrice": "number",
  "catalogItem": t.opt("boolean"),
  "eckOrderAllowed": t.opt("boolean"),
  "isSeparatelyAvailable": t.opt("boolean"),
  "organisationPrivacyLocation": t.opt("boolean"),
  "productDescriptions": t.opt(t.union(t.array("string"), t.tuple()))
});
exports.BasispoortProductProperties = t.iface([], {
  "productThumbnailLocation": t.opt(t.union("string", "null")),
  "productFamilyName": "string",
  "productFamilyCode": "string",
  "productFamilyThumbnailLocation": t.opt(t.union("string", "null"))
});
exports.PublisherInfo = t.iface([], {
  "cpi": t.opt("string"),
  "subject": t.opt("string"),
  [t.indexKey]: "any"
});
exports.IdentityProvider = t.enumtype({
  "KENNISNET_FEDERATIE": "KENNISNET_FEDERATIE",
  "TOEGANG_ORG": "TOEGANG_ORG",
  "BASISPOORT": "BASISPOORT"
});
exports.ProductSyncValidationResult = t.iface([], {
  "isValid": "boolean",
  "missingProps": t.array("string"),
  "invalidProps": t.array("string")
});
exports.ProductSyncErrorCodes = t.enumtype({
  "SUCCESS": 0,
  "MISSING_PARAMETERS": 1,
  "FAILED": 2,
  "INVALID_VALUES": 3
});
exports.Designation = t.union(t.lit('PERSONAL'), t.lit('INSTITUTION'));
exports.UitgeverProduct = t.iface([], {
  "operation": "string",
  "productType": "number",
  "visibleInECKCatalog": t.opt("boolean"),
  "title": t.opt("string"),
  "visible": t.opt("boolean"),
  "name": t.opt("string"),
  "productMethodUrl": "string",
  "EAN": "string",
  "licenseDesignation": "Designation",
  "licenseExpiration": "string",
  "licenseDuration": "number",
  "reminderFirst": "number",
  "reminderSecond": "number",
  "EAUrl": "string",
  "intendedEndUserRole": t.opt("string"),
  "productStartDate": t.opt("string"),
  "productEndDate": t.opt("string"),
  "gebruikersRol": t.opt("string"),
  "productStartDatum": t.opt("string"),
  "productEindDatum": t.opt("string"),
  "serviceLicenseAllowed": t.opt("boolean"),
  "productThumbnailLocation": "string",
  "productFamilyName": t.opt("string"),
  "productFamilyCode": t.opt("string"),
  "productFamilyThumbnailLocation": t.opt("string"),
  "environments": t.opt(t.iface([], {
    "platforms": t.array("string"),
    "devices": t.array("string"),
    "browsers": t.array("string")
  })),
  "contentLocation": t.opt("string"),
  "version": t.opt("string"),
  "productState": t.opt("string"),
  "informationLocation": t.opt("string"),
  "consumptionProduct": "boolean",
  "productUsages": t.opt(t.array("string")),
  "years": t.opt(t.array("string")),
  "subjects": t.opt("string"),
  "saleUnitSize": "number",
  "priceIsIndicative": "boolean",
  "description": "string",
  "authors": t.array("string"),
  "sectors": t.array("string"),
  "courses": t.array("string"),
  "levels": t.array("string"),
  "consumerPrice": "number",
  "prices": t.array("Price"),
  "medium": "ProductType",
  "licenseModel": "UitgeverLicenseModel",
  "eckFulfillmentAllowed": t.opt("boolean"),
  "publisherInfo": t.opt("PublisherInfo"),
  "eckOrderAllowed": t.opt("boolean"),
  "subproductFolio": t.opt("string"),
  "subproductDigitaal": t.opt("string")
});
exports.UitgeverLicenseModel = t.iface([], {
  "description": "string",
  "licenseType": t.opt("LicenseType"),
  "schoolYearAmount": t.opt("number"),
  "maximumLoginAmount": t.opt("number"),
  "duration": t.opt("number"),
  "endDate": t.opt("Date"),
  "licenseActivationType": t.opt("LicenseActivationType"),
  "activatableFrom": t.opt("Date"),
  "activatableUntil": t.opt("Date"),
  "activatableAmountOfDays": "number",
  "tiltDate": t.opt("string"),
  "schoolYearEndDate": t.opt("string")
});
exports.AuthenticationType = t.enumtype({
  "TOEGANG_SSO": "TOEGANG_SSO",
  "OAUTH2": "OAUTH2"
});
exports.IncompleteToegangProduct = t.iface([], {
  "ean": "string",
  "productType": t.opt("ProductType"),
  "authenticationType": t.opt("AuthenticationType"),
  "introductionDate": t.opt("Date"),
  "endOfLifeDate": t.opt("Date"),
  "title": t.opt("string"),
  "intendedEndUserRole": t.opt("EndUserRoleType"),
  "ssoUrl": t.opt("string"),
  "deliverable": t.opt("boolean"),
  "active": t.opt("boolean"),
  "serviceLicenseAllowed": t.opt("boolean"),
  "productThumbnailLocation": t.opt("string"),
  "productFamilyName": t.opt("string"),
  "productFamilyCode": t.opt("string"),
  "productFamilyThumbnailLocation": t.opt("string"),
  "platforms": t.opt(t.array("string")),
  "devices": t.opt(t.array("string")),
  "browsers": t.opt(t.array("string")),
  "contentLocation": t.opt("string"),
  "version": t.opt("string"),
  "productState": t.opt("string"),
  "informationLocation": t.opt("string"),
  "consumptionProduct": t.opt("boolean"),
  "productUsages": t.opt(t.array("string")),
  "years": t.opt(t.array("string")),
  "subjects": t.opt(t.array("string")),
  "saleUnitSize": t.opt("number"),
  "priceIsIndicative": t.opt("boolean"),
  "description": t.opt("string"),
  "authors": t.opt(t.array("string")),
  "sectors": t.opt(t.array("string")),
  "courses": t.opt(t.array("string")),
  "levels": t.opt(t.array("string")),
  "consumerPrice": t.opt("number"),
  "prices": t.opt(t.array("Price")),
  "licenseModel": t.opt("UitgeverLicenseModel"),
  "productUrl": t.opt("string"),
  "catalogItem": t.opt("boolean"),
  "publisherInfo": t.opt("PublisherInfo"),
  "eckOrderAllowed": t.opt("boolean"),
  "subproductFolio": t.opt("string"),
  "subproductDigitaal": t.opt("string")
});
exports.PoRole = t.enumtype({
  "Student": "leerling",
  "Teacher": "leerkracht"
});
exports.ProfileRole = t.enumtype({
  "Student": "student",
  "Teacher": "teacher"
});
const exportedTypeSuite = {
  Recht: exports.Recht,
  TLinkLicenseState: exports.TLinkLicenseState,
  LicenseType: exports.LicenseType,
  LicenseActivationType: exports.LicenseActivationType,
  BrowserType: exports.BrowserType,
  ProductType: exports.ProductType,
  PlatformType: exports.PlatformType,
  DeviceType: exports.DeviceType,
  ProductState: exports.ProductState,
  ProductUsage: exports.ProductUsage,
  EducationalYearType: exports.EducationalYearType,
  SectorType: exports.SectorType,
  EndUserRoleType: exports.EndUserRoleType,
  UitgeverLicenseInfo: exports.UitgeverLicenseInfo,
  UitgeverProductInfo: exports.UitgeverProductInfo,
  Order: exports.Order,
  EckRestTarget: exports.EckRestTarget,
  Price: exports.Price,
  ChainType: exports.ChainType,
  BillingModelType: exports.BillingModelType,
  EckProductProperties: exports.EckProductProperties,
  BasispoortProductProperties: exports.BasispoortProductProperties,
  PublisherInfo: exports.PublisherInfo,
  IdentityProvider: exports.IdentityProvider,
  ProductSyncValidationResult: exports.ProductSyncValidationResult,
  ProductSyncErrorCodes: exports.ProductSyncErrorCodes,
  Designation: exports.Designation,
  UitgeverProduct: exports.UitgeverProduct,
  UitgeverLicenseModel: exports.UitgeverLicenseModel,
  AuthenticationType: exports.AuthenticationType,
  IncompleteToegangProduct: exports.IncompleteToegangProduct,
  PoRole: exports.PoRole,
  ProfileRole: exports.ProfileRole
};
exports.default = exportedTypeSuite;