import {Button, Grid, Paper, Typography, withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import {format} from 'date-fns';
import nlLocale from 'date-fns/locale/nl';
import React, {useEffect, useReducer, useState} from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import GenericField from '../../../components/input/genericfield';
import ReadOnly from '../../../components/input/readonly';
import {hasRight} from '../../../components/permissions/withpermissions';
import SimpleTable from '../../../components/simpletable';
import entityReducer, {initialState} from '../../../reducers/entityreducer';
import * as ActionCreators from '../../../store/actioncreators';
import autodispatch from '../../../store/autodispatch';
import defaultConnect from '../../../store/connector';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        margin: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff',
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    grid: {
        flexWrap: 'wrap',
        '& > div > div': {
            width: '100%',
        },
    },
    dateContainer: {
        marginBottom: theme.spacing(1),
    },
    inputContainer: {
        minWidth: 250,
    },
    gridItemWide: {
        margin: theme.spacing(2),
        minWidth: 350,
    },
    simpleTable: {
        minWidth: 250,
    },
    colorWhite: {
        color: '#fff',
    },
});

function ProductExtendedView(props) {
    const {classes, authUserReducer, match} = props;

    const [showExtraFields, setShowExtraFields] = useState(false);

    const [state, dispatch] = useReducer(entityReducer, initialState);
    const {entity, loading} = state;
    const {fetchItem, fetchData} = autodispatch(ActionCreators, dispatch);

    useEffect(() => {
        const {id, ean} = match.params;
        if (id) {
            fetchItem({endpoint: 'products', id: id});
        } else if (ean) {
            fetchData({endpoint: 'products', filter: {ean: ean}});
        }
    }, [match]);

    const serviceWindowsColumns = [
        {
            header: 'Begindatum',
            renderer: (row) => format(new Date(row.startDate), 'dd-MM-yyyy HH:mm', {locale: nlLocale}),
        },
        {
            header: 'Einddatum',
            renderer: (row) => format(new Date(row.endDate), 'dd-MM-yyyy HH:mm', {locale: nlLocale}),
        },
        {
            header: '',
            renderer: (row) => {
                return (
                    entity && (
                        <Link to={`/producten/${entity.id}/servicewindows/${row.id}/edit`}>
                            <Icon color="primary">edit</Icon>
                        </Link>
                    )
                );
            },
        },
    ];
    return entity && !loading ? (
        <React.Fragment>
            <Paper className={classes.root}>
                <Typography component="h1" variant="h5" gutterBottom noWrap>
                    Overzicht {entity ? entity.toString : ''}
                </Typography>
                <Grid container className={classes.grid}>
                    <Grid container direction="row" className={classes.dateContainer}>
                        <Grid item xs={3}>
                            <GenericField label="Aangemaakt">
                                <ReadOnly>{new Date(entity.createdAt).toLocaleString()}</ReadOnly>
                            </GenericField>
                        </Grid>
                        <Grid item xs={6}>
                            <GenericField label="Laatst gewijzigd" style={{width: '100%'}}>
                                <ReadOnly>{new Date(entity.updatedAt).toLocaleString()}</ReadOnly>
                            </GenericField>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <GenericField label="EAN">
                            <ReadOnly>{entity.ean || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Digitaal-ean">
                            <ReadOnly>{entity.subproductDigitaal || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Folio-ean">
                            <ReadOnly>{entity.subproductFolio || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Titel">
                            <ReadOnly>{entity.title || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Uitgever">
                            <ReadOnly>{entity.organisation.name || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Sectoren">
                            <ReadOnly>
                                {(entity.customProperties.sectors && entity.customProperties.sectors.join(', ')) || '-'}
                            </ReadOnly>
                        </GenericField>
                        <GenericField label="Distributieketens">
                            <ReadOnly>{entity?.chains?.join(', ') || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Eindgebruikersrol">
                            <ReadOnly>{entity.intendedEndUserRole || '-'}</ReadOnly>
                        </GenericField>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <GenericField label="Begindatum">
                            <ReadOnly>
                                {entity.introductionDate
                                    ? format(new Date(entity.introductionDate), 'dd-MM-yyyy', {locale: nlLocale})
                                    : '-'}
                            </ReadOnly>
                        </GenericField>
                        <GenericField label="Einddatum">
                            <ReadOnly>
                                {entity.endOfLifeDate
                                    ? format(new Date(entity.endOfLifeDate), 'dd-MM-yyyy', {locale: nlLocale})
                                    : '-'}
                            </ReadOnly>
                        </GenericField>
                        <GenericField label="Type">
                            <ReadOnly>{entity.productType || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Uitleveren aan catalogservice">
                            <ReadOnly>{entity.deliverable ? 'JA' : 'NEE'}</ReadOnly>
                        </GenericField>
                        <GenericField label="Product url">
                            <ReadOnly>{entity.customProperties.productUrl || '-'}</ReadOnly>
                        </GenericField>
                        <GenericField label="SSO url">
                            <ReadOnly>{entity.ssoUrl || '-'}</ReadOnly>
                        </GenericField>
                    </Grid>
                    {entity.productType === 'WEB_BROWSER' && (
                        <Grid item sm={12} md={4}>
                            <GenericField label="Licentietype">
                                <ReadOnly>{entity.licenseModel?.licenseType || '-'}</ReadOnly>
                            </GenericField>
                            {entity.licenseModel?.licenseType === 'SCHOOLJAARLICENTIE' && (
                                <React.Fragment>
                                    <GenericField label="Aantal schooljaren">
                                        <ReadOnly>{entity.licenseModel?.schoolYearAmount || '-'}</ReadOnly>
                                    </GenericField>
                                    <GenericField label="Schooljaar einddatum">
                                        <ReadOnly>
                                            {entity.licenseModel?.schoolYearEndDate
                                                ? format(new Date(entity.licenseModel.schoolYearEndDate), 'dd MMMM', {
                                                      locale: nlLocale,
                                                  })
                                                : '-'}
                                        </ReadOnly>
                                    </GenericField>
                                </React.Fragment>
                            )}
                            {entity.licenseModel?.licenseType === 'AANTAL' && (
                                <GenericField label="Aantal">
                                    <ReadOnly>{entity.licenseModel?.maximumLoginAmount}</ReadOnly>
                                </GenericField>
                            )}
                            {(entity.licenseModel?.licenseType === 'VASTE_PERIODE' ||
                                entity.licenseModel?.licenseType === 'ABONNEMENT') && (
                                <GenericField label="Aantal dagen">
                                    <ReadOnly>{entity.licenseModel?.duration}</ReadOnly>
                                </GenericField>
                            )}
                            {entity.licenseModel?.licenseType === 'VASTE_DATUM' && (
                                <GenericField label="Licentie einddatum">
                                    <ReadOnly>
                                        {entity.licenseModel?.endDate
                                            ? format(new Date(entity.licenseModel.endDate), 'dd-MM-yyyy', {locale: nlLocale})
                                            : '-'}
                                    </ReadOnly>
                                </GenericField>
                            )}
                            <GenericField label="Licentie activatietype">
                                <ReadOnly>{entity.licenseModel?.licenseActivationType}</ReadOnly>
                            </GenericField>
                            {entity.licenseModel?.licenseActivationType === 'KANTELDATUM' && (
                                <GenericField label="Kanteldatum">
                                    <ReadOnly>
                                        {entity.licenseModel?.tiltDate
                                            ? format(new Date(entity.licenseModel.tiltDate), 'dd MMMM', {locale: nlLocale})
                                            : '-'}
                                    </ReadOnly>
                                </GenericField>
                            )}
                            {entity.licenseModel?.licenseActivationType === 'AANTAL_DAGEN' && (
                                <GenericField label="Aantal dagen activeerbaar">
                                    <ReadOnly>{entity.licenseModel?.activatableAmountOfDays}</ReadOnly>
                                </GenericField>
                            )}
                            {entity.licenseModel?.licenseActivationType === 'PERIODE' && (
                                <React.Fragment>
                                    <GenericField label="Te activeren vanaf">
                                        <ReadOnly>
                                            {entity.licenseModel?.activatableFrom
                                                ? format(new Date(entity.licenseModel.activatableFrom), 'dd-MM-yyyy', {
                                                      locale: nlLocale,
                                                  })
                                                : '-'}
                                        </ReadOnly>
                                    </GenericField>
                                    <GenericField label="Te activeren tot">
                                        <ReadOnly>
                                            {entity.licenseModel?.activatableUntil
                                                ? format(new Date(entity.licenseModel.activatableUntil), 'dd-MM-yyyy', {
                                                      locale: nlLocale,
                                                  })
                                                : '-'}
                                        </ReadOnly>
                                    </GenericField>
                                </React.Fragment>
                            )}
                            <GenericField label="Servicelicentie toegestaan">
                                <ReadOnly>{entity.serviceLicenseAllowed ? 'JA' : 'NEE'}</ReadOnly>
                            </GenericField>
                            {entity.productType === 'WEB_BROWSER' && (
                                <GenericField label="Browsers">
                                    <ReadOnly>{(entity.browsers && entity.browsers.join(', ')) || '-'}</ReadOnly>
                                </GenericField>
                            )}
                            <GenericField label="Product is een vak voor schoolcontract">
                                <ReadOnly>{entity.isSubject ? 'JA' : 'NEE'}</ReadOnly>
                            </GenericField>
                        </Grid>
                    )}
                    {entity.publisherInfo && (
                        <Grid container className={classes.grid}>
                            {Object.keys(entity.publisherInfo).map((p) => (
                                <Grid item sm={12} md={0}>
                                    <GenericField label={p}>
                                        <ReadOnly>{entity.publisherInfo[p]}</ReadOnly>
                                    </GenericField>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
                {showExtraFields && (
                    <Grid container className={classes.grid}>
                        <Grid item sm={12} md={4}>
                            <GenericField label="Methodenaam">
                                <ReadOnly>{entity.customProperties.productFamilyName || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Methodecode">
                                <ReadOnly>{entity.customProperties.productFamilyCode || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Editie">
                                <ReadOnly>{entity.customProperties.edition || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Versie">
                                <ReadOnly>{entity.customProperties.version || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Leerjaren">
                                <ReadOnly>
                                    {(entity.customProperties.years && entity.customProperties.years.join(', ')) || '-'}
                                </ReadOnly>
                            </GenericField>
                            <GenericField label="Auteurs">
                                <ReadOnly>
                                    {(entity.customProperties.authors && entity.customProperties.authors.join(', ')) || '-'}
                                </ReadOnly>
                            </GenericField>
                            <GenericField label="Beschrijving">
                                <ReadOnly>
                                    {(entity.customProperties.description && entity.customProperties.description) || '-'}
                                </ReadOnly>
                            </GenericField>
                            <GenericField label="Consumptie product">
                                <ReadOnly>{entity.customProperties.consumptionProduct ? 'JA' : 'NEE'}</ReadOnly>
                            </GenericField>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <GenericField label="Productthumbnail Url">
                                <ReadOnly>{entity.customProperties.productThumbnailLocation || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Methode thumbnail Url">
                                <ReadOnly>{entity.customProperties.productFamilyThumbnailLocation || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Contentlocation Url">
                                <ReadOnly>{entity.customProperties.contentLocation || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Informationlocation Url">
                                <ReadOnly>{entity.customProperties.informationLocation || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Platformen">
                                <ReadOnly>{(entity.platforms && entity.platforms.join(', ')) || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Devices">
                                <ReadOnly>{(entity.devices && entity.devices.join(', ')) || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Vakken">
                                <ReadOnly>
                                    {(entity.customProperties.courses && entity.customProperties.courses.join(', ')) || '-'}
                                </ReadOnly>
                            </GenericField>
                            <GenericField label="Subjects">
                                <ReadOnly>
                                    {(entity.customProperties.subjects && entity.customProperties.subjects.join(', ')) || '-'}
                                </ReadOnly>
                            </GenericField>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <GenericField label="Niveaus">
                                <ReadOnly>
                                    {(entity.customProperties.levels && entity.customProperties.levels.join(', ')) || '-'}
                                </ReadOnly>
                            </GenericField>
                            <GenericField label="Verkoopeenheid">
                                <ReadOnly>{entity.customProperties.saleUnitSize || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Prijzen">
                                <ReadOnly>
                                    {(entity.customProperties.prices &&
                                        entity.customProperties.prices.length > 0 &&
                                        entity.customProperties.prices.map((p) => `${p.amount} (${p.vat}%)`).join(', ')) ||
                                        '-'}
                                </ReadOnly>
                            </GenericField>
                            <GenericField label="Consumentenprijs">
                                <ReadOnly>{entity.customProperties.consumerPrice || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Prijs is een indicatie">
                                <ReadOnly>{entity.customProperties.priceIsIndicative ? 'JA' : 'NEE'}</ReadOnly>
                            </GenericField>
                            <GenericField label="Product status">
                                <ReadOnly>
                                    {(entity.customProperties.productState && entity.customProperties.productState) || '-'}
                                </ReadOnly>
                            </GenericField>
                            <GenericField label="Product gebruik">
                                <ReadOnly>
                                    {(entity.customProperties.productUsages &&
                                        entity.customProperties.productUsages.join(', ')) ||
                                        '-'}
                                </ReadOnly>
                            </GenericField>
                        </Grid>
                    </Grid>
                )}
                <Button
                    className={classNames(classes.button, classes.colorWhite)}
                    variant="contained"
                    size="medium"
                    color="primary"
                    type="button"
                    onClick={() => setShowExtraFields(!showExtraFields)}
                >
                    {!showExtraFields ? 'Toon extra velden' : 'Verberg extra velden'}
                </Button>
            </Paper>
            {hasRight(authUserReducer, Recht.PRODUCTEN_SERVICEWINDOWS_TOEGANG) && (
                <Paper className={classes.root}>
                    <Grid container>
                        <Typography component="h1" variant="h5" gutterBottom noWrap>
                            Service Windows
                        </Typography>
                        <SimpleTable
                            endpoint={`servicewindows`}
                            sortBy="startDate"
                            sortDirection="DESC"
                            filter={{
                                product: entity.id,
                            }}
                            columns={serviceWindowsColumns}
                        />
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            className={classNames(classes.button, classes.colorWhite)}
                            component={Link}
                            to={`/producten/${entity.id}/servicewindows/new`}
                        >
                            <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                            Service Window toevoegen
                        </Button>
                    </Grid>
                </Paper>
            )}
            {hasRight(authUserReducer, Recht.PRODUCTEN_AANPASSEN) && (
                <Fab
                    color="secondary"
                    aria-label="Edit"
                    className={classes.fab}
                    component={Link}
                    to={`/producten/${entity.id}/edit`}
                >
                    <EditIcon />
                </Fab>
            )}
        </React.Fragment>
    ) : (
        <React.Fragment>
            <CircularProgress />
        </React.Fragment>
    );
}

export default compose(defaultConnect, withStyles(styles))(ProductExtendedView);
