import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import * as uuid from 'uuid/v4';

const actionMapping = {
    EDIT: {path: 'edit', icon: 'edit'},
    NEW: {path: 'new', icon: 'edit'},
    VIEW: {path: 'edit', icon: 'info'},
    EXTENDEDVIEW: {path: 'extended-view', icon: 'search'}
};

export const ActionLink = ({action, endpoint, rowData}) => {
    if (rowData) {
        if (!actionMapping[action]) {
            throw new Error(`Action '${action} 'is not defined`);
        }
        const link = `${endpoint}/${rowData.id}/${actionMapping[action]['path']}`;

        return (
            <Link to={link}>
                <Icon color="primary">{actionMapping[action]['icon']}</Icon>
            </Link>
        );
    } else {
        return <span />;
    }
};

ActionLink.propTypes = {
    endpoint: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
        id: PropTypes.string
    })
};

export const ActionLinks = ({actions, endpoint, rowData}) => {
    if (rowData) {
        const icons = [];
        for (const action of actions) {
            if (!actionMapping[action]) {
                throw new Error(`Action '${action}' is not defined`);
            }
            icons.push(<ActionLink action={action} endpoint={endpoint} rowData={rowData} key={uuid()} />);
        }
        return <React.Fragment>{icons}</React.Fragment>;
    } else {
        return <span></span>;
    }
};

ActionLinks.propTypes = {
    endpoint: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(PropTypes.string),
    rowData: PropTypes.shape({
        id: PropTypes.string
    })
};
