import Typography from '@material-ui/core/Typography';
import React from 'react';

class PageNotFound extends React.Component {
    render() {
        return (
            <Typography component="h1" variant="h6" color="inherit" noWrap>
                Pagina niet gevonden
            </Typography>
        );
    }
}

export default PageNotFound;
