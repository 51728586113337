import * as Actions from '../store/actions';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.RESET_CLIENT_SECRET:
            return {...state, entityId: action.entityId, secret: action.secret};
        case Actions.REMOVE_OAUTHCLIENT_FROM_CACHE_SUCCESS:
            return {...state, entityId: action.entityId};
        case Actions.REMOVE_OAUTHCLIENT_FROM_CACHE_ERROR:
            return {...state, entityId: action.entityId, error: action.error.message};
        default:
            return state;
    }
};
