import {Checkbox, FormControl, Grid, ListItemText, MenuItem, Select, TextField, Typography, withStyles} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import DataTable from '../../components/datatable';
import {ArrayRenderer, BooleanRenderer} from '../../components/datatable/contentrenderers';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, isUitgeverAccount, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    formInputBox: {
        width: 250,
        margin: theme.spacing(1)
    }
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250
        }
    }
};

const sectors = ['PO', 'VO', 'MBO', 'HO'];

class Organisaties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    q: Types.STRING,
                    type: Types.STRING,
                    verified: Types.STRING,
                    sectors: Types.ARRAY
                },
                props.history
            ),
            version: 0
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    showType() {
        return isUitgeverAccount(this.props.authUserReducer)
            ? {}
            : {
                label: 'Type',
                dataKey: 'type',
                disableSort: true,
                component: <ArrayRenderer />
            };
    }

    render() {
        const {classes, authUserReducer, history} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Typography component="h1" variant="h5" gutterBottom noWrap>
                    Organisaties
                </Typography>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl>
                            <SearchTextField
                                fullWidth
                                className={classes.formInputBox}
                                label="Esn, naam, brin, instelling-brin of portalid"
                                autoFocus={true}
                                value={this.state.filters.q}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v => {
                                    this.setState({filters: {...this.state.filters, q: v}});
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <Select
                                labelId="sectorInputLabel"
                                multiple
                                margin="dense"
                                value={this.state.filters.sectors}
                                onChange={event => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            sectors: event.target.value
                                        }
                                    });
                                }}
                                input={<TextField label="Sector" variant="outlined" />}
                                renderValue={selected => selected.sort().join(', ')}
                                MenuProps={MenuProps}
                            >
                                {sectors.map(sector => (
                                    <MenuItem key={sector} value={sector}>
                                        <Checkbox checked={this.state.filters.sectors.includes(sector)} />
                                        <ListItemText primary={sector} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {hasRight(authUserReducer, Recht.ORGANISATIES_UITGEVERS_DISTRIBUTEURS_TOEGANG) && (
                        <Grid item>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={classes.formInputBox}
                                label="Type"
                                value={this.state.filters.type}
                                onChange={e => {
                                    this.setState({filters: {...this.state.filters, type: e.target.value}});
                                }}
                            >
                                <MenuItem value="School">School</MenuItem>
                                <MenuItem value="Uitgever">Uitgever</MenuItem>
                                <MenuItem value="Distributeur">Distributeur</MenuItem>
                                <MenuItem value="">Alles</MenuItem>
                            </TextField>
                        </Grid>
                    )}
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className={classes.formInputBox}
                            label="Geverifiëerd"
                            value={this.state.filters.verified}
                            onChange={e => {
                                this.setState({filters: {...this.state.filters, verified: e.target.value}});
                            }}
                        >
                            <MenuItem value={true}>Ja</MenuItem>
                            <MenuItem value={false}>Nee</MenuItem>
                            <MenuItem value="">Beide</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                    <Grid container>
                        <DataTable
                            csv
                            endpoint="organisations"
                            version={this.state.version}
                            onRowClick={data => {
                                if (data.id) {
                                    history.push(`/organisaties/${data.id}/extended-view`);
                                }
                            }}
                            filter={this.state.filters}
                            columns={[
                                {label: 'Naam', dataKey: 'name'},
                                {label: 'ESN', dataKey: 'esn', maxWidth: 'auto'},
                                this.showType(),
                                {label: 'Brin', dataKey: 'brin'},
                                {label: 'Instelling Brin', dataKey: 'instellingBrin'},
                                {label: 'portal Id', dataKey: 'portalId'},
                                {label: 'sector', dataKey: 'sector'},
                                {
                                    label: 'Geverifieerd',
                                    dataKey: 'verified',
                                    maxWidth: 150,
                                    component: <BooleanRenderer />,
                                    styling: {justifyContent: 'center'}
                                }
                            ]}
                        />
                    </Grid>
                    {hasRight(authUserReducer, Recht.ORGANISATIES_TOEVOEGEN) && (
                        <Fab color="secondary" aria-label="Add" className={classes.fab} component={Link} to="/organisaties/new">
                            <AddIcon />
                        </Fab>
                    )}
                </Grid>
            </Paper>
        );
    }
}

export default compose(withRouter, defaultConnect, withPermissions(Recht.ORGANISATIES_INZIEN), withStyles(styles))(Organisaties);
