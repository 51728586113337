import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import {compose} from 'recompose';

import DataTable from '../../components/datatable';
import {
    BooleanRenderer,
    DateToLocalDateRenderer,
    DatetimeToLocalDatetimeRenderer
} from '../../components/datatable/contentrenderers';
import defaultConnect from '../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

export const UrlOpenRenderer = ({cellData, rowData}) => {
    const onClickDownload = React.useCallback(() => {
        let anchor = document.createElement('a');
        anchor.href = cellData;
        anchor.target = '_blank';
        anchor.download = 'export.csv';
        anchor.click();
        window.URL.revokeObjectURL(cellData);
    });

    if (cellData && new Date(rowData.downloadableUntil) > new Date()) {
        return (
            <Button onClick={onClickDownload} variant="outlined" size="small" color="primary">
                Download
            </Button>
        );
    } else {
        return null;
    }
};

function ExportsView(props) {
    const {classes} = props;
    return (
        <Paper className={classes.root}>
            <Typography component="h1" variant="h5">
                Mijn Exports
            </Typography>
            <Grid container>
                <DataTable
                    endpoint="exportjobs"
                    version={Number(new Date())}
                    columns={[
                        {label: 'Voltooid', dataKey: 'hasFinished', maxWidth: 150, component: <BooleanRenderer />},
                        {label: 'Export', dataKey: 'exportRoute'},
                        {label: 'Gestart op', dataKey: 'createdAt', component: <DatetimeToLocalDatetimeRenderer />},
                        {
                            label: 'Download geldig tot',
                            dataKey: 'downloadableUntil',
                            component: <DateToLocalDateRenderer />
                        },
                        {label: 'URL', dataKey: 'url'},
                        {label: 'Download', dataKey: 'url', component: <UrlOpenRenderer />}
                    ]}
                />
            </Grid>
        </Paper>
    );
}

export default compose(defaultConnect, withStyles(styles))(ExportsView);
