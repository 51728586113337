export const SORT_DATATABLE = 'SORT_DATATABLE';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_ITEM = 'FETCH_ITEM';
export const FETCH_CSV = 'FETCH_CSV';
export const POST_DATA = 'POST_DATA';
export const PUT_DATA = 'PUT_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const RECEIVE_ITEM = 'RECEIVE_ITEM';
export const RECEIVE_CSV = 'RECEIVE_CSV';
export const RECEIVE_DATA_ERROR = 'RECEIVE_DATA_ERROR';
export const RECEIVE_CSV_ERROR = 'RECEIVE_CSV_ERROR';
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const POST_DATA_ERROR = 'POST_DATA_ERROR';
export const PUT_DATA_SUCCESS = 'PUT_DATA_SUCCESS';
export const PUT_DATA_ERROR = 'PUT_DATA_ERROR';
export const SAVE_SUPPORTENTRY = 'SAVE_SUPPORTENTRY';
export const SUPPORTENTRY_CLOSED = 'SUPPORTENTRY_CLOSED';
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCES';
export const DELETE_ITEM_ERROR = 'DELETE_ITEM_ERROR';
export const DESTROY_SESSION_SUCCESS = 'DESTROY_SESSION_SUCCESS';
export const DESTROY_SESSION_ERROR = 'DESTROY_SESSION_ERROR';
export const RESEND = 'RESEND';
export const RESEND_OK = 'RESEND_OK';
export const RESEND_FAILED = 'RESEND_FAILED';
export const RESET_CLIENT_SECRET = 'RESET_CLIENT_SECRET';
export const IMPORT_OK = 'IMPORT_OK';
export const IMPORT_FAILED = 'IMPORT_FAILED';
export const CLEAR_IMPORT = 'CLEAR_IMPORT';
export const FETCH_ADMIN_INFO = 'FETCH_ADMIN_INFO';
export const RECEIVE_ADMIN_INFO = 'RECEIVE_ADMIN_INFO';
export const REMOVE_OAUTHCLIENT_FROM_CACHE_SUCCESS = 'REMOVE_OAUTHCLIENT_FROM_CACHE_SUCCESS';
export const REMOVE_OAUTHCLIENT_FROM_CACHE_ERROR = 'REMOVE_OAUTHCLIENT_FROM_CACHE_ERROR';
export const ORDERBALANS_DISTRIBUTEURS_FETCH = 'ORDERBALANS_DISTRIBUTEURS_FETCH';
export const ORDERBALANS_DISTRIBUTEURS_FETCH_OK = 'ORDERBALANS_DISTRIBUTEURS_FETCH_OK';
export const ORDERBALANS_DISTRIBUTEURS_FETCH_ERROR = 'ORDERBALANS_DISTRIBUTEURS_ERROR';
export const FETCH_ADJUSTED_INVOICE = 'FETCH_ADJUSTED_INVOICE';
export const RECEIVE_ADJUSTED_INVOICE = 'RECEIVE_ADJUSTED_INVOICE';
