/**
 * Get the name of the current school year.
 * Based on calculateSchoolYearName of schoolyearservice.ts of toegang-shared.
 * @param {number} yearShift How many schoolyears in the future, by default 0.
 * @returns {string} with format 2023-2024
 */
export function getSchoolYearName(yearShift = 0) {
  const now = new Date();
  const tiltDate = new Date(`${now.getFullYear()}-08-01`);

  now.setHours(0);
  tiltDate.setHours(0);
  tiltDate.setFullYear(now.getFullYear());

  const year = now.getFullYear();

  let from, to;
  if (now.getTime() >= tiltDate.getTime()) {
      from = year;
      to = year + 1;
  } else {
      from = year - 1;
      to = year;
  }

  return `${from + yearShift}-${to + yearShift}`;
}