import * as Actions from '../store/actions';

export const initialState = {
    saving: false,
    loading: false,
    resending: false,
    resendError: false,
    entity: undefined,
    lastCreatedEntities: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.POST_DATA:
        case Actions.PUT_DATA:
            return {...state, saving: true, loading: false};
        case Actions.FETCH_ITEM:
        case Actions.FETCH_DATA:
            return {...state, saving: false, loading: true};
        case Actions.RECEIVE_ITEM:
            return {...state, saving: false, loading: false, entity: action.data};
        case Actions.RECEIVE_DATA:
            const entity = action.data.get(0);
            if (entity) return {...state, saving: false, loading: false, entity: action.data.get(0)};
            else return {...state, saving: false, loading: false, entity: null, loadError: true};
        case Actions.RECEIVE_DATA_ERROR:
            return {...state, saving: false, loading: false, loadError: true, errorStatus: action.error.status};
        case Actions.POST_DATA_SUCCESS:
            return {...state, saving: false, postError: false, postSuccess: true, lastCreatedEntities: action.data};
        case Actions.PUT_DATA_SUCCESS:
            return {...state, saving: false, postError: false, postSuccess: true};
        case Actions.POST_DATA_ERROR:
        case Actions.PUT_DATA_ERROR:
        case Actions.DELETE_ITEM_ERROR:
            return {
                ...state,
                saving: false,
                postError: true,
                postErrorMessage: action.error.message,
                postSuccess: false,
                errorStatus: action.error.status
            };
        case Actions.RESEND:
            return {...state, resending: true, resendError: false};
        case Actions.RESEND_OK:
            return {...state, resending: false, resendError: false};
        case Actions.RESEND_FAILED:
            return {...state, resending: false, resendError: true};
        default:
            return state;
    }
};
