import {Icon, Input} from '@material-ui/core';
import get from 'lodash.get';
import React from 'react';
import {Link} from 'react-router-dom';

import {infoToLink} from '../converter/texttolink';

export const DefaultRenderer = ({cellData}) => <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{cellData}</span>;

export const InfoResolveRenderer = ({rowData, infoResolver}) => {
    const {title, rowValue} = infoResolver && infoResolver(rowData);

    return <span title={title}>{rowValue}</span>;
};

export const BooleanRenderer = ({cellData}) =>
    cellData ? <Icon color="primary">done</Icon> : <Icon style={{opacity: '0.6'}}>clear</Icon>;

export const ActionBooleanRenderer = ({cellData, rowData, onClick}) => (
    <span onClick={() => onClick(rowData)}>
        {cellData ? <Icon color="primary">done</Icon> : <Icon style={{opacity: '0.6'}}>clear</Icon>}
    </span>
);

export const ProfileLinkRenderer = ({rowData}) => {
    return rowData && rowData.account && rowData.account.id ? (
        <Link to={`/accounts/${rowData.account.id}/extended-view`}>
            <i className="material-icons">person_outline</i>
        </Link>
    ) : (
        ''
    );
};

export const LinkRenderer = ({rowData, cellData, url, data, title}) => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            url = url.replace(`<${key}>`, encodeURIComponent(get(rowData, data[key])));
        }
    }
    title = get(rowData, title);

    if (cellData && url) {
        return (
            <Link to={url} Title={title}>
                {cellData}
            </Link>
        );
    } else if (url && title) {
        return (
            <Link to={url} Title={title}>
                {title}
            </Link>
        );
    } else {
        return '';
    }
};

export const InfoToLinkRenderer = ({rowData, authUserReducer}) => {
    return rowData && rowData.info ? infoToLink(rowData.info, authUserReducer) : '';
};

export const DateToLocalDateRenderer = ({rowData, dataKey}) => {
    return rowData && rowData[dataKey] ? new Date(rowData[dataKey]).toLocaleDateString('nl-NL') : '';
};

export const DatetimeToLocalDatetimeRenderer = ({rowData, dataKey}) => {
    return rowData && rowData[dataKey]
        ? new Date(rowData[dataKey]).toLocaleDateString('nl-NL', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
          })
        : '';
};

export const DistributorRenderer = ({rowData}) => {
    let distributor = '';

    if (rowData?.distributor) {
        distributor = rowData?.distributor.name;
    } else if (rowData?.eckAccount) {
        distributor = rowData?.eckAccount.organisation.name;
    }

    return distributor;
};

export const ArrayRenderer = ({cellData}) => <span>{cellData && cellData.join(',')}</span>;
