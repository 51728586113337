import PropTypes from 'prop-types';
import {Component} from 'react';

export default class AbstractFormComponent extends Component {
    /**
     * Callback naar de in de component properties gedefinieerde onChange.
     * Geeft de naam van de te zetten property en de nieuwe waarde mee in de aanroep.
     */
    handleChange = input => {
        if (this.props.onChange) {
            let value = this.convertInput(input);
            this.props.onChange(this.props.name, value);
        }
    };

    /**
     * Callback naar de in de component properties gedefinieerde onBlur.
     * Geeft de naam van de property mee, waarvan het component de focus heeft verloren.
     */
    handleBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.name, true);
        }
    };

    /**
     * Converteer input op iedere gewenste manier, vóórdat onChange wordt aangeroepen.
     */
    convertInput = input => {
        return input;
    };
}

AbstractFormComponent.propTypes = {
    name: PropTypes.string.isRequired
};
