import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import config from '../config';
import * as ActionCreators from './actioncreators';

export function mapStateToProps(state) {
    return Object.assign({...state}, config);
}

export function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function defaultConnect(componentClass) {
    return connect(mapStateToProps, mapDispatchToProps)(componentClass);
}

export default defaultConnect;
