import * as _ from 'lodash';
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default function getValidationSchema() {
    return Yup.object().shape({
        naam: Yup.string()
            .nullable()
            .required(),
        rechten: Yup.mixed().test({test: mayNotBeEmpty})
    });
}

function mayNotBeEmpty(value) {
    if (_.isEmpty(value)) {
        return this.createError({path: 'rechten', message: 'Veld is verplicht'});
    }
    return true;
}
