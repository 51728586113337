import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Build from '@material-ui/icons/Build';
import ComputerIcon from '@material-ui/icons/Computer';
import DomainIcon from '@material-ui/icons/Domain';
import EmojiNatureIcon from '@material-ui/icons/EmojiNature';
import Equalizer from '@material-ui/icons/Equalizer';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TableChart from '@material-ui/icons/TableChart';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import React from 'react';
import {Switch} from 'react-router';
import {Redirect, Route} from 'react-router-dom';

import {PrivateRoute} from './oauth2/privaterouter';
import AccessDenied from './routes/accessdenied';
import Accounts from './routes/accounts';
import AccountEdit from './routes/accounts/edit';
import AccountExtendedView from './routes/accounts/extended-view';
import AccountsMerge from './routes/accounts/merge';
import Audit from './routes/auditlog';
import BeheerOverzicht from './routes/beheer';
import Beheerders from './routes/beheerders';
import BeheerderEdit from './routes/beheerders/edit';
import PasswordForm from './routes/beheerders/edit/password';
import BeheerderExtendedView from './routes/beheerders/extended-view';
import ContractBalances from './routes/contractbalances';
import ContractSpecifications from './routes/contractspecifications';
import EckLicenties from './routes/ecklicenties';
import ExportsView from './routes/exports';
import Home from './routes/home';
import InvoicePage from './routes/invoices';
import OAuthClients from './routes/oauthclients';
import OAuthClientEdit from './routes/oauthclients/edit';
import Orderbalans from './routes/orderbalans';
import Orders from './routes/orders';
import Organisaties from './routes/organisaties';
import OrganisatieEdit from './routes/organisaties/edit';
import OrganisatieExtendedView from './routes/organisaties/extended-view';
import PageNotFound from './routes/pagenotfound';
import PoLicenties from './routes/policenties';
import Producten from './routes/producten';
import ProductEdit from './routes/producten/edit';
import ProductExtendedView from './routes/producten/extended-view';
import Rollen from './routes/rollen';
import RolEdit from './routes/rollen/edit';
import SchoolContracts from './routes/schoolcontracts';
import ServiceWindowEdit from './routes/servicewindows/edit';
import TLinkOverzicht from './routes/tlink';
import TLinkEdit from './routes/tlink/edit';
import TLinkLicentiesPerSchool from './routes/tlinklicentiesperschool';
import TLinkStatusOverview from './routes/tlinkstatusoverview';
import WasWordt from './routes/waswordt';
import ProductImport from "./routes/productimport";
import OrderImport from "./routes/orderimport";

export const pageConfiguration = [
    {title: 'Home', icon: <HomeIcon />, component: Home, path: '/home'},
    {title: 'Audit log', icon: <AccessTimeIcon />, component: Audit, path: '/audit'},
    {
        title: 'Organisaties',
        icon: <DomainIcon />,
        component: Organisaties,
        path: '/organisaties',
        children: [
            {component: OrganisatieEdit, path: '/organisaties/:id/edit'},
            {component: OrganisatieEdit, path: '/organisaties/new'},
            {component: OrganisatieExtendedView, path: '/organisaties/:id/extended-view'},
        ],
    },
    {
        title: 'OAuthClients',
        icon: <ComputerIcon />,
        component: OAuthClients,
        path: '/oauthclients',
        children: [
            {component: OAuthClientEdit, path: '/oauthclients/:id/edit'},
            {component: OAuthClientEdit, path: '/oauthclients/new'},
        ],
    },
    {
        title: 'Producten',
        icon: <LibraryBooksIcon />,
        component: Producten,
        path: '/producten',
        children: [
            {component: ProductEdit, path: '/producten/:id/edit'},
            {component: ProductEdit, path: '/producten/ean/:ean'},
            {component: ProductEdit, path: '/producten/new'},
            {component: ProductExtendedView, path: '/producten/:id/extended-view'},
            {component: ProductExtendedView, path: '/producten/ean/:ean/extended-view'},
            {component: ServiceWindowEdit, path: '/producten/:product/servicewindows/:id/edit'},
            {component: ServiceWindowEdit, path: '/producten/:product/servicewindows/new'},
        ],
    },
    {
        title: 'Gebruikers',
        icon: <PeopleIcon />,
        component: Accounts,
        path: '/accounts',
        children: [
            {component: AccountEdit, path: '/accounts/:id/edit'},
            {component: AccountEdit, path: '/accounts/new'},
            {component: AccountExtendedView, path: '/accounts/:id/extended-view'},
            {component: AccountsMerge, path: '/accounts/merge'},
            {component: AccountsMerge, path: '/accounts/merge/:src/:dst'},
        ],
    },
    {
        title: 'Beheerders',
        icon: <SupervisedUserCircleIcon />,
        component: Beheerders,
        path: '/beheerders',
        children: [
            {component: BeheerderEdit, path: '/beheerders/:id/edit'},
            {component: BeheerderEdit, path: '/beheerders/new'},
            {component: BeheerderExtendedView, path: '/beheerders/:id/extended-view'},
            {component: PasswordForm, path: '/beheerders/:id/edit/password'},
        ],
    },
    {
        title: 'Rollen',
        icon: <VerifiedUserIcon />,
        component: Rollen,
        path: '/rollen',
        children: [
            {
                component: RolEdit,
                path: '/rollen/:id/edit',
            },
            {
                component: RolEdit,
                path: '/rollen/new',
            },
        ],
    },
    {
        title: 'TLink licenties',
        icon: <LinkIcon />,
        component: TLinkOverzicht,
        path: '/tlinks',
        children: [
            {component: TLinkEdit, path: '/tlinks/:id/edit'},
            {component: TLinkEdit, path: '/tlinks/code/:code'},
            {component: TLinkEdit, path: '/tlinks/new'},
        ],
    },
    {title: 'ECK licenties', icon: <LinkOffIcon />, component: EckLicenties, path: '/ecklicenties'},
    {
        title: 'Schoolcontracten',
        icon: <SchoolIcon />,
        component: SchoolContracts,
        path: '/schoolcontracts',
        // excludeFromSideBar: true,
    },
    {
        excludeFromSideBar: true,
        component: ContractSpecifications,
        path: '/contractspecifications',
    },
    {
        excludeFromSideBar: true,
        component: ContractBalances,
        path: '/contractbalances',
    },
    {title: 'PO licenties', icon: <EmojiNatureIcon />, component: PoLicenties, path: '/policenties'},
    {title: 'Orders', icon: <ShowChartIcon />, component: Orders, path: '/orders'},
    {title: 'Orderbalans', icon: <AttachMoney />, component: Orderbalans, path: '/orderbalans'},
    {title: 'Beheer', icon: <Build />, component: BeheerOverzicht, path: '/beheer'},
    {
        title: 'Licenties per school',
        icon: <TableChart />,
        component: TLinkLicentiesPerSchool,
        path: '/tlinklicentiesperschool',
    },
    {title: 'Licenties per maand', icon: <Equalizer />, component: TLinkStatusOverview, path: '/tlinkstatusoverview'},
    {title: 'Facturatie', icon: <LocalOfferIcon />, component: InvoicePage, path: '/invoices'},
    {title: 'Was/wordt', icon: <SyncAltIcon />, component: WasWordt, path: '/waswordt'},
    {excludeFromSideBar: true, title: 'Productimport', component: ProductImport, path: '/productimport'},
    {excludeFromSideBar: true, component: ExportsView, path: '/myexports'},
    {excludeFromSideBar: true, title: 'Orderimport', component: OrderImport, path: '/orderimport'},
];

const ProtectedRoutes = () => {
    return (
        <Switch>
            <Route path="/access-denied" component={AccessDenied} exact />
            <Route
                exact
                path="/"
                render={() => {
                    return <Redirect to="/home" />;
                }}
            />
            {pageConfiguration.map(({path, children, component}, index) => {
                return [
                    children &&
                        children.map((c) => (
                            <PrivateRoute path={c.path} component={c.component} exact key={'childpage' + index} />
                        )),
                    <PrivateRoute path={path} component={component} exact key={'page' + index} />,
                ];
            })}
            <Route component={PageNotFound} />
        </Switch>
    );
};

export default ProtectedRoutes;
