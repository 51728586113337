import {OidcSecure} from '@axa-fr/react-oidc-redux';
import React from 'react';
import {Route} from 'react-router-dom';

export const PrivateRoute = ({component, ...rest}) => {
    const renderFn = Component => props => (
        <OidcSecure>
            <Component {...props} />
        </OidcSecure>
    );
    return <Route {...rest} render={renderFn(component)} />;
};
