import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { getAccountContactgegeven } from '../../services/util';
import { BooleanRenderer } from '../datatable/contentrenderers';
import SimpleTable from '../simpletable';
import { DeleteForever } from '@material-ui/icons';

import Button from '@material-ui/core/Button';

function AccountAccessurlsTable(props) {
    const { entity, version, deleteItem } = props;

    const columns = [...props.columns];
    if (deleteItem) {
        columns.push({
            header: 'Verwijderen',
            renderer: row => (<Button variant="outlined"
                                      size="medium"
                                      color="primary"
                                      type="button"
                                      onClick={() => deleteItem(row)}
            >
                <DeleteForever />
            </Button>)
        });
    }

    return (
      <SimpleTable
        endpoint="accessurls"
        sortBy="createdAt"
        sortDirection="DESC"
        filter={{ account: entity.id }}
        columns={columns}
        version={version}
      />
    );
}

AccountAccessurlsTable.propTypes = {
    entity: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    version: PropTypes.number
};

AccountAccessurlsTable.defaultProps = {
    version: 0,
    columns: [
        {
            header: 'Gemaakt op',
            renderer: row => moment(row.createdAt).format('DD-MM-YY')
        },
        {
            header: 'Geüpdatet op',
            renderer: row => row.used && moment(row.updatedAt).format('DD-MM-YY HH:mm')
        },
        {
            header: 'Contactgegeven',
            renderer: row => getAccountContactgegeven(row)
        },
        {
            header: 'Token',
            renderer: row => row.token
        },
        {
            header: 'Type',
            renderer: row => row.type
        },
        {
            header: 'Bruikbaar',
            renderer: row => <BooleanRenderer cellData={!row.used} />
        },
    ]
};

export default AccountAccessurlsTable;
