import {PhoneNumberUtil} from 'google-libphonenumber';
import * as _ from 'lodash';
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default function getValidationSchema() {
    return Yup.object()
        .shape({
            email: Yup.string().max(255).nullable(),
            telephone: Yup.string().max(100).nullable(),
            givenName: Yup.string().max(255).nullable(),
            blackListDate: Yup.date().nullable(),
        })
        .test({test: _isTelephoneValid});
}

function _isTelephoneValid(value) {
    if (!value.telephone || value.telephone === '') return true;

    var isValid = false;
    var number = value.telephone;

    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        number = phoneUtil.parseAndKeepRawInput(number, 'NL');
        isValid = phoneUtil.isValidNumber(number) ? phoneUtil.getNumberType(number) === 1 : false;
    } catch (e) {
        isValid = false;
    }

    if (isValid) return true;
    else
        return this.createError({
            path: 'telephone',
            message: 'Telefoonnummer is niet geldig',
        });
}
