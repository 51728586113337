import {Dialog} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

export default function InfoDialog(props) {
    const {open, onClose, setOpen, title, closeButtonName, ...other} = props;

    return (
        <Dialog
            fullWidth={true}
            onBackdropClick={() => setOpen(false)}
            onEscapeKeyDown={() => setOpen(false)}
            open={open}
            {...other}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    {closeButtonName ?? 'Sluiten'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

InfoDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};
