import DateFnsUtils from '@date-io/date-fns';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import * as React from 'react';

import {Types} from '../../services/util';

const dateFns = new DateFnsUtils();

class SearchQuerySetter extends React.Component {
    render() {
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.filters, this.props.filters)) {
            const params = {};
            for (let [key, value] of Object.entries(this.props.filters)) {
                if (value instanceof Date && dateFns.isValid(value)) {
                    params[key] = value.toISOString();
                } else if (typeof value === Types.OBJECT) {
                    if (value != null) {
                        params[key] = JSON.stringify(value);
                    }
                } else if (typeof value !== 'undefined' && value !== '' && value !== false) {
                    params[key] = value;
                } else if (typeof value === 'boolean') {
                    params[key] = value;
                }
            }
            const urlQueryParams = new URLSearchParams(params);
            this.props.history.replace({
                pathname: this.props.history.location.pathname,
                search: urlQueryParams.toString()
            });
        }
    }
}

SearchQuerySetter.propTypes = {
    filters: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default SearchQuerySetter;
