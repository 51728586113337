import * as Actions from '../store/actions';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_ADJUSTED_INVOICE:
            return {...state, loading: true};
        case Actions.RECEIVE_ADJUSTED_INVOICE:
            return {...state, entityId: action.entityId, loading: false};
        default:
            return state;
    }
};
