import config from './config';

export const oidcConfiguration = {
    config: {
        authority: config.oauth_idp_base,
        client_id: config.oauth_client,
        redirect_uri: config.base_uri + 'authentication/callback',
        response_type: 'id_token token',
        scope: 'openid beheer',
        loadUserInfo: true,
        // Ondersteunt onze IDP nog niet:
        revokeAccessTokenOnSignout: false,
        // Ondersteunt onze IDP nog niet (maar verplicht veld hier!):
        automaticSilentRenew: false,
        silent_redirect_uri: config.base_uri + 'authentication/silent_callback',
        triggerAuthFlow: true,
        post_logout_redirect_uri: config.base_uri
    }
};
