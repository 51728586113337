import {Fab, Grid, Paper, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {ActionLinks} from '../../components/datatable/actionlink';
import {ArrayRenderer} from '../../components/datatable/contentrenderers';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    formInputBox: {
        maxWidth: 250,
        minWidth: 250,
        margin: theme.spacing(1)
    }
});

class Rollen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    uitgever: Types.NUMBER
                },
                props.history
            )
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    getAllowedActions() {
        let actions = [];
        if (hasRight(this.props.authUserReducer, Recht.ROLLEN_AANPASSEN)) {
            actions.unshift('EDIT');
        }
        return actions;
    }

    render() {
        const {classes, authUserReducer} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history}/>
                <Typography component="h1" variant="h5" gutterBottom noWrap>
                    Rollen en rechten
                </Typography>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    {authUserReducer && authUserReducer.beheerder && !authUserReducer.beheerder.uitgever && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name={'uitgever'}
                                    endpoint={'organisations'}
                                    endpointFilter={{type: 'Uitgever'}}
                                    value={this.state.filters.uitgever}
                                    labelKey={'name'}
                                    secondLabelKey={'esn'}
                                    placeholder={'Uitgever...'}
                                    onChange={(property, value) => {
                                        this.setState({filters: {...this.state.filters, uitgever: value && value.id}});
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        endpoint="roles"
                        version={this.state.version}
                        filter={this.state.filters}
                        columns={[
                            {label: 'Naam', dataKey: 'naam', maxWidth: 'auto'},
                            {label: 'Rechten', dataKey: 'rechten', component: <ArrayRenderer/>},
                            {label: 'Uitgever', dataKey: 'uitgever.name', maxWidth: 'auto'},
                            {
                                label: 'Beheer',
                                dataKey: 'ACTIONS',
                                disableSort: true,
                                component: <ActionLinks endpoint={'/rollen'} actions={this.getAllowedActions()}/>,
                                styling: {justifyContent: 'center'}
                            }
                        ]}
                    />
                </Grid>
                {hasRight(authUserReducer, Recht.ROLLEN_TOEVOEGEN) && (
                    <Fab color="secondary" aria-label="Add" className={classes.fab} component={Link} to="/rollen/new">
                        <AddIcon/>
                    </Fab>
                )}
            </Paper>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.ROLLEN_INZIEN), withStyles(styles))(Rollen);
