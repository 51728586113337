import {Button, CardContent, Fab, IconButton, InputAdornment} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForever from '@material-ui/icons/DeleteForever';
import InfoIcon from '@material-ui/icons/Info';
import SaveIcon from '@material-ui/icons/Save';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import {Form, Formik} from 'formik';
import * as _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../../components/autocomplete';
import ConfirmationDialog from '../../../components/confirmationdialog';
import {hasRight} from '../../../components/permissions/withpermissions';
import {toastPromise} from '../../../components/toast';
import {mapStateToProps} from '../../../store/connector';
import getValidationSchema from './getValidationSchema';

const endpoint = 'administrators';

const styles = (theme) => ({
    rootAccount: {
        padding: 0,
    },
    headerContainer: {
        position: 'relative',
    },
    wachtwoordContainer: {
        top: 0,
        right: 0,
        position: 'absolute',
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    button: {
        marginLeft: theme.spacing(2),
    },
    buttonReset: {
        marginLeft: 'auto',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
});

function Beheerderform(props) {
    const {classes, entity, deleteItem, history, authUserReducer, postSuccess} = props;

    const [canDelete, setCanDelete] = React.useState();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (
            (hasRight(authUserReducer, Recht.IS_SUPERUSER) || hasRight(authUserReducer, Recht.BEHEERDERS_VERWIJDEREN)) !==
            canDelete
        ) {
            setCanDelete(
                hasRight(authUserReducer, Recht.IS_SUPERUSER) || hasRight(authUserReducer, Recht.BEHEERDERS_VERWIJDEREN)
            );
        }
        if (postSuccess) {
            redirectToOverviewScreen();
        }
    });

    const _doDelete = async (entity, deleteItem) => {
        const deletingItem = deleteItem({
            endpoint,
            id: entity.id,
        }).then(() => {
            redirectToOverviewScreen();
        });

        toastPromise(deletingItem, 'Aan het verwijderen..', 'De beheerder is verwijderd.', (err) => {
            if (err.status === 409) {
                let msg =
                    'Dit kan nu niet worden verwijderd omdat er nog andere entiteiten aan zijn gekoppeld. Verwijder eerst:\n';
                for (let entity of err.failedEntities) {
                    msg += `${entity.toString} (${entity.entityType}#${entity.id})\n`;
                }
                return msg;
            } else {
                return 'Deze beheerder kan nu niet worden verwijderd.';
            }
        });
    };
    const redirectToOverviewScreen = () => {
        history.push('/beheerders');
    };
    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };

    const getRoleEndpointFilters = (values) => {
        const filters = {isKiesbaar: true};

        filters.geenBasisRol = true;

        if (authUserReducer.beheerder && authUserReducer.beheerder.uitgever && !hasRight(authUserReducer, Recht.IS_SUPERUSER)) {
            filters.uitgever = authUserReducer.beheerder.uitgever.id;
        } else if (values && values.uitgever && hasRight(authUserReducer, Recht.IS_SUPERUSER)) {
            filters.uitgever = values.uitgever.id;
        } else {
            filters.geenUitgever = true;
        }

        return filters;
    };

    return (
        <CardContent className={classes.rootAccount}>
            <ConfirmationDialog
                title="Weet je het zeker?"
                open={open}
                onConfirm={() => {
                    _doDelete(entity, deleteItem);
                }}
                onClose={() => setOpen(false)}
            >
                {'Wil je deze beheerder echt verwijderen?'}
            </ConfirmationDialog>
            <Grid className={classes.headerContainer}>
                <Typography component="h1" variant="h5" gutterBottom noWrap>
                    {entity ? `Beheerder bewerken` : `Beheerder toevoegen`}
                    &nbsp;
                    {entity && entity.attributes && (
                        <span title={JSON.stringify(entity.attributes, undefined, 4)}>
                            <InfoIcon className={classNames(classes.leftIcon, classes.iconSmall)} color="secondary" />
                        </span>
                    )}
                </Typography>
                <Grid md={12} className={classes.wachtwoordContainer}>
                    {entity &&
                        (entity.id === authUserReducer.beheerder.id || hasRight(authUserReducer, Recht.BEHEERDERS_AANPASSEN)) && (
                            <Button
                                size="medium"
                                color="primary"
                                className={classes.buttonReset}
                                component={Link}
                                to={`/beheerders/${entity.id}/edit/password`}
                            >
                                <AutorenewIcon />
                                Wachtwoord
                            </Button>
                        )}
                </Grid>
            </Grid>
            <Formik
                initialValues={{
                    id: entity ? entity.id : null,
                    email: entity ? entity.email : null,
                    name: entity ? entity.name : '',
                    uitgever: entity ? entity.uitgever : null,
                    password: '',
                    confirmPassword: '',
                    showPassword: false,
                    rol: entity ? entity.rol : null,
                }}
                validationSchema={getValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;
                    await _doSubmit(values, values.id ? updateData : postData, values.id ? `${endpoint}/${values.id}` : endpoint)
                        .then((result) => {
                            actions.setSubmitting(false);
                        })
                        .catch((e) => {
                            actions.setSubmitting(false);
                        });
                }}
                render={({values, errors, isSubmitting, setFieldValue}) => (
                    <Form onKeyDown={_detectEsc}>
                        <Grid container direction={'column'}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Naam</InputLabel>
                                <Input
                                    error={Boolean(errors.name)}
                                    autoFocus={true}
                                    value={values.name}
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                    onChange={(e) => {
                                        setFieldValue('name', e.target.value, false);
                                    }}
                                />
                                {errors.name && <span className={classes.error}>{errors.name}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel required>E-mail</InputLabel>
                                <Input
                                    error={Boolean(errors.email)}
                                    value={values.email}
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                    onChange={(e) => {
                                        setFieldValue('email', e.target.value, false);
                                    }}
                                />
                                {errors.email && <span className={classes.error}>{errors.email}</span>}
                            </FormControl>
                            {!entity && (
                                <React.Fragment>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel required>Wachtwoord</InputLabel>
                                        <Input
                                            type={values.showPassword ? 'text' : 'password'}
                                            error={Boolean(errors.password)}
                                            value={values.password}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                            onChange={(e) => {
                                                setFieldValue('password', e.target.value, false);
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={(e) => {
                                                            setFieldValue('showPassword', !values.showPassword, false);
                                                        }}
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {errors.password && <span className={classes.error}>{errors.password}</span>}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel required>Bevestig wachtwoord</InputLabel>
                                        <Input
                                            type={values.showPassword ? 'text' : 'password'}
                                            error={Boolean(errors.confirmPassword)}
                                            value={values.confirmPassword}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                            onChange={(e) => {
                                                setFieldValue('confirmPassword', e.target.value, false);
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={(e) => {
                                                            setFieldValue('showPassword', !values.showPassword, false);
                                                        }}
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {errors.confirmPassword && (
                                            <span className={classes.error}>{errors.confirmPassword}</span>
                                        )}
                                    </FormControl>
                                </React.Fragment>
                            )}
                            <FormControl className={classes.formControl}>
                                {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                                    <AutoComplete
                                        name="uitgevers"
                                        endpoint="organisations"
                                        value={values.uitgever}
                                        endpointFilter={{
                                            type: 'Uitgever',
                                            id: values.rol && values.rol.uitgever ? values.rol.uitgever.id : null,
                                        }}
                                        labelKey="toString"
                                        valueKey={'id'}
                                        placeholder="Kies een uitgever..."
                                        onChange={(property, value) => {
                                            setFieldValue('uitgever', value, false);
                                        }}
                                    />
                                )}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <AutoComplete
                                    name="role"
                                    endpoint="roles"
                                    endpointFilter={getRoleEndpointFilters(values)}
                                    labelKey="naam"
                                    value={values.rol}
                                    onChange={(name, value) => setFieldValue('rol', value, false)}
                                    onAfterLoad={(roles) => {
                                        if (
                                            values.uitgever &&
                                            values.rol &&
                                            !roles.some((r) => r.id === values.rol.id) &&
                                            !hasRight(authUserReducer, Recht.IS_SUPERUSER)
                                        ) {
                                            setFieldValue('rol', null, false);
                                        }
                                    }}
                                    placeholder="Kies rol..."
                                />
                                {errors.rol && <span className={classes.error}>{errors.rol}</span>}
                            </FormControl>
                            <Grid className={classes.actionButtonContainer} md={12}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    className={classNames(classes.button, classes.saveButton)}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Opslaan
                                </Button>
                                <Button
                                    size="medium"
                                    className={classNames(classes.button, classes.cancelButton)}
                                    onClick={(e) => history.goBack()}
                                >
                                    <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Annuleren
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            />
            {entity && canDelete && (
                <Fab
                    aria-label="Delete"
                    className={classes.fab}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <DeleteForever />
                </Fab>
            )}
        </CardContent>
    );
}

const _doSubmit = (values, syncData, endpoint) => {
    const syncing = syncData({
        endpoint: endpoint,
        entity: values,
    });

    return toastPromise(
        syncing,
        'Beheerder aan het opslaan...',
        'De beheerder is opgeslagen.',
        (e) =>
            `De beheerder kon niet worden opgeslagen${
                e.message || e.error.message ? ': ' + (e.message ?? e.error.message) : ''
            }.`,
        null,
        true
    );
};

export default compose(
    connect(mapStateToProps, () => {
        return {};
    }),
    withRouter,
    withStyles(styles)
)(Beheerderform);
