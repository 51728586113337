import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {Info} from '@material-ui/icons';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import DataTable from '../../components/datatable';
import {InfoResolveRenderer} from '../../components/datatable/contentrenderers';
import GenericField from '../../components/input/genericfield';
import ReadOnly from '../../components/input/readonly';
import {withPermissions} from '../../components/permissions/withpermissions';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    grid: {
        flexWrap: 'wrap',
        '& > div > div': {
            width: '100%',
        },
    },
});

class ContractBalances extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    contractReferenceId: Types.STRING,
                    schoolyearName: Types.STRING,
                    schoolId: Types.STRING,
                    schoolName: Types.STRING,
                    schoolEsn: Types.STRING,
                    schoolBrin: Types.STRING,
                    subjectId: Types.STRING,
                    distributorId: Types.STRING,
                    grade: Types.STRING,
                },
                props.history
            ),
        };
    }

    // we load the data automatically because there are no filter inputs nor ZOEK button
    componentDidMount() {
        const hasAlreadyAFilter = Object.values(this.state.filters).some((value) => value !== undefined);
        if (hasAlreadyAFilter) {
            this.reloadDataTable();
        }
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    render() {
        const {classes, authUserReducer, history} = this.props;
        return (
            <div>
                <Paper className={classes.root}>
                    <Grid container xs={10} spacing={2}>
                        <Grid item>
                            <Typography component="h1" variant="h5" gutterBottom noWrap>
                                Contractbalans
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.grid}>
                        <Grid item sm={12} md={12}>
                            <Typography component="h1" variant="h6" style={{marginTop: '40'}} gutterBottom noWrap>
                                Overzicht
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <GenericField label="Schoollocatie">
                                <ReadOnly>{this.state.filters['schoolName'] || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="DigiDeliveryId">
                                <ReadOnly>{this.state.filters['schoolEsn'] || '-'}</ReadOnly>
                            </GenericField>
                            <GenericField label="BRIN">
                                <ReadOnly>{this.state.filters['schoolBrin'] || '-'}</ReadOnly>
                            </GenericField>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <GenericField label="Schooljaar">
                                <ReadOnly>{this.state.filters['schoolyearName'] || '-'}</ReadOnly>
                            </GenericField>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <DataTable
                            csv
                            endpoint="contractbalances"
                            version={this.state.version}
                            filter={this.state.filters}
                            columns={[
                                {label: 'Contract id', dataKey: 'contractReferenceId', maxWidth: 'auto'},
                                {label: 'Vak EAN', dataKey: 'subjectEan', minWidth: '130px', styling: {minWidth: '130px'}},
                                {label: 'Vak product', dataKey: 'subjectTitle', maxWidth: 'auto', minWidth: '100px'},
                                {label: 'Distributeur', dataKey: 'distributorName', maxWidth: 'auto'},
                                {
                                    label: 'Leerjaren',
                                    dataKey: 'grades',
                                    maxWidth: 'auto',
                                    component: <InfoResolveRenderer infoResolver={_formatGrades} />,
                                },
                                {
                                    label: (
                                        <span>
                                            Specified
                                            <Tooltip
                                                placement={'top'}
                                                title={
                                                    <>
                                                        Tlinklicenties met één van de volgende statussen: <u>PREACTIVE</u>,{' '}
                                                        <u>ACTIVE</u>, <u>EXPIRED</u>, <u>BLOCKED</u>, <u>REVOKED</u>,{' '}
                                                        <u>REVOKED_BY_MERGE</u>
                                                    </>
                                                }
                                            >
                                                <Info fontSize={'small'} />
                                            </Tooltip>
                                        </span>
                                    ),
                                    dataKey: 'specified',
                                },
                                {
                                    label: <span>Active</span>,
                                    dataKey: 'active',
                                },
                                {
                                    label: <span>Expired</span>,
                                    dataKey: 'expired',
                                },
                                {
                                    label: (
                                        <span>
                                            Disabled
                                            <Tooltip
                                                placement={'top'}
                                                title={
                                                    <>
                                                        Tlinklicenties met één van de volgende statussen: <u>BLOCKED</u>,{' '}
                                                        <u>REVOKED</u>, <u>REVOKED_BY_MERGE</u>
                                                    </>
                                                }
                                            >
                                                <Info fontSize={'small'} />
                                            </Tooltip>
                                        </span>
                                    ),
                                    dataKey: 'disabled',
                                },
                                {
                                    label: (
                                        <span>
                                            Service no contract
                                            <Tooltip
                                                placement={'top'}
                                                title={<>Alleen de actieve service no contract tlinklicenties</>}
                                            >
                                                <Info fontSize={'small'} />
                                            </Tooltip>
                                        </span>
                                    ),
                                    dataKey: 'serviceNoContract',
                                },
                                {
                                    label: <span>Preactive used</span>,
                                    dataKey: 'preactiveUsed',
                                },
                                {
                                    label: <span>Preactive unused</span>,
                                    dataKey: 'preactiveUnused',
                                },
                            ]}
                            customCsvHeaders={[
                                {
                                    label: 'Specified',
                                    dataKey: 'specified',
                                },
                                {
                                    label: 'Active',
                                    dataKey: 'active',
                                },
                                {
                                    label: 'Expired',
                                    dataKey: 'expired',
                                },
                                {
                                    label: 'Disabled',
                                    dataKey: 'disabled',
                                },
                                {
                                    label: 'Service no contract',
                                    dataKey: 'serviceNoContract',
                                },
                                {
                                    label: 'Preactive used',
                                    dataKey: 'preactiveUsed',
                                },
                                {
                                    label: 'Preactive unused',
                                    dataKey: 'preactiveUnused',
                                }
                            ]}
                        />
                    </Grid>
                </Paper>
            </div>
        );
    }
}

const _formatGrades = (rowData) => {
    return {
        title: 'Leerjaar',
        rowValue: rowData.grades
            ?.split(',')
            .map((grade) => grade.replace(/JAAR_/g, ''))
            .sort()
            .join(', '),
    };
};

export default compose(
    defaultConnect,
    withPermissions(Recht.SCHOOLJAARVAKCONTRACTEN_INZIEN),
    withStyles(styles)
)(ContractBalances);
