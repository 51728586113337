import {Fab, Grid, Icon, MenuItem, Paper, TextField, Typography, withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {InfoResolveRenderer} from '../../components/datatable/contentrenderers';
import SearchTextField from '../../components/input/searchtextfield';
import {withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';
import {getSchoolYearName} from '../../services/schoolyear';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    formInputBox: {
        maxWidth: 250,
        minWidth: 250,
        margin: theme.spacing(1),
    },
});

const Info = (props) => (
    <a title={props.rowData && props.rowData.createdAt}>
        <Icon>access_time</Icon>
    </a>
);

class ContractSpecifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    contractReferenceId: Types.STRING,
                    schoolyearName: Types.STRING,
                    schoolId: Types.STRING,
                    subjectId: Types.STRING,
                    distributorId: Types.STRING,
                    grade: Types.STRING,
                },
                props.history
            ),
        };
    }

    // we load the data automatically if a filter is present so we have a smooth experience between SVPL screens
    // this is also necessary to fetch the list of schools and subjects so the filters inputs do not appear empty
    componentDidMount() {
        const hasAlreadyAFilter = Object.values(this.state.filters).some((value) => value !== undefined);
        if (hasAlreadyAFilter) {
            this.reloadDataTable();
        }
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    render() {
        const {classes, authUserReducer, history} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Grid container xs={10} spacing={2}>
                    <Grid item>
                        <Typography component="h1" variant="h5" gutterBottom noWrap>
                            Contractspecificaties
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Contract id'}
                                value={this.state.filters['contractReferenceId']}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(v) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            contractReferenceId: v,
                                        },
                                    });
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className={classes.formInputBox}
                            label="Schooljaar"
                            value={this.state.filters.schoolyearName}
                            onChange={(e) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        schoolyearName: e.target.value,
                                    },
                                });
                            }}
                        >
                            <MenuItem value={getSchoolYearName(1)}>{getSchoolYearName(1)}</MenuItem>
                            <MenuItem value={getSchoolYearName(0)}>{getSchoolYearName(0)}</MenuItem>
                            <MenuItem value={getSchoolYearName(-1)}>{getSchoolYearName(-1)}</MenuItem>
                            <MenuItem value="">Alles</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'location'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'School'}}
                                value={this.state.filters['schoolId']}
                                labelKey={'name'}
                                secondLabelKey={'esn'}
                                placeholder={'Schoollocatie...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            ['schoolId']: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'vak'}
                                endpoint={'products'}
                                endpointFilter={{isSubject: true}}
                                value={this.state.filters['subjectId']}
                                labelKey={'toString'}
                                secondLabelKey={'ean'}
                                valueKey={'id'}
                                placeholder={'Vak...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            ['subjectId']: value && value.id,
                                            ['productId']: ""
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'distributor'}
                                endpoint={'organisations'}
                                endpointFilter={{type: 'Distributeur'}}
                                value={this.state.filters['distributorId']}
                                labelKey={'name'}
                                secondLabelKey={'id'}
                                placeholder={'Distributeur...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            distributorId: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                inputProps={{maxLength: '200'}}
                                label={'Reference id'}
                                value={this.state.filters['referenceId']}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(v) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            referenceId: v,
                                        },
                                    });
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'spec product'}
                                endpoint={'products'}
                                endpointFilter={{isSubject: false, subject: this.state.filters.subjectId}}
                                value={this.state.filters['productId']}
                                labelKey={'toString'}
                                secondLabelKey={'ean'}
                                valueKey={'id'}
                                placeholder={'Spec product...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            ['productId']: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className={classes.formInputBox}
                            label="Leerjaar"
                            value={this.state.filters.grade}
                            onChange={(e) => {
                                this.setState({
                                    filters: {
                                        ...this.state.filters,
                                        grade: e.target.value,
                                    },
                                });
                            }}
                        >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="">Alles</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        csv
                        endpoint="contractspecifications"
                        version={this.state.version}
                        filter={this.state.filters}
                        columns={[
                            {
                                label: '',
                                dataKey: 'ACTIONS',
                                disableSort: true,
                                component: <Info />,
                                styling: {justifyContent: 'center'},
                            },
                            {label: 'Contract id', dataKey: 'contractReferenceId', maxWidth: 'auto'},
                            {label: 'Schooljaar', dataKey: 'schoolyearName', maxWidth: 'auto'},
                            {label: 'Schoollocatie', dataKey: 'schoolName', maxWidth: 'auto', minWidth: '150px'},
                            {label: 'Vak product', dataKey: 'subjectTitle', maxWidth: 'auto'},
                            {label: 'DigideliveryId', dataKey: 'schoolEsn', maxWidth: 'auto'},
                            {label: 'Vak EAN', dataKey: 'subjectEan', minWidth: '130px', styling: {minWidth: '130px'}},
                            {label: 'Distributeur', dataKey: 'distributorName', maxWidth: 'auto'},
                            {label: 'Reference id', dataKey: 'referenceId', maxWidth: 'auto'},
                            {label: 'Spec product', dataKey: 'productTitle', maxWidth: 'auto', minWidth: '100px'},
                            {label: 'Spec EAN', dataKey: 'productEan', minWidth: '130px', styling: {minWidth: '130px'}},
                            {
                                label: 'Leerjaren',
                                dataKey: 'productGrades',
                                maxWidth: 'auto',
                                component: <InfoResolveRenderer infoResolver={_formatGrades} />,
                            },
                            {label: 'Aantal', dataKey: 'specifiedAmount', maxWidth: '100px'},
                        ]}
                        customCsvHeaders={[
                            {
                                label: 'Aangemaakt',
                                dataKey: 'createdAt',
                            },
                        ]}
                    />
                </Grid>
            </Paper>
        );
    }
}

const _formatGrades = (rowData) => {
    return {
        title: 'Leerjaar',
        rowValue: rowData.productGrades
            ?.split(',')
            .map((grade) => grade.replace(/JAAR_/g, ''))
            .sort()
            .join(', '),
    };
};

export default compose(
    defaultConnect,
    withPermissions(Recht.SCHOOLJAARVAKCONTRACTEN_INZIEN),
    withStyles(styles)
)(ContractSpecifications);
