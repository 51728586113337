import Immutable from 'immutable';
import {SortDirection} from 'react-virtualized/dist/es/Table';

import * as Actions from '../store/actions';

export const initialState = {
    sortDirection: SortDirection.DESC,
    sortBy: 'updatedAt',
    sortedData: Immutable.List.of(),
    fetching: false,
    totalSize: Number.POSITIVE_INFINITY, // incentive for fetching
    fetchingCsv: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_DATA:
            return {...state, fetching: true};
        case Actions.SORT_DATATABLE: {
            const {sortBy, sortDirection} = action;
            return {...state, sortBy, sortDirection}; // datatable render will trigger fetch
        }
        case Actions.RECEIVE_DATA: {
            const {sortedData: oldData} = state;
            const {range, sortBy, sortDirection, data: actionData} = action;
            const sortedData = range.start < oldData.size ? actionData : oldData.concat(action.data);
            return {fetching: false, sortedData, sortBy, sortDirection, totalSize: range.totalSize};
        }
        case Actions.RECEIVE_DATA_ERROR:
            return {...state, fetching: false};
        case Actions.FETCH_CSV:
            return {...state, fetchingCsv: true};
        case Actions.RECEIVE_CSV:
        case Actions.RECEIVE_CSV_ERROR:
            return {...state, fetchingCsv: false};
        default:
            return state;
    }
};
