import * as React from 'react';

import EditPage from '../../../components/editpage';
import OrganisatieForm from './organisatieform';
import {compose} from "recompose";
import {Paper, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class OrganisatieEdit extends React.Component {
    static defaultProps = {
        endpoint: 'organisations',
        entityDescription: 'Organisatie'
    };

    render() {
        const {entityDescription, endpoint, classes} = this.props;
        const {id} = this.props.match.params;
        return (
            <Paper className={classes.root}><EditPage endpoint={endpoint} entityDescription={entityDescription} formComponent={OrganisatieForm} entityId={id} /></Paper>
        );
    }
}

export default compose(withStyles(styles))(OrganisatieEdit);
