import {
    Button,
    Checkbox,
    Fab,
    FormControl,
    Grid,
    Icon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {ActionLinks} from '../../components/datatable/actionlink';
import {DateToLocalDateRenderer, DistributorRenderer, ProfileLinkRenderer} from '../../components/datatable/contentrenderers';
import DynamicSelect from '../../components/dynamicselect';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {nl} from 'date-fns/locale';

const dateFns = new DateFnsUtils();

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    button: {
        margin: theme.spacing(1),
    },
    checkbox: {
        marginLeft: theme.spacing(1),
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    formInputBox: {
        width: 280,
        margin: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff',
    },
    copyToClipboard: {
        marginLeft: theme.spacing(1),
    },
    formDateBox: {
        width: 210,
        marginLeft: theme.spacing(1),
    },
    searchButtonBox: {
        marginLeft: theme.spacing(1),
    }
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

const bronnen = [
    'ACTIVERINGSCODE',
    'API',
    'BASISPOORT',
    'BEHEER',
    'SERVICE',
    'ELF_SPECIFICATIE',
    'ILF_SPECIFICATIE',
    'VRIJE_LICENTIE',
    'SCHOOLJAAR_CONTRACT',
];

const statussen = [
    'AVAILABLE',
    'ACTIVE',
    'EXPIRED',
    'DEACTIVATED',
    'DESPECIFIED',
    'REVOKED',
    'BLOCKED',
    'REVOKED_BY_MERGE',
    'REVOKED_BY_EXTENSION',
    'RESERVED',
    'PAUSED',
    'PREACTIVE',
];

const Info = (props) => (
    <a title={props.rowData && props.rowData.createdAt}>
        <Icon>access_time</Icon>
    </a>
);

class TLinkOverzicht extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastCreatedEntities: {},
            licenseCodes: [],
            filters: parseURLSearchParams(
                {
                    tlink: Types.STRING,
                    referentie: Types.STRING,
                    product: Types.NUMBER,
                    account: Types.NUMBER,
                    organisation: Types.NUMBER,
                    distributor: Types.NUMBER,
                    bronnen: Types.ARRAY,
                    toDate: Types.DATE,
                    fromDate: Types.DATE,
                },
                props.history
            ),
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    componentWillMount() {
        this._loadLastCreatedEntities();
    }

    render() {
        const {classes, authUserReducer} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Typography component="h1" variant="h5">
                    TLink licenties
                </Typography>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                label="Code"
                                value={this.state.filters.tlink}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(v) => {
                                    this.setState({filters: {...this.state.filters, tlink: v}});
                                }}
                                autoFocus={true}
                                inputRef={(c) => (super.target = c)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                fullWidth={true}
                                label="Referentie"
                                value={this.state.filters.referentie}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(v) => {
                                    this.setState({filters: {...this.state.filters, referentie: v}});
                                }}
                                inputRef={(c) => (super.target = c)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {hasRight(authUserReducer, Recht.PRODUCTEN_INZIEN) && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name="product"
                                    endpoint="products"
                                    value={this.state.filters.product}
                                    label="Product"
                                    labelKey="toString"
                                    secondLabelKey="ean"
                                    placeholder="Product..."
                                    onChange={(property, value) => {
                                        this.setState({filters: {...this.state.filters, product: value && value.id}});
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {hasRight(authUserReducer, Recht.GEBRUIKERS_INZIEN) && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name="account"
                                    endpoint="accounts"
                                    endpointFilter={{alleenGebruikers: true}}
                                    value={this.state.filters.account}
                                    labelKey="toString"
                                    placeholder="Account..."
                                    onChange={(property, value) => {
                                        this.setState({filters: {...this.state.filters, account: value && value.id}});
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name="organisatie"
                                endpoint="organisations"
                                endpointFilter={{type: 'School'}}
                                value={this.state.filters.organisation}
                                labelKey="toString"
                                placeholder="Organisatie..."
                                onChange={(property, value) => {
                                    this.setState({filters: {...this.state.filters, organisation: value && value.id}});
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {!authUserReducer?.beheerder?.uitgever && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name={'uitgever'}
                                    endpoint={'organisations'}
                                    endpointFilter={{type: 'Uitgever'}}
                                    value={this.state.filters['product.organisation']}
                                    labelKey={'name'}
                                    secondLabelKey={'esn'}
                                    placeholder={'Uitgever...'}
                                    onChange={(property, value) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                'product.organisation': value && value.id,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {hasRight(authUserReducer, Recht.ORGANISATIES_UITGEVERS_DISTRIBUTEURS_TOEGANG) && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <DynamicSelect
                                    name="distributeur"
                                    endpoint="organisations"
                                    endpointFilter={{type: 'Distributeur'}}
                                    value={this.state.filters.distributor}
                                    valueKey="id"
                                    label="Distributeur..."
                                    labelKey="toString"
                                    placeholder="Distributeur..."
                                    onChange={(event) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                distributor: event.target.value,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <Select
                                label="Status..."
                                labelId="statusLabel"
                                multiple
                                value={this.state.filters.statussen ?? []}
                                onChange={(event) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            statussen: event.target.value,
                                        },
                                    });
                                }}
                                input={<TextField margin="dense" variant="outlined" label="Status" />}
                                renderValue={(selected) => selected.sort().join(', ')}
                                MenuProps={MenuProps}
                            >
                                {statussen.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        <Checkbox checked={(this.state.filters.statussen ?? []).includes(status)} />
                                        <ListItemText primary={status} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <Select
                                label="Bronnen..."
                                labelId="bronLabel"
                                multiple
                                value={this.state.filters.bronnen}
                                onChange={(event) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            bronnen: event.target.value,
                                        },
                                    });
                                }}
                                input={<TextField margin="dense" variant="outlined" placeholder="Bronnen..." />}
                                renderValue={(selected) => selected.sort().join(', ')}
                                MenuProps={MenuProps}
                            >
                                {bronnen.map((bron) => (
                                    <MenuItem key={bron} value={bron}>
                                        <Checkbox checked={this.state.filters.bronnen.includes(bron)} />
                                        <ListItemText primary={bron} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl className={classes.formDateBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    clearable
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt vanaf"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={(e) => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: null,
                                                },
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                },
                                            });
                                        }
                                    }}
                                    value={this.state.filters.fromDate}
                                    invalidDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl className={classes.formDateBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    clearable
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt tot"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={(e) => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: null,
                                                },
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                },
                                            });
                                        }
                                    }}
                                    value={this.state.filters.toDate}
                                    invalidDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.searchButtonBox}>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    {this.state.lastCreatedEntities && this.state.lastCreatedEntities.entities && (
                        <Grid className={classes.copyToClipboard}>
                            <CopyToClipboard text={this.state.licenseCodes}>
                                <Button color="secondary" onClick={this._extractNewLicensecodes}>
                                    <i className="material-icons">file_copy</i>
                                </Button>
                            </CopyToClipboard>
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    <DataTable
                        csv
                        endpoint="tlinklicenses"
                        version={this.state.version}
                        filter={this.state.filters}
                        columns={this.getColumns()}
                        restrictSearchLength={[
                            {name: 'tlink', minimalLength: 3},
                            {name: 'referentie', minimalLength: 3},
                        ]}
                        customCsvHeaders={[{label: 'Schoolcontract referentie', dataKey: 'schoolContractReferenceId'}]}
                    />
                </Grid>
                {hasRight(authUserReducer, Recht.TLINKLICENTIES_TOEVOEGEN) && (
                    <Fab color="secondary" aria-label="Add" className={classes.fab} component={Link} to="/tlinks/new">
                        <AddIcon />
                    </Fab>
                )}
            </Paper>
        );
    }

    getColumns = () => {
        const {authUserReducer} = this.props;

        const columns = [
            {
                label: '',
                dataKey: 'ACTIONS',
                disableSort: true,
                component: <Info />,
                styling: {justifyContent: 'center'},
            },
            {
                label: 'TLink',
                dataKey: 'licenseCode',
            },
            {label: 'Product', dataKey: 'product.title'},
            {label: 'EAN', dataKey: 'product.ean'},
            {
                label: 'Startdatum',
                dataKey: 'startDate',
                component: <DateToLocalDateRenderer />,
            },
            {
                label: 'Einddatum',
                dataKey: 'endDate',
                component: <DateToLocalDateRenderer />,
            },
            {
                label: 'Activatiedatum',
                dataKey: 'activationDate',
                component: <DateToLocalDateRenderer />,
            },
            {label: 'Referentie', dataKey: 'referenceId'},
            {label: 'Distributeur', dataKey: 'distributor.name', component: <DistributorRenderer />},
            {label: 'Status', dataKey: 'licenseState'},
            {label: 'Claimwijze', dataKey: 'activationMethod'},
            {label: 'Bron', dataKey: 'creationMethod'},
            {
                label: 'Beheer',
                dataKey: 'ACTIONS',
                component: (
                    <ActionLinks
                        endpoint={'tlinks'}
                        actions={hasRight(authUserReducer, Recht.TLINKLICENTIES_AANPASSEN) ? ['EDIT'] : []}
                    />
                ),
                styling: {justifyContent: 'center'},
            },
        ];

        if (hasRight(authUserReducer, Recht.GEBRUIKERS_INZIEN)) {
            columns.push({
                label: 'Account',
                dataKey: 'account',
                component: <ProfileLinkRenderer />,
                styling: {justifyContent: 'center'},
            });
        }

        return columns;
    };

    _loadLastCreatedEntities = () => {
        let lastCreatedEntities = JSON.parse(sessionStorage.getItem('last-created-entities'));
        if (lastCreatedEntities) {
            if (Date.now() <= lastCreatedEntities.timestamp + 10 * 1000) {
                // Na 10 seconden geen reload.
                this.reloadDataTable();
            }
            this.setState({lastCreatedEntities}, () => this._extractNewLicensecodes());
        }
    };

    _extractNewLicensecodes = () => {
        const entities = this.state.lastCreatedEntities.entities;
        if (entities) {
            this.setState({licenseCodes: entities.map((e) => e.licenseCode).join(' ')});
        }
    };
}

export default compose(defaultConnect, withPermissions(Recht.TLINKLICENTIES_INZIEN), withStyles(styles))(TLinkOverzicht);
