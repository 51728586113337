import {CircularProgress, withStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useReducer} from 'react';
import uuid from 'uuid';

import entitiesReducer, {initialState} from '../../reducers/entitiesreducer';
import * as ActionCreators from '../../store/actioncreators';
import autodispatch from '../../store/autodispatch';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(5),
    },
    headerRow: {
        backgroundColor: '#00C2D1',
        color: '#fff',
    },
    colorRow: {
        backgroundColor: 'rgba(224, 224, 224, 0.2)',
    },
});

function SimpleTable(props) {
    const {styleBody, columns, noDataText, rowRenderer, endpoint, sortBy, filter, sortDirection, version, rangeEnd, classes} =
        props;

    const [state, dispatch] = useReducer(entitiesReducer, initialState);
    const {entities, loading} = state;
    const {fetchData} = autodispatch(ActionCreators, dispatch);

    useEffect(() => {
        if (filter?.q?.length < 3) {
            return;
        }
        fetchData({endpoint, sortBy, sortDirection, filter, rangeStart: 0, rangeEnd});
    }, [endpoint, sortBy, sortDirection, JSON.stringify(filter), version, rangeEnd]);

    const headers = columns.map((c, index) => (
        <TableCell className={classes.headerRow} style={c.style} component="th" key={'header-' + index}>
            {c.header}
        </TableCell>
    ));

    if (!loading) {
        const rows = [];
        entities.forEach((entity, index) => rows.push(rowRenderer({...props, id: index}, columns, entity)));
        return (
            <Table stickyHeader>
                <TableHead>
                    <TableRow key="headers">{headers}</TableRow>
                </TableHead>
                <TableBody style={styleBody}>
                    {rows && rows.length > 0 ? (
                        rows
                    ) : (
                        <TableRow>
                            <TableCell key="nodata" colSpan={columns.length || 1}>
                                {noDataText}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    } else {
        return <CircularProgress />;
    }
}

const defaultRowRenderer = (props, columns, rowData) => (
    <TableRow className={props.id % 2 === 0 ? props['classes'].colorRow : ''} key={uuid.v4()}>
        {columns.map((c, index) => {
            return (
                <TableCell style={c.style} key={'col-' + index}>
                    {c.linksTo ? <a href={c.linksTo(rowData)}>{c.renderer(rowData)}</a> : c.renderer(rowData)}
                </TableCell>
            );
        })}
    </TableRow>
);

SimpleTable.defaultProps = {
    endpoint: undefined,
    sortBy: 'id',
    sortDirection: 'ASC',
    filter: {},
    noDataText: 'Geen gegevens',
    rows: [],
    columns: [],
    version: undefined, // een smeuïge hack om te kunnen refreshen
    rowRenderer: defaultRowRenderer,
};

SimpleTable.propTypes = {
    endpoint: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
        })
    ),
};

export default withStyles(styles)(SimpleTable);
