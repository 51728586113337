import FormControl from '@material-ui/core/FormControl';
import {styles as inputStyles} from '@material-ui/core/Input/Input'; // this is highlighted by IDE for some reason
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import * as React from 'react';

const GenericField = withStyles(inputStyles)(({label, InputLabelProps, children, classes, helper, ...props}) => (
    <FormControl {...props}>
        <InputLabel shrink {...InputLabelProps}>
            {label}
        </InputLabel>
        <p>{children}</p>
        {helper}
    </FormControl>
));

export default GenericField;
