import 'react-toastify/dist/ReactToastify.css';

import {
    Box,
    Button,
    CardContent,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Fab,
    MenuItem,
    TextField,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForever from '@material-ui/icons/DeleteForever';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import {Form, Formik} from 'formik';
import * as _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import ConfirmationDialog from '../../../components/confirmationdialog';
import InfoDialog from '../../../components/infodialog';
import {hasRight} from '../../../components/permissions/withpermissions';
import ExternAccountCollapsible from '../../../components/simpletemplates/externaccountcollapsible';
import ExternAccountsList from '../../../components/simpletemplates/externaccountslist';
import {toastError, toastPromise, toastWarn} from '../../../components/toast';
import * as Restclient from '../../../services/restclient';
import {mapStateToProps} from '../../../store/connector';
import getValidationSchema from './getValidationSchema';

const endpoint = 'accounts';
const styles = (theme) => ({
    rootAccount: {
        padding: 0,
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    gridItemWide: {
        margin: theme.spacing(2),
        minWidth: 350,
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    externAccountContainer: {
        width: '100%',
    },
    externAccountBox: {
        marginBottom: theme.spacing(1),
    },
    externalAccountExpansionPanel: {
        marginTop: theme.spacing(2),
    },
    externalAccountExpansionPanelDetails: {
        padding: '8px 24px',
    },
    externalAccountInputBox: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    accountTypeBox: {
        marginRight: theme.spacing(2),
    },
    attributesBox: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    verticalPadding: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    wordBreak: {
        wordBreak: 'break-all',
    },
    fullFlex: {
        flex: 1,
    },
    button: {
        marginLeft: theme.spacing(2),
    },
    fullWidth: {
        width: '100%',
    },
    minWidth200: {
        minWidth: 200,
    },
    externalAccountAddButtonBox: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
    },
    externalAccountDeleteButton: {
        padding: theme.spacing(2),
    },
    rawExternalAccountsBox: {
        marginBottom: theme.spacing(2),
        padding: 5,
        minHeight: 75,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    actionButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
    },
    marginOne: {
        marginTop: '20px',
    },
    marginTwo: {
        marginTop: '35px',
        marginBottom: '10px',
    },
    colorWhite: {
        color: '#fff',
    },
});

function AccountForm(props) {
    const {classes, entity, deleteItem, history, authUserReducer, postSuccess} = props;

    const [canDelete, setCanDelete] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [openExternalAccountDialog, setOpenExternalAccountDialog] = React.useState(false);

    React.useEffect(() => {
        if (hasRight(authUserReducer, Recht.IS_SUPERUSER) !== canDelete) {
            setCanDelete(hasRight(authUserReducer, Recht.IS_SUPERUSER));
        }
        if (postSuccess) {
            if (entity?.id) {
                history.push(`/accounts/${entity.id}/extended-view`);
            } else {
                redirectToOverviewScreen();
            }
        }
    });

    const _getUnlinkedExternalAccounts = React.useCallback(async (values, setFieldValue) => {
        if (values.nameId) {
            const fetchingExternalAccounts = Restclient.fetchData({
                endpoint: `externalaccounts`,
                filter: {
                    nameId: values.nameId,
                    accountType: values.accountType,
                },
            }).then((response) => {
                if (response.data?.length === 1 && response.data[0].attributes) {
                    setOpenExternalAccountDialog(true);
                    setFieldValue('exaccattributes', JSON.stringify(response.data[0].attributes, undefined, 4), false);
                } else {
                    setFieldValue('exaccattributes', '', false);
                    throw new Error();
                }
                toast.dismiss();
            });

            toastPromise(
                fetchingExternalAccounts,
                'Externe account aan het ophalen...',
                undefined,
                (e) =>
                    `We hebben geen ongekoppeld extern account gevonden met name ID "${values.nameId}"${
                        e.message ? ': ' + e.message : ''
                    }.`
            );
        } else {
            toastWarn('Vul een name ID in.');
        }
    });

    const _doDelete = async (entity, deleteItem) => {
        const deleting = deleteItem({endpoint, id: entity.id});
        deleting.then(() => {
            redirectToOverviewScreen();
        });

        toastPromise(deleting, 'Aan het verwijderen...', 'Het account is verwijderd.', (err) => {
            if (err.status === 409) {
                let msg =
                    'Dit kan nu niet worden verwijderd omdat er nog andere entiteiten aan zijn gekoppeld. Verwijder eerst:\n';
                for (let entity of err.failedEntities) {
                    msg += `${entity.toString} (${entity.entityType}#${entity.id})\n`;
                }
                return msg;
            } else {
                return `Account verwijderen mislukt${err.message ? ': ' + err.message : ''}.`;
            }
        });
    };
    const redirectToOverviewScreen = () => {
        history.push('/accounts');
    };
    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };
    return (
        <CardContent className={classes.rootAccount}>
            <ConfirmationDialog
                title="Let op!"
                open={open}
                onConfirm={() => {
                    _doDelete(entity, deleteItem);
                }}
                onClose={() => setOpen(false)}
            >
                {`Als je dit account verwijdert, zullen alle gekoppelde licenties de status 'REVOKED' krijgen met als einddatum vandaag (${new Date().toLocaleDateString()}).`}
            </ConfirmationDialog>
            <InfoDialog
                title={'Let op!'}
                closeButtonName={'Akkoord'}
                open={openExternalAccountDialog}
                onClose={() => setOpenExternalAccountDialog(false)}
            >
                Controleer of handmatig koppelen wel de juiste actie is.
                <br />
                <br />
                Er is een externaccount gevonden zonder gelinkt account. Dit betekent meestal dat deze niet automatisch kon worden
                gekoppeld.
                <br />
                <br />
                Als een gebruiker op meerdere scholen zit of is gewisseld van school dient deze per school een apart account te
                hebben ivm resultaten en licentieadministratie.
            </InfoDialog>
            <Typography component="h1" variant="h5" gutterBottom noWrap>
                {entity ? `Gebruiker bewerken` : `Gebruiker toevoegen`}
                &nbsp;
                {entity && entity.attributes && (
                    <span title={JSON.stringify(entity.attributes, undefined, 4)}>
                        <InfoIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                    </span>
                )}
            </Typography>
            <Formik
                initialValues={{
                    id: entity ? entity.id : null,
                    email: entity ? entity.email : null,
                    blackListDate: entity ? entity.blackListDate : null,
                    givenName: entity?.attributes?.given_name ?? '',
                    first_name: entity && entity.attributes ? entity.attributes.first_name : '',
                    tussenvoegsels: entity && entity.attributes ? entity.attributes.tussenvoegsels : '',
                    family_name: entity && entity.attributes ? entity.attributes.family_name : '',
                    externalAccounts: entity ? entity.externAccounts : null,
                    rawExternalAccounts: [],
                    rawExternalAccountsToDelete: [],
                    accessurlsToDelete: [],
                    accountType: 'KNF',
                    nameId: '',
                    exaccattributes: '',
                }}
                validationSchema={getValidationSchema}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;
                    await _doSubmit(values, values.id ? updateData : postData, values.id ? `${endpoint}/${values.id}` : endpoint)
                        .then(() => {
                            actions.setSubmitting(false);
                        })
                        .catch(() => {
                            actions.setSubmitting(false);
                        });
                }}
                render={({values, errors, isSubmitting, setFieldValue}) => (
                    <Form onKeyDown={_detectEsc}>
                        <Grid container direction="column">
                            <FormControl className={classes.formControl}>
                                <InputLabel required>E-mail</InputLabel>
                                <Input
                                    error={Boolean(errors.email)}
                                    autoFocus={true}
                                    type={'email'}
                                    value={values.email}
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                    onChange={(e) => {
                                        setFieldValue('email', e.target.value.trim(), false);
                                    }}
                                />
                                {errors.email && <span className={classes.error}>{errors.email}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Weergavenaam</InputLabel>
                                <Input
                                    error={Boolean(errors.givenName)}
                                    value={values.givenName}
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                    onChange={(e) => {
                                        setFieldValue('givenName', e.target.value, false);
                                    }}
                                />
                                {errors.givenName && <span className={classes.error}>{errors.givenName}</span>}
                            </FormControl>
                            {authUserReducer &&
                                authUserReducer.beheerder &&
                                (!authUserReducer.beheerder.uitgever ||
                                    authUserReducer.beheerder.uitgever.askForVolledigeNaam) && (
                                    <>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Voornaam</InputLabel>
                                            <Input
                                                error={Boolean(errors.first_name)}
                                                value={values.first_name}
                                                disabled={
                                                    !!(
                                                        entity &&
                                                        entity.externAccounts &&
                                                        entity.externAccounts.find((ea) => ea.attributes.givenName)
                                                    )
                                                }
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue('first_name', e.target.value, false);
                                                }}
                                            />
                                            {errors.first_name && <span className={classes.error}>{errors.first_name}</span>}
                                        </FormControl>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Tussenvoegsels</InputLabel>
                                            <Input
                                                error={Boolean(errors.tussenvoegsels)}
                                                value={values.tussenvoegsels}
                                                disabled={
                                                    !!(
                                                        entity &&
                                                        entity.externAccounts &&
                                                        entity.externAccounts.find((ea) => ea.attributes.sn)
                                                    )
                                                }
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue('tussenvoegsels', e.target.value, false);
                                                }}
                                            />
                                            {errors.tussenvoegsels && (
                                                <span className={classes.error}>{errors.tussenvoegsels}</span>
                                            )}
                                        </FormControl>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Achternaam</InputLabel>
                                            <Input
                                                error={Boolean(errors.family_name)}
                                                value={values.family_name}
                                                disabled={
                                                    !!(
                                                        entity &&
                                                        entity.externAccounts &&
                                                        entity.externAccounts.find((ea) => ea.attributes.sn)
                                                    )
                                                }
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue('family_name', e.target.value, false);
                                                }}
                                            />
                                            {errors.family_name && <span className={classes.error}>{errors.family_name}</span>}
                                        </FormControl>
                                    </>
                                )}

                            {(hasRight(authUserReducer, Recht.EXTERNACCOUNTS_VERWIJDEREN) ||
                                hasRight(authUserReducer, Recht.EXTERNACCOUNTS_TOEVOEGEN)) &&
                                entity && (
                                    <Typography component="h1" variant="h5" className={classes.marginOne}>
                                        Externe accounts
                                    </Typography>
                                )}
                            {hasRight(authUserReducer, Recht.EXTERNACCOUNTS_VERWIJDEREN) && entity && (
                                <Grid className={classes.marginOne}>
                                    <Box>
                                        <ExternAccountsList
                                            editable={true}
                                            account={entity}
                                            onDelete={(externAccount, index) => {
                                                values.rawExternalAccountsToDelete.push(externAccount);
                                                setFieldValue(
                                                    'rawExternalAccountsToDelete',
                                                    values.rawExternalAccountsToDelete,
                                                    false
                                                );
                                                setFieldValue('externalAccounts', values.externalAccounts, false);
                                                entity.externAccounts.splice(index, 1);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            {hasRight(authUserReducer, Recht.EXTERNACCOUNTS_TOEVOEGEN) && entity && (
                                <ExpansionPanel
                                    name={'rawExternalAccountExpansionPanel'}
                                    TransitionProps={{unmountOnExit: true}}
                                    className={classes.externalAccountExpansionPanel}
                                >
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <AddIcon className={classes.leftIcon} />
                                        <Typography>Externe account</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.externalAccountExpansionPanelDetails}>
                                        <Box name={'externalAccountInputBox'} className={classes.externalAccountInputBox}>
                                            <Box display={'flex'} className={classes.externAccountBox}>
                                                <Box className={classes.fullFlex}>
                                                    <FormControl className={classes.fullWidth}>
                                                        <InputLabel>name ID</InputLabel>
                                                        <Input
                                                            name={'rawExternalAccountNameIdInput'}
                                                            value={values.nameId}
                                                            onChange={(e) => {
                                                                setFieldValue('nameId', e.target.value, false);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                {hasRight(authUserReducer, Recht.EXTERNACCOUNTS_KOPPELEN) && (
                                                    <Box className={classes.fullFlex}>
                                                        <Button
                                                            className={classes.fullWidth}
                                                            color="primary"
                                                            size="medium"
                                                            onClick={() => {
                                                                _getUnlinkedExternalAccounts(values, setFieldValue);
                                                            }}
                                                        >
                                                            Zoek naar ongekoppelde externe accounts
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box display={'flex'} className={classes.externAccountBox}>
                                                <FormControl className={classes.fullFlex}>
                                                    <InputLabel>attributes</InputLabel>
                                                    <Input
                                                        multiline
                                                        name={'rawExternalAccountAttributesTextarea'}
                                                        type={'textfield'}
                                                        className={classes.fullWidth}
                                                        value={values.exaccattributes}
                                                        placeholder={'{"key" : "value"}'}
                                                        onChange={(e) => {
                                                            setFieldValue('exaccattributes', e.target.value, false);
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box display={'flex'} className={classes.externAccountBox}>
                                                <Box className={classes.fullFlex}>
                                                    <TextField
                                                        select
                                                        className={classes.fullWidth}
                                                        margin="dense"
                                                        label="Kies accounttype"
                                                        variant="outlined"
                                                        value={values.accountType}
                                                        onChange={(e) => {
                                                            setFieldValue('accountType', e.target.value, false);
                                                        }}
                                                    >
                                                        <MenuItem value="KNF">KNF</MenuItem>
                                                        <MenuItem value="BP">BP</MenuItem>
                                                    </TextField>
                                                </Box>
                                            </Box>
                                            <Box className={classes.externalAccountAddButtonBox}>
                                                <Button
                                                    name={'externalAccountAddButton'}
                                                    variant="contained"
                                                    size="medium"
                                                    color="primary"
                                                    className={classes.colorWhite}
                                                    onClick={() => {
                                                        if (_validateExternAccount(values)) {
                                                            const externalAccount = {
                                                                accountType: values.accountType,
                                                                attributes: JSON.parse(values.exaccattributes),
                                                                nameId: values.nameId,
                                                            };
                                                            values.rawExternalAccounts.push(externalAccount);
                                                            setFieldValue(
                                                                'rawExternalAccounts',
                                                                values.rawExternalAccounts,
                                                                false
                                                            );
                                                            setFieldValue('accountType', 'KNF', false);
                                                            setFieldValue('exaccattributes', '', false);
                                                            setFieldValue('nameId', '', false);
                                                        }
                                                    }}
                                                >
                                                    <AddIcon className={classes.iconSmall} />
                                                    Toevoegen
                                                </Button>
                                            </Box>
                                        </Box>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )}
                            {values.rawExternalAccounts.length > 0 && (
                                <React.Fragment>
                                    <Typography component="h1" variant="h6" className={classes.marginTwo}>
                                        Toe te voegen externe accounts
                                    </Typography>
                                    <Box>
                                        {values.rawExternalAccounts.map((externAccount, index) => {
                                            return (
                                                <ExternAccountCollapsible
                                                    editable={true}
                                                    key={'ext-key2-' + index}
                                                    externAccount={externAccount}
                                                    onDelete={() => {
                                                        values.rawExternalAccounts.splice(index, 1);
                                                        setFieldValue('rawExternalAccounts', values.rawExternalAccounts, false);
                                                    }}
                                                />
                                            );
                                        })}
                                    </Box>
                                </React.Fragment>
                            )}
                            {values.rawExternalAccountsToDelete.length > 0 && (
                                <React.Fragment>
                                    <Typography component="h1" variant="h6" className={classes.marginTwo}>
                                        Te verwijderen externe accounts
                                    </Typography>
                                    <Box>
                                        {values.rawExternalAccountsToDelete.map((externAccount, index) => {
                                            return (
                                                <ExternAccountCollapsible
                                                    key={'ext-key3-' + index}
                                                    externAccount={externAccount}
                                                    onDelete={(externAccount) => {
                                                        values.externalAccounts.push(externAccount);
                                                        setFieldValue('externalAccounts', values.externalAccounts, false);
                                                        values.rawExternalAccountsToDelete.splice(index, 1);
                                                        setFieldValue(
                                                            'rawExternalAccountsToDelete',
                                                            values.rawExternalAccountsToDelete,
                                                            false
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    </Box>
                                </React.Fragment>
                            )}

                            <Grid className={classes.actionButtonContainer}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    className={classNames(classes.button, classes.saveButton)}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Opslaan
                                </Button>
                                <Button
                                    size="medium"
                                    className={classNames(classes.button, classes.cancelButton)}
                                    onClick={() => history.goBack()}
                                >
                                    <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                    Annuleren
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            />
            {entity && canDelete && (
                <Fab
                    aria-label="Delete"
                    className={classes.fab}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <DeleteForever />
                </Fab>
            )}
        </CardContent>
    );
}

function _validateExternAccount(values) {
    try {
        if (values.nameId) {
            JSON.parse(values.exaccattributes);
            if (_.findIndex(values.rawExternalAccounts, (externalAccount) => externalAccount.nameId === values.nameId) === -1) {
                return true;
            } else {
                toastError(`name ID "${values.nameId}" bestaat al.`);
            }
        } else {
            toastWarn('Vul eerst een name ID in.');
        }
    } catch (e) {
        toastError(
            <div>
                <h5>Fout in de JSON:</h5>
                <p style={{fontFamily: 'monospace'}}>${e.message}</p>
            </div>,
            {autoClose: 9000} /* langer laten zien omdat het een JSON error is */
        );
    }
    return false;
}

const _doSubmit = (values, syncData, endpoint) => {
    const syncing = syncData({
        endpoint: endpoint,
        entity: values,
    });

    return toastPromise(
        syncing,
        'Aan het opslaan...',
        'Het account is opgeslagen.',
        (e) => `Er is iets misgegaan bij het opslaan van het account. ${e.message || e.error.message || ''}`,
        null,
        true
    );
};

export default compose(connect(mapStateToProps), withRouter, withStyles(styles))(AccountForm);
