import './style.scss';

import {Box, withStyles} from '@material-ui/core';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import {format} from 'date-fns';
import React from 'react';
import Collapsible from 'react-collapsible';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';
import * as uuid from 'uuid/v4';

import defaultConnect from '../../store/connector';
import {infoToLink} from '../converter/texttolink';
import {hasRight, isUitgeverAccount} from '../permissions/withpermissions';

const styles = theme => ({
    personIconContainer: {
        marginLeft: 'auto'
    },
    personIconBox: {
        display: 'flex',
        maxHeight: 25,
        margin: '0 2px 0 2px'
    },
    accountLink: {
        display: 'flex',
        color: '#fff',
        transform: 'scale(1.0)',
        transition: 'all cubic-bezier(0.14, 0.63, 0, 1.01) .5s',
        '&:hover': {
            transition: 'all cubic-bezier(0.14, 0.63, 0, 1.01) .5s',
            transform: 'scale(1.1)'
        }
    },
    timestamp: {
        width: '160px',
        minWidth: '160px',
        display: 'inline-block',
        textAlign: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        color: '#444',
        borderRadius: '30px',
        marginRight: '8px'
    },

    noticeCode: {
        width: '180px',
        minWidth: '180px',
        display: 'inline-block'
    },

    lastNoticeText: {
        display: '-webkit-box',
        fontStyle: 'italic',
        wordBreak: 'break-all',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        maxHeight: 20,
        overflow: 'hidden'
    },

    triggerBar: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        marginLeft: '-6px',
        alignItems: 'center'
    },

    noticeRow: {
        margin: 0,
        '&:nth-child(even)': {
            backgroundColor: '#eeeeee'
        },
        wordBreak: 'break-word'
    },

    errorRow: {
        backgroundColor: '#f68c96',
        wordBreak: 'break-word'
    },

    successRow: {
        backgroundColor: '#a7d1d1',
        wordBreak: 'break-word'
    },

    externalRow: {
        backgroundColor: '#239489',
        color: '#fff'
    }
});

function MeldcodeGroep(props) {
    const {meldcodegroep, classes, authUserReducer, index, autoOpen} = props;
    return (
        <Box display={'flex'}>
            <Collapsible
                trigger={
                    <Box display={'flex'}>
                        <div className={classes.triggerBar}>
                            <div className={classes.timestamp}>
                                &nbsp;{format(new Date(meldcodegroep.timestamp), 'yyyy-MM-dd HH:mm:ss')}&nbsp;
                            </div>
                            <div className={classes.noticeCode}>{meldcodegroep.meldcode}</div>
                            <div className={classes.lastNoticeText}>
                                {meldcodegroep.entries[0].info
                                    ? meldcodegroep.entries[0].info
                                    : 'Error: ' + meldcodegroep.entries[0].error}
                            </div>
                        </div>
                        {meldcodegroep.accounts.length > 0 && hasRight(authUserReducer, Recht.GEBRUIKERS_INZIEN) && (
                            <Box display={'flex'} className={classes.personIconContainer} onClick={e => e.stopPropagation()}>
                                {meldcodegroep.accounts.map(account => {
                                    return (
                                        <Box key={uuid()} className={classes.personIconBox} title={'Ga naar dit account'}>
                                            <Link
                                                className={classes.accountLink}
                                                to={`/accounts/${account}/extended-view`}
                                                target={'_blank'}
                                            >
                                                <PersonOutlineTwoToneIcon />
                                            </Link>
                                        </Box>
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                }
                triggerClassName={_categoryClass(meldcodegroep.entries, classes)}
                triggerOpenedClassName={_categoryClass(meldcodegroep.entries, classes)}
                transitionTime={200}
                classParentString={'Collapsible'}
                open={autoOpen === true}
            >
                <ul>
                    {meldcodegroep.entries.map((logRow, index) => {
                        const text = logRow.info ? logRow.info : 'Error: ' + logRow.error;
                        return (
                            <li className={_rowClass(logRow, classes, authUserReducer)} key={'entry-' + index}>
                                {format(new Date(logRow.timestamp), 'yyyy-MM-dd HH:mm:ss')} : {infoToLink(text, authUserReducer)}
                            </li>
                        );
                    })}
                </ul>
            </Collapsible>
        </Box>
    );
}

function _categoryClass(group, classes) {
    //Strings op basis waarvan we de groep 'goed' rekenen
    let successStrings = ['Callback succesvol: '];

    //Strings op basis waarvan we de groep 'fout' rekenen
    let errorStrings = ['Callback fout: ', 'Er is geen licentie gevonden'];

    let returnStyle = 'pending';

    const r = group.some(item => successStrings.some(s => item.info && item.info.includes(s)))
        ? (returnStyle = 'success')
        : group.some(item => errorStrings.some(e => item.error && item.error.includes(e)))
        ? (returnStyle = 'error')
        : null;

    return returnStyle;
}

function _rowClass(logRow, classes, authUserReducer) {
    const info = logRow.info ? logRow.info : 'Error: ' + logRow.error;
    //Strings op basis waarvan we de regel 'goed' rekenen
    let successStrings = ['Product exists for '];

    //Strings op basis waarvan we de regel 'fout' rekenen
    let errorStrings = ['Error: '];

    let returnStyle = classes.noticeRow;

    const r = errorStrings.some(s => info && info.includes(s))
        ? (returnStyle = classes.errorRow)
        : successStrings.some(s => info && info.includes(s))
        ? (returnStyle = classes.successRow)
        : null;

    if (logRow.external && !isUitgeverAccount(authUserReducer)) {
        returnStyle = classes.externalRow;
    }

    return returnStyle;
}

export default compose(defaultConnect, withStyles(styles))(MeldcodeGroep);
