import Typography from '@material-ui/core/Typography';
import React from 'react';

class AccessDenied extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                    Toegang geweigerd
                </Typography>
                <p>Dit account heeft niet de juiste rechten om deze pagina te kunnen zien.</p>
            </React.Fragment>
        );
    }
}

export default AccessDenied;
