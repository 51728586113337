import * as React from 'react';

import EditPage from '../../../components/editpage';
// Local
import RolForm from './rolform';
import {compose} from "recompose";
import {Paper, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class RolEdit extends React.Component {
    static defaultProps = {
        endpoint: 'roles',
        entityDescription: 'Rol'
    };

    render() {
        const {entityDescription, endpoint, classes} = this.props;
        const {id} = this.props.match.params;
        return (<Paper className={classes.root}><EditPage endpoint={endpoint} entityDescription={entityDescription} formComponent={RolForm} entityId={id} /></Paper>);
    }
}

export default compose(withStyles(styles))(RolEdit);