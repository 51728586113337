import {withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useReducer} from 'react';

import entityReducer, {initialState} from '../../reducers/entityreducer';
import * as ActionCreators from '../../store/actioncreators';
import autodispatch from '../../store/autodispatch';

const styles = theme => ({
    serverError: {
        color: '#f00',
        fontSize: '1.5em'
    },
    root: {
        padding: theme.spacing(4)
    }
});

function EditPage(props) {
    const {endpoint, entityId, filter, entityDescription, formComponent, classes, ...other} = props;
    if (!endpoint) {
        throw new Error(`Page should have a property 'endpoint'`);
    }
    if (!entityDescription) {
        throw new Error(`Page should have a property 'entityDescription'`);
    }
    if (!formComponent) {
        throw new Error(`Page should have a property 'formComponent'`);
    }

    const [state, dispatch] = useReducer(entityReducer, initialState);
    const {loading, errorStatus, entity, loadError, postErrorMessage} = state;
    const actions = autodispatch(ActionCreators, dispatch);

    useEffect(() => {
        if (entityId) {
            actions.fetchItem({endpoint: endpoint, id: entityId});
        } else if (filter) {
            actions.fetchData({endpoint, filter});
        }
    }, [endpoint, entityId, filter]);

    if (loading) {
        return (
            <React.Fragment>
                <CircularProgress />
            </React.Fragment>
        );
    } else if (!loading && !entity) {
        if (loadError && errorStatus) {
            let errorTitle, errorMessage;
            if (errorStatus === 403) {
                errorTitle = 'Toegang geweigerd';
                errorMessage = `Niet bevoegd om opgevraagde ${entityDescription.toLowerCase()} in te zien.`;
            } else if (errorStatus === 404) {
                errorTitle = `Niet gevonden`;
                errorMessage = `Opgevraagde ${entityDescription.toLowerCase()} bestaat niet.`;
            } else {
                errorTitle = 'Onbekende fout';
                errorMessage = `Ophalen van opgevraagde ${entityDescription.toLowerCase()} is mislukt`;
            }
            return (
                <React.Fragment>
                    <Typography component="h1" variant="h6" color="inherit" noWrap>
                        {errorTitle}
                    </Typography>
                    <Typography component="span" variant="subtitle1" color="inherit" noWrap>
                        {errorMessage}
                    </Typography>
                </React.Fragment>
            );
        }
    }
    const editForm = React.createElement(formComponent, {...other, ...state, ...actions});
    return editForm;
}

export default withStyles(styles)(EditPage);
