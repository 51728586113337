import {reducer as oidc} from '@axa-fr/react-oidc-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';

import authUserReducer from '../reducers/authUserReducer';
import beheerReducer from '../reducers/beheerReducer';
import oauthClientReducer from '../reducers/oauthclientreducer';
import orderbalansReducer from '../reducers/orderbalans';
import supportReducer from '../reducers/supportReducer';
import invoiceReducer from '../reducers/invoiceReducer';

const rootReducer = combineReducers({
    supportReducer,
    oauthClientReducer,
    beheerReducer,
    authUserReducer,
    oidc,
    orderbalansReducer,
    invoiceReducer
});

const composeEnhancers =
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;
const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

export default createStore(rootReducer, enhancer);
