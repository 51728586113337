import {Box, Button, CircularProgress, FormControl, Grid, InputAdornment, Typography, withStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {SearchRounded} from '@material-ui/icons';
import debounce from 'lodash/debounce';
import React from 'react';
import {Waypoint} from 'react-waypoint';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import SearchTextField from '../../components/input/searchtextfield';
import MeldcodeOverzicht from '../../components/meldcodeoverzicht';
import {hasRight} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import defaultConnect from '../../store/connector';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        margin: theme.spacing(4),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(7),
        right: theme.spacing(7),
        position: 'fixed',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    formInputBox: {
        width: '400px',
        margin: theme.spacing(1),
    },
});

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            meldcode: '',
            selectedValue: 'all',
            initialMeerClick: false,
            showResultsMeldcode: false,
            showResultsAuditLog: false,
            filter: {
                amount: 20,
                meldcode: '',
            },
            versionAuditLog: 0,
            versionMeldcode: 0,
        };
    }

    reloadMeldcodeDataTable() {
        this.setState({versionMeldcode: Number(Date.now()), showResultsMeldcode: true});
    }

    _meerButton = () => {
        this.setState({initialMeerClick: true});
        this.setState({filter: {...this.state.filter, amount: this.state.filter.amount + 10}});
    };

    _meer = () => {
        if (this.state.initialMeerClick === true) {
            this.setState({filter: {...this.state.filter, amount: this.state.filter.amount + 10}});
        }
    };

    _updateFilter = debounce(
        () => {
            this.setState({filter: {...this.state.filter, meldcode: this.state.meldcode, amount: 20}});
            this.setState({initialMeerClick: false});
        },
        500,
        {leading: false}
    );

    render() {
        const {filter, meldcode} = this.state;
        const {classes, authUserReducer} = this.props;

        return (
            <Box>
                <Paper className={classes.root}>
                    <Grid container justify={'space-between'}>
                        <Grid>
                            <Typography component="h1" variant="h5">
                                Home
                            </Typography>
                        </Grid>
                    </Grid>
                    {hasRight(authUserReducer, Recht.LOGENTRIES_INZIEN) && (
                        <Grid container justify={'flex-start'} className={classes.inputContainer}>
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <SearchTextField
                                        autoFocus={true}
                                        fullWidth
                                        variant="outlined"
                                        label="Zoeken op meldcode"
                                        margin="dense"
                                        value={meldcode}
                                        onEnter={() => this.reloadMeldcodeDataTable()}
                                        onChange={(e) => {
                                            this.setState({meldcode: e}, this._updateFilter.bind(this));
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchRounded style={{opacity: 0.2}} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <SearchButton onClick={this.reloadMeldcodeDataTable.bind(this)} />
                            </Grid>
                            {this.state.showResultsMeldcode && (
                                <Grid container>
                                    <MeldcodeOverzicht
                                        endpoint="logentries"
                                        filter={filter}
                                        version={Number(new Date())}
                                        footer={({fetching, results}) =>
                                            fetching ? (
                                                <CircularProgress />
                                            ) : (
                                                <Waypoint onEnter={this._meer.bind(this)}>
                                                    {results.size > 0 && results.size % 10 === 0 && (
                                                        <Button
                                                            color="primary"
                                                            variant="outlined"
                                                            onClick={this._meerButton.bind(this)}
                                                        >
                                                            <i className="material-icons">expand_more</i>
                                                        </Button>
                                                    )}
                                                </Waypoint>
                                            )
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Paper>
            </Box>
        );
    }
}

export default compose(defaultConnect, withStyles(styles))(Home);
