import {
    Box,
    Button,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    TextField,
    Typography,
    withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import {toast} from 'react-toastify';
import {compose} from 'recompose';
import {Recht} from 'shared-types';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import {toastInfo} from '../../components/toast';
import * as RestClient from '../../services/restclient';
import defaultConnect from '../../store/connector';
import {getSchoolYearName} from '../../services/schoolyear';
import DynamicSelect from '../../components/dynamicselect';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    inputContainer: {
        margin: theme.spacing(2),
    },
    resultContainer: {
        marginTop: theme.spacing(2),
    },
    resultPaper: {
        padding: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    formInputBox: {
        maxWidth: 250,
        minWidth: 250,
    },
});

function OrderImport(props) {
    const {classes, authUserReducer, history} = props;

    const [saving, setSaving] = React.useState(true);
    const [result, setResult] = React.useState({failed: [], added: []});
    const [delimiter, setDelimiter] = React.useState(';');

    const [eckAccountId, setEckAccountId] = React.useState(null);
    const [schoolYearName, setSchoolYearName] = React.useState(null);
    const [referenceId, setReferenceId] = React.useState(null);
    const [contractId, setContractId] = React.useState(null);

    const hasEnoughUserProvidedInput = React.useMemo(() => {
        if (hasRight(authUserReducer, Recht.IS_SUPERUSER)) {
            return eckAccountId && schoolYearName && referenceId;
        }
        return schoolYearName && referenceId;
    }, [eckAccountId, schoolYearName, referenceId]);

    const csvFileRef = React.createRef();
    return (
        <Paper className={classes.root}>
            <Typography gutterBottom component="h1" variant="h5">
                Voorraadmutatie inschieten
            </Typography>

            {authUserReducer && authUserReducer.beheerder && (
                <Grid container xs={10} spacing={2}>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <DynamicSelect
                                name="eckAccount"
                                endpoint={'eckaccounts/beheer'}
                                value={eckAccountId}
                                valueKey={'id'}
                                label="Distributeur"
                                labelKey={'name'}
                                onChange={(event) => {
                                    setEckAccountId(event.target.value);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            className={classes.formInputBox}
                            label="Schooljaar"
                            value={schoolYearName}
                            onChange={(e) => {
                                setSchoolYearName(e.target.value);
                            }}
                        >
                            <MenuItem value={getSchoolYearName(1)}>{getSchoolYearName(1)}</MenuItem>
                            <MenuItem value={getSchoolYearName(0)}>{getSchoolYearName(0)}</MenuItem>
                            <MenuItem value={getSchoolYearName(-1)}>{getSchoolYearName(-1)}</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                label="ReferenceId *"
                                autoFocus={true}
                                value={referenceId}
                                onChange={(value) => {
                                    setReferenceId(value)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <SearchTextField
                                label="Contract ID"
                                value={contractId}
                                onChange={(value) => {
                                    setContractId(value)
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )}

            <Paper className={classes.root}>
                <i>Let op! Wij accepteren alleen .csv bestanden</i>
                <Box display={'flex'} justifyContent={'space-between'} className={classes.inputContainer}
                     style={{gap: '25px'}}>
                    <Box>
                        <InputLabel required shrink>
                            Delimiter
                        </InputLabel>
                        <Input value={delimiter} onChange={(e) => setDelimiter(e.target.value)}/>
                    </Box>
                    <Box>
                        <InputLabel required shrink>
                            Selecteer het bestand
                        </InputLabel>
                        <input
                            ref={csvFileRef}
                            type="file"
                            label="file"
                            accept=".csv"
                            onChange={(r) => {
                                if (r.currentTarget.value) {
                                    setSaving(false);
                                } else {
                                    setSaving(true);
                                }
                            }}
                        />
                    </Box>
                    <Box>
                        <Button
                            color={'primary'}
                            style={{color: '#fff'}}
                            variant="contained"
                            disabled={saving || !hasEnoughUserProvidedInput}
                            onClick={() => {
                                let ordersString = [];
                                setSaving(true);
                                let reader = new FileReader();
                                reader.readAsText(csvFileRef.current.files[0]);
                                reader.addEventListener(
                                    'load',
                                    async function () {
                                        ordersString = reader.result;
                                    },
                                    false
                                );
                                reader.addEventListener('loadend', async () => {
                                    const toastId = toastInfo('Aan het verwerken...', {isLoading: true});
                                    if (ordersString.length > 0) {
                                        let error = '';
                                        let lineBreak;
                                        if (ordersString.includes('\r\n')) {
                                            lineBreak = '\r\n';
                                        } else if (ordersString.includes('\r')) {
                                            lineBreak = '\r';
                                        } else if (ordersString.includes('\n')) {
                                            lineBreak = '\n';
                                        }
                                        if (!lineBreak) {
                                            error = 'Onbekende linebreak.';
                                        } else {
                                            const rows = ordersString.split(lineBreak).filter((r) => r);
                                            if (rows.length === 0) {
                                                error = 'CSV bevat geen rijen.';
                                            } else {
                                                if (rows.some((r) => !r.includes(delimiter))) {
                                                    error = 'Bij één of meerdere rijen ontbreekt de delimiter.';
                                                } else {
                                                    RestClient.postData({
                                                        endpoint: `orders/actions/import?schoolYearName=${schoolYearName}&referenceId=${referenceId}${
                                                            eckAccountId ? `&eckAccountId=${eckAccountId}` : ''
                                                        }${
                                                            contractId ? `&contractId=${contractId}` : ''
                                                        }`,
                                                        entity: {delimiter: delimiter, orders: ordersString},
                                                    })
                                                        .then((res) => {
                                                            setResult(res);
                                                            toast.update(toastId, {
                                                                render: '',
                                                                autoClose: 1000,
                                                                type: 'success',
                                                                isLoading: false,
                                                            });
                                                        })
                                                        .catch((e) => {
                                                            toast.update(toastId, {
                                                                render: `Error: ${e.message}`,
                                                                autoClose: 6000,
                                                                type: 'error',
                                                                isLoading: false,
                                                            });
                                                            setSaving(false);
                                                        });
                                                    return;
                                                }
                                            }
                                        }
                                        setSaving(false);
                                        toast.update(toastId, {
                                            render: error,
                                            autoClose: 1000,
                                            type: 'error',
                                            isLoading: false,
                                        });
                                    } else {
                                        toast.update(toastId, {
                                            render: 'De producten lijst is leeg',
                                            autoClose: 1000,
                                            type: 'error',
                                            isLoading: false,
                                        });
                                        setSaving(false);
                                    }
                                });
                            }}
                        >
                            Importeren
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Box display={'grid'}>
                <Box className={classes.resultContainer}>
                    <Paper className={classes.resultPaper}>
                        {result.addedAmount && (
                            <Typography gutterBottom component="h2" variant="h6">
                                De {result.addedAmount} voorraadmutaties zijn geïmporteerd!
                            </Typography>
                        )}
                        {(result?.errors ?? []).map(error =>
                            <Typography gutterBottom component="h4" variant="h6">
                                {error}
                            </Typography>
                        )}
                    </Paper>
                </Box>
            </Box>
        </Paper>
    );
}

export default compose(defaultConnect, withPermissions(Recht.ORDERS_TOEVOEGEN), withStyles(styles))(OrderImport);
