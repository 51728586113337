import * as Actions from '../store/actions';

const initialState = {
    fetching: false,
    distributeurs: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.ORDERBALANS_DISTRIBUTEURS_FETCH:
            return {...state, fetching: true};
        case Actions.ORDERBALANS_DISTRIBUTEURS_FETCH_OK:
            return {...state, fetching: false, distributeurs: action.data};
        case Actions.ORDERBALANS_DISTRIBUTEURS_FETCH_ERROR:
            return {...state, fetching: false};
        default:
            return state;
    }
};
