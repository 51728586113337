/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default function getValidationSchema() {
    return Yup.object().shape({
        oldPassword: Yup.string().nullable(),
        password: Yup.string()
            .required('Nieuw wachtwoord is verplicht')
            .min(9, 'Nieuw wachtwoord moet minimaal 9 karakters lang zijn')
            .notOneOf([Yup.ref('oldPassword'), null], 'Nieuw wachtwoord mag niet overeenkomen met de huidige'),
        confirmPassword: Yup.string().when('password', {
            is: val => val && val.length > 0,
            then: Yup.string().oneOf([Yup.ref('password'), null], 'Nieuw wachtwoord komt niet overeen')
        })
    });
}
