import { Button, CircularProgress, Divider, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SwapHoriz } from "@material-ui/icons";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { Recht } from "shared-types";

import AutoComplete from "../../../components/autocomplete";
import MeldcodeOverzicht from "../../../components/meldcodeoverzicht";
import { withPermissions } from "../../../components/permissions/withpermissions";
import AccountAccessurlsTable from "../../../components/simpletemplates/accountaccessurlstable";
import AccountTLinksTable from "../../../components/simpletemplates/accounttlinkstable";
import BasicAccountInfo from "../../../components/simpletemplates/basicaccountinfo";
import ExternAccountsList from "../../../components/simpletemplates/externaccountslist";
import { fetchItem, mergeAccounts } from "../../../services/restclient";
import { getAccountContactgegeven } from "../../../services/util";
import defaultConnect from "../../../store/connector";
import { toastPromise, toastWarn } from "../../../components/toast";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  infoContainer: {
    padding: theme.spacing(3),
    width: "100%"
  },
  sourceAccountText: {
    color: "#d39e00",
    fontWeight: "bold"
  },
  destinationAccountText: {
    color: "#339900",
    fontWeight: "bold"
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
  mergeIcon: {
    transform: "rotate(180deg)"
  },
  colorWhite: {
    color: "#fff"
  },
  actionButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2)
  },
  saveButton: {
    color: "#fff"
  },
  cancelButton: {
    backgroundColor: "#E0E0E0",
    color: "#fff"
  }
});

const accessUrlColumns = [
  {
    header: "Gemaakt op",
    renderer: (row) => moment(row.createdAt).format("DD-MM-YY")
  },
  {
    header: "Contactgegeven",
    renderer: (row) => getAccountContactgegeven(row)
  }
];

function AccountsMerge(props) {
  const { classes, history, authUserReducer } = props;
  const { src, dst } = props.match.params;

  const [sourceAccount, setSourceAccount] = React.useState(null);
  const [destinationAccount, setDestinationAccount] = React.useState(null);

  const [merging, setMerging] = React.useState(false);

  React.useEffect(() => {
    async function fetchSourceAccount() {
      if (src) {
        const account = await fetchItem({ endpoint: "accounts", id: src });
        setSourceAccount(account);
      }
    }

    fetchSourceAccount();
  }, [src]);
  React.useEffect(() => {
    async function fetchDestinationAccount() {
      if (dst) {
        const account = await fetchItem({ endpoint: "accounts", id: dst });
        setDestinationAccount(account);
      }
    }

    fetchDestinationAccount();
  }, [dst]);

  function _doMerge(sourceAccount, destinationAccount) {
    let hasErrors = false;
    if (!sourceAccount) {
      toastWarn("Bronaccount is verplicht.");
      hasErrors = true;
    }
    if (!destinationAccount) {
      toastWarn("Bestemmingsaccount is verplicht.");
      hasErrors = true;
    }
    if (sourceAccount && destinationAccount && sourceAccount.id === destinationAccount.id) {
      toastWarn("Bronaccount en bestemmingsaccount mogen niet hetzelfde zijn.");
      hasErrors = true;
    }
    if (!hasErrors && !merging) {
      setMerging(true);
      const mergingAccounts = mergeAccounts(sourceAccount.id, destinationAccount.id)
        .then((r) => {
          history.push(`/accounts/${destinationAccount.id}/extended-view`);
        })
        .catch()
        .then(() => {
          setMerging(false);
        });

      toastPromise(mergingAccounts,
        "Accounts aan het samenvoegen...",
        "Accounts succesvol samengevoegd.",
        (e) => {
          if (
            e.message &&
            (e.message === "source account has multiple publishers linked" ||
              e.message === "source account has other publisher than admin linked")
          ) {
            return 'Kan niet samenvoegen, licenties van andere uitgever gekoppeld';
          } else if (e.message) {
            return `Er ging iets mis: ${e.message}`;
          } else {
            return "Er ging iets mis, accounts zijn niet gemerged";
          }
        }
      );
    }
  }

  return (
    <Paper className={classes.root}>
      <Typography component="h1" variant="h5" gutterBottom>
        Gebruikers samenvoegen
        <IconButton
          title={"Bronaccount en bestemmingsaccount omwisselen"}
          color="primary"
          className={classes.button}
          disabled={merging}
          onClick={() => {
            const sourceAccountTemp = sourceAccount;
            setSourceAccount(destinationAccount);
            setDestinationAccount(sourceAccountTemp);
          }}
        >
          <SwapHoriz />
        </IconButton>
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Grid container direction={"column"}>
            <span className={classes.sourceAccountText}>Bronaccount, dit account wordt verwijderd</span>
            <AutoComplete
              name="sourceAccount"
              endpoint="accounts"
              endpointFilter={{ alleenGebruikers: true }}
              value={sourceAccount}
              labelKey="toString"
              placeholder="Kies een account..."
              filter
              onChange={(property, value) => {
                setSourceAccount(value);
              }}
              disabled={merging}
            />
            <br />
            <Divider />
            <br />
            {sourceAccount && (
              <AccountInfo authUserReducer={authUserReducer} account={sourceAccount} classes={classes} />
            )}
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container direction={"column"}>
                        <span className={classes.destinationAccountText}>
                            Bestemmingsaccount, dit account neemt de gegevens van het bronaccount over
                        </span>
            <AutoComplete
              name="destinationAccount"
              endpoint="accounts"
              endpointFilter={{ alleenGebruikers: true }}
              value={destinationAccount}
              labelKey="toString"
              placeholder="Kies een account..."
              onChange={(property, value) => {
                setDestinationAccount(value);
              }}
              disabled={merging}
            />
            <br />
            <Divider />
            <br />
            {destinationAccount && (
              <AccountInfo authUserReducer={authUserReducer} account={destinationAccount} classes={classes} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.actionButtonContainer}>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          className={classNames(classes.button, classes.saveButton)}
          type="submit"
          onClick={(e) => {
            _doMerge(sourceAccount, destinationAccount);
          }}
          disabled={merging}
        >
          <AccountTreeIcon className={classNames(classes.icon, classes.mergeIcon)} />
          Samenvoegen
        </Button>
        <Button
          size="medium"
          className={classNames(classes.button, classes.cancelButton)}
          component={Link}
          to="/accounts"
          disabled={merging}
        >
          <CancelIcon className={classNames(classes.icon)} />
          Annuleren
        </Button>
      </Grid>
    </Paper>
  );
}

function AccountInfo(props) {
  const { account, classes } = props;
  return (
    <Paper className={classes.infoContainer}>
      <Typography component="h1" variant="h5" gutterBottom noWrap>
        Overzicht <Link to={`/accounts/${account.id}/extended-view`}>{account.toString}</Link>
      </Typography>
      <BasicAccountInfo account={account} />
      <Typography component="h1" variant="h5" gutterBottom noWrap>
        Externe accounts
      </Typography>
      <ExternAccountsList account={account} />
      <br />
      <AccountTLinksTable entity={account} />
      <br />
      <Typography component="h1" variant="h5" gutterBottom noWrap>
        Verstuurde bevestingslinks
      </Typography>
      <AccountAccessurlsTable entity={account} columns={accessUrlColumns} />
      <br />
      <Typography component="h1" variant="h5" gutterBottom noWrap>
        Activiteit
      </Typography>
      <MeldcodeOverzicht
        version={Number(new Date())}
        endpoint={`accounts/${account.id}/activity`}
        footer={({ fetching, results }) => {
          return fetching ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            results.size === 0 && <div>Geen activiteit gevonden.</div>
          );
        }}
      />
    </Paper>
  );
}

export default compose(defaultConnect, withPermissions(Recht.GEBRUIKERS_SAMENVOEGEN), withStyles(styles))(AccountsMerge);
