import * as Actions from '../store/actions';

export const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.POST_DATA:
        case Actions.PUT_DATA:
            return {...state, success: false, loading: true};
        case Actions.RECEIVE_DATA:
            return action.data.size ? {...state, item: action.data.get(0)} : state;
        case Actions.POST_DATA_SUCCESS: {
            const [item] = action.data.entities;
            return {item, success: true, loading: false};
        }
        case Actions.PUT_DATA_SUCCESS: {
            return {...state, success: true, loading: false};
        }
        case Actions.POST_DATA_ERROR:
        case Actions.PUT_DATA_ERROR:
            return {...state, error: action.error, loading: false};
        default:
            return state;
    }
};
