import * as Actions from '../store/actions';

const initialState = {
    beheerder: null,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_ADMIN_INFO:
            return {
                ...state,
                loading: true
            };
        case Actions.RECEIVE_ADMIN_INFO:
            return {
                ...state,
                loading: false,
                beheerder: action.beheerder
            };
        default:
            return state;
    }
};
