import * as React from 'react';

import EditPage from '../../../components/editpage';
import BeheerderForm from './beheerderform';
import {Paper, withStyles} from '@material-ui/core';
import {compose} from 'recompose';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class BeheerderEdit extends React.Component {
    static defaultProps = {
        endpoint: 'administrators',
        entityDescription: 'Administrator'
    };

    render() {
        const {entityDescription, endpoint, classes} = this.props;
        const {id} = this.props.match.params;
        return (<Paper className={classes.root}><EditPage endpoint={endpoint} entityDescription={entityDescription} formComponent={BeheerderForm} entityId={id} /></Paper>);
    }
}

export default compose(withStyles(styles))(BeheerderEdit);