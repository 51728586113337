import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";
import { compose } from "recompose";
import { Recht } from "shared-types";

import ConfirmationDialog from "../../components/confirmationdialog";
import { withPermissions } from "../../components/permissions/withpermissions";
import * as RestClient from "../../services/restclient";
import { convertArrayToCSV } from "../../services/util";
import defaultConnect from "../../store/connector";
import { toastError, toastInfo, toastWarn } from "../../components/toast";
import { toast } from "react-toastify";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  inner: {
    "padding-left": theme.spacing(20),
    "padding-right": theme.spacing(20)
  },
  inputBox: {
    width: "30%"
  },
  inputRow: {
    margin: theme.spacing(1)
  },
  error: {
    marginLeft: "2px",
    color: "#f00"
  },
  formInputBox: {
    width: 250,
    margin: theme.spacing(1)
  },
  headerDiv: {
    width: "48px",
    height: "48px"
  },
  headerInfo: {
    width: "30%",
    textAlign: "center"
  },
  saveButton: {
    color: "#fff"
  }
});

function WasWordt(props) {
  const { classes } = props;

  const [attribuut, setAttribuut] = React.useState("nlEduPersonProfileId");
  const [wasWordtItems, setWasWordtItems] = React.useState([{ was: "", wordt: "" }]);
  const [clearConfirmOpen, setClearConfirmOpen] = React.useState(false);
  const [csvImportConfirmOpen, setCsvImportConfirmOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [csvFileSelected, setCsvFileSelected] = React.useState(false);
  const [delimiter, setDelimiter] = React.useState(",");


  const csvFileRef = React.createRef();
  return (
    <Paper className={classes.root}>
      <Typography gutterBottom component="h1" variant="h5">
        Was-Wordt importeren
      </Typography>

      <ConfirmationDialog
        title="CSV importeren"
        open={csvImportConfirmOpen}
        onConfirm={() => {
          let reader = new FileReader();
          reader.readAsText(csvFileRef.current.files[0]);
          reader.addEventListener(
            "loadend",
            function() {
              const result = reader.result;
              let error = "";
              let lineBreak;
              if (result.includes("\r\n")) {
                lineBreak = "\r\n";
              } else if (result.includes("\r")) {
                lineBreak = "\r";
              } else if (result.includes("\n")) {
                lineBreak = "\n";
              }
              if (!lineBreak) {
                error = "Onbekende linebreak.";
              } else {
                const rows = result.split(lineBreak);
                if (rows.length === 0) {
                  error = "CSV bevat geen rijen.";
                } else {
                  if (rows.some((r) => !r.includes(delimiter))) {
                    error = "Bij één of meerdere rijen ontbreekt de delimiter.";
                  } else {
                    setSaved(false);
                    setWasWordtItems(
                      rows
                        .map((r) => r.split(delimiter))
                        .map((s) => {
                          return {
                            was: s[0].replaceAll(`"`, ""),
                            wordt: s[1].replaceAll(`"`, "")
                          };
                        })
                    );
                    return;
                  }
                }
              }
              toastError(`CSV bevat fouten. ${error}`);

            },
            false
          );
        }}
        onClose={() => {
          setCsvFileSelected(false);
          setCsvImportConfirmOpen(false);
        }}
        disableActionButton={!csvFileSelected || !delimiter}
      >
        Selecteer een CSV bestand om te importeren. <b>Let op: hiermee wordt alle huidige invoer vervangen met de
        CSV.</b>
        <br />
        <br />
        <InputLabel required shrink>
          Delimiter
        </InputLabel>
        <Input value={delimiter} onChange={(e) => setDelimiter(e.target.value)} />
        <br />
        <br />
        De CSV mag <b>geen header</b> bevatten en moet voldoen aan onderstaand format:
        <br />
        <br />
        <i>was{delimiter}wordt</i>
        <br />
        <br />
        <input
          ref={csvFileRef}
          type="file"
          label="file"
          accept=".csv"
          onChange={(e) => {
            if (e.target.value) {
              setCsvFileSelected(true);
            }
          }}
        />
      </ConfirmationDialog>

      <ConfirmationDialog
        title="Weet je het zeker?"
        open={clearConfirmOpen}
        onConfirm={() => {
          setWasWordtItems([{ was: "", wordt: "" }]);
          setSaved(false);
        }}
        onClose={() => {
          setClearConfirmOpen(false);
        }}
      >
        Weet je zeker dat je alle invoer wilt verwijderen?
      </ConfirmationDialog>

      <Box display={"flex"} flexDirection={"column"} height={"75vh"}>
        <TextField
          select
          disabled={saving || saved}
          fullWidth={true}
          className={classes.formInputBox}
          margin="dense"
          label="Attribuut"
          value={attribuut}
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e) => {
            setAttribuut(e.target.value);
          }}
        >
          <MenuItem value="nlEduPersonProfileId">nlEduPersonProfileId</MenuItem>
          <MenuItem value="nlEduPersonRealId">nlEduPersonRealId</MenuItem>
        </TextField>
        <Box display="flex" justifyContent="space-evenly">
          <h3 class={classes.headerInfo}>Was</h3>
          <h3 class={classes.headerInfo}>Wordt</h3>
          <div class={classes.headerDiv} />
        </Box>
        <Box display="flex" flexDirection="column" flex={1} overflow="auto">
          {wasWordtItems.map((v, i) => (
            <Box display="flex" justifyContent="space-evenly" className={classes.inputRow} key={i}>
              <Box display="flex" flexDirection="column" className={classes.inputBox}>
                <Input
                  value={v.was}
                  disabled={saving || saved}
                  error={Boolean(v.wasError)}
                  onChange={(e) => {
                    const temp = wasWordtItems.slice();
                    temp[i].was = e.target.value;
                    delete temp[i].wasError;
                    setWasWordtItems(temp);
                  }}
                />
                {v.wasError && <span className={classes.error}>{v.wasError}</span>}
              </Box>
              <Box display="flex" flexDirection="column" className={classes.inputBox}>
                <Input
                  value={v.wordt}
                  disabled={saving || saved}
                  error={Boolean(v.wordtError)}
                  onChange={(e) => {
                    const temp = wasWordtItems.slice();
                    temp[i].wordt = e.target.value;
                    delete temp[i].wordtError;
                    setWasWordtItems(temp);
                  }}
                />
                {v.wordtError && <span className={classes.error}>{v.wordtError}</span>}
              </Box>
              <IconButton
                disabled={saving || saved}
                onClick={() => {
                  const temp = wasWordtItems.slice();
                  temp.splice(i, 1);
                  setWasWordtItems(temp);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
        <Box display="flex">
          <ButtonGroup fullWidth color="primary">
            <Button
              disabled={saving}
              onClick={() => {
                setCsvImportConfirmOpen(true);
              }}
            >
              CSV importeren
            </Button>
            <Button
              disabled={saving || saved}
              onClick={() => {
                setWasWordtItems([...wasWordtItems, { was: "", wordt: "" }]);
              }}
            >
              Nieuwe regel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.saveButton}
              disabled={saving || saved}
              onClick={async (e) => {
                setSaving(true);
                const temp = wasWordtItems.slice().map((wwi) => {
                  const tempWwi = { ...wwi };
                  if (!tempWwi.was) {
                    tempWwi.wasError = "Veld mag niet leeg zijn";
                  } else if (tempWwi.was.trim().includes(" ")) {
                    tempWwi.wasError = "Veld bevat spatie(s)";
                  }

                  if (!tempWwi.wordt) {
                    tempWwi.wordtError = "Veld mag niet leeg zijn";
                  } else if (tempWwi.wordt.trim().includes(" ")) {
                    tempWwi.wordtError = "Veld bevat spatie(s)";
                  }
                  return tempWwi;
                });

                if (temp.length === 0) {
                  toastWarn("Formulier bevat geen invoer.");
                } else if (temp.some((t) => t.wasError || t.wordtError)) {
                  setWasWordtItems(temp);

                  const faulty = temp.find(t => t.wasError) || temp.find(t => t.wordtError);
                  const index = temp.indexOf(faulty);
                  toastError(`Fout in in de ${faulty.wasError ? "was" : "wordt"} op rij ${index + 1}. ${faulty.wasError || faulty.wordtError}.`);
                } else {
                  const toastId = toastInfo('Aan het toepassen...', {isLoading: true});
                  RestClient.postData({
                    endpoint: `waswordtitems/actions/import`,
                    entity: {
                      attribuut,
                      wasWordtItems: wasWordtItems.map((wwi) => {
                        return {
                          was: wwi.was.trim(),
                          wordt: wwi.wordt.trim()
                        };
                      })
                    }
                  }).then((res) => {
                    const csvContent =
                      "data:text/csv;charset=utf-8," +
                      convertArrayToCSV(res, ["was", "wordt", "info"], delimiter || ",");

                    const encodedUri = encodeURI(csvContent);

                    toast.update(toastId, {
                      render: <div>
                        <p>De Was-Wordt lijst is toegepast.</p>
                        <Link
                          className={"toastButton"}
                          component={Button}
                          href={encodedUri}
                          download={`Was-Wordt-Import-${Date.now()}.csv`}
                        >
                          Log downloaden
                        </Link>
                      </div>,
                      autoClose: false,
                      type: 'success',
                      isLoading: false
                    });
                    setSaved(true);
                  }).catch((err) => {
                    toast.update(toastId, {
                      render: 'De Was-Wordt-lijst kon niet worden toegepast' + (e.message ? ': ' + e.message : '') + '.',
                      autoClose: 6000,
                      type: 'error',
                      isLoading: false
                    });
                  });
                }
                setSaving(false);
              }}
            >
              Toepassen
            </Button>
            <Button
              disabled={saving}
              onClick={() => {
                setClearConfirmOpen(true);
              }}
            >
              Reset formulier
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Paper>
  );
}

export default compose(defaultConnect, withPermissions(Recht.WASWORDT_IMPORTEREN), withStyles(styles))(WasWordt);
