import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import {CircularProgress, Fab} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, {useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import GenericField from '../../../components/input/genericfield';
import ReadOnly from '../../../components/input/readonly';
import {hasRight} from '../../../components/permissions/withpermissions';
import entityReducer, {initialState} from '../../../reducers/entityreducer';
import * as ActionCreators from '../../../store/actioncreators';
import autodispatch from '../../../store/autodispatch';
import defaultConnect from '../../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    grid: {
        margin: theme.spacing(2),
        flexWrap: 'wrap'
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff'
    }
});

function BeheerderExtendedView(props) {
    const {classes, match, authUserReducer} = props;

    const [state, dispatch] = useReducer(entityReducer, initialState);
    const {entity, loading} = state;
    const {fetchItem} = autodispatch(ActionCreators, dispatch);

    useEffect(() => {
        const {id} = match.params;
        if (id) {
            fetchItem({endpoint: 'administrators', id: id});
        }
    }, [match]);

    return entity && !loading ? (
        <div>
            <Paper className={classes.root}>
                <Typography component="h1" variant="h5" gutterBottom noWrap>
                    Overzicht {entity ? entity.toString : ''}
                </Typography>
                <Grid container className={classes.grid}>
                    <Grid container direction="row">
                        <Grid item xs={4}>
                            <GenericField label="Email">
                                <ReadOnly>{entity.email || '-'}</ReadOnly>
                            </GenericField>
                        </Grid>
                        <Grid item xs={4}>
                            <GenericField label="Naam">
                                <ReadOnly>{entity.name || '-'}</ReadOnly>
                            </GenericField>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {(hasRight(authUserReducer, Recht.BEHEERDERS_AANPASSEN) || authUserReducer.beheerder.id === entity.id) && (
                <Fab
                    color="secondary"
                    aria-label="Edit"
                    className={classes.fab}
                    component={Link}
                    to={`/beheerders/${entity.id}/edit`}
                >
                    <EditIcon />
                </Fab>
            )}
        </div>
    ) : (
        <React.Fragment>
            <CircularProgress />
        </React.Fragment>
    );
}

export default compose(defaultConnect, withStyles(styles))(BeheerderExtendedView);
