import {Button, CardContent, IconButton, InputAdornment, Paper} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import SaveIcon from '@material-ui/icons/Save';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import {Form, Formik} from 'formik';
import * as _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import ConfirmationDialog from '../../../../components/confirmationdialog';
import {hasRight} from '../../../../components/permissions/withpermissions';
import {mapStateToProps} from '../../../../store/connector';
import getValidationSchema from './getValidationSchema';

const endpoint = 'administrators';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    formControl: {
        marginBottom: theme.spacing(1),
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    button: {
        marginLeft: theme.spacing(2),
    },
    buttonReset: {
        marginLeft: 'auto',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
});

function PasswordForm(props) {
    const {classes, entity, history, authUserReducer} = props;

    const [canDelete, setCanDelete] = React.useState();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (hasRight(authUserReducer, Recht.IS_SUPERUSER) !== canDelete) {
            setCanDelete(hasRight(authUserReducer, Recht.IS_SUPERUSER));
        }
        if (props.postSuccess) {
            redirectToOverviewScreen();
        }
    });

    const redirectToOverviewScreen = () => {
        if (hasRight(authUserReducer, Recht.BEHEERDERS_AANPASSEN)) {
            history.push(`/beheerders/${entity && entity.id}/edit`);
        } else {
            history.push(`/`);
        }
    };
    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };

    return (
        <Paper className={classes.root}>
            <Typography component="h1" variant="h5" gutterBottom noWrap>
                {entity && `Wachtwoord wijzigen van ${entity.email || entity.name}`}
                &nbsp;
                {entity && entity.attributes && (
                    <span title={JSON.stringify(entity.attributes, undefined, 4)}>
                        <InfoIcon className={classNames(classes.leftIcon, classes.iconSmall)} color="secondary" />
                    </span>
                )}
            </Typography>
            <Formik
                initialValues={{
                    id: entity && entity.id,
                    oldPassword: '',
                    password: '',
                    confirmPassword: '',
                    showPassword: false,
                }}
                validationSchema={getValidationSchema}
                onSubmit={async (values, actions) => {
                    setOpen(true);
                    actions.setSubmitting(false);
                }}
                render={({values, errors, isSubmitting, setFieldValue}) => (
                    <React.Fragment>
                        <ConfirmationDialog
                            title="Let op!"
                            open={open}
                            onConfirm={async () => {
                                const {postData} = props;
                                await _doSubmit(values, postData, `${endpoint}/${entity.id}/actions/changepassword`);
                            }}
                            onClose={() => setOpen(false)}
                        >
                            {`Je wijzigt hiermee definitief het wachtwoord van ${
                                entity && (entity.email || entity.name)
                            }. Klik 'OK' om verder te gaan.`}
                        </ConfirmationDialog>
                        <Form onKeyDown={_detectEsc}>
                            <Grid container direction="column">
                                <React.Fragment>
                                    {!hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                                        <FormControl className={classes.formControl}>
                                            <InputLabel required>Huidig wachtwoord</InputLabel>
                                            <Input
                                                autoFocus={true}
                                                type={values.showPassword ? 'text' : 'password'}
                                                error={Boolean(errors.oldPassword)}
                                                value={values.oldPassword}
                                                inputProps={{
                                                    maxLength: 255,
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue('oldPassword', e.target.value, false);
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            tabIndex="-1"
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => {
                                                                setFieldValue('showPassword', !values.showPassword, false);
                                                            }}
                                                        >
                                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {errors.oldPassword && <span className={classes.error}>{errors.oldPassword}</span>}
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.formControl}>
                                        <InputLabel required>Nieuw wachtwoord</InputLabel>
                                        <Input
                                            type={values.showPassword ? 'text' : 'password'}
                                            error={Boolean(errors.password)}
                                            value={values.password}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                            onChange={(e) => {
                                                setFieldValue('password', e.target.value, false);
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={(e) => {
                                                            setFieldValue('showPassword', !values.showPassword, false);
                                                        }}
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {errors.password && <span className={classes.error}>{errors.password}</span>}
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel required>Bevestig nieuw wachtwoord</InputLabel>
                                        <Input
                                            type={values.showPassword ? 'text' : 'password'}
                                            error={Boolean(errors.confirmPassword)}
                                            value={values.confirmPassword}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                            onChange={(e) => {
                                                setFieldValue('confirmPassword', e.target.value, false);
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"
                                                        aria-label="toggle password visibility"
                                                        onClick={(e) => {
                                                            setFieldValue('showPassword', !values.showPassword, false);
                                                        }}
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {errors.confirmPassword && (
                                            <span className={classes.error}>{errors.confirmPassword}</span>
                                        )}
                                    </FormControl>
                                </React.Fragment>
                                <Grid className={classes.actionButtonContainer}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="secondary"
                                        className={classNames(classes.button, classes.saveButton)}
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                        Opslaan
                                    </Button>
                                    <Button
                                        size="medium"
                                        className={classNames(classes.button, classes.cancelButton)}
                                        onClick={(e) => history.goBack()}
                                    >
                                        <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                        Annuleren
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </React.Fragment>
                )}
            />
        </Paper>
    );
}

const _doSubmit = (values, syncData, endpoint) => {
    // toast moet omgetoverd worden in een error bij een fulfilled promise zonder success
    const toastId = toast.loading('Wachtwoord aan het opslaan...');
    return syncData({
        endpoint: endpoint,
        entity: values,
    })
        .then((r) => {
            if (r.type === 'POST_DATA_SUCCESS') {
                toast.update(toastId, {
                    render: 'Het wachtwoord is gewijzigd.',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                });
            } else {
                toast.update(toastId, {
                    render: 'Wachtwoord opslaan mislukt, het wachtwoord is niet gewijzigd.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                });
            }
        })
        .catch(() => {
            toast.update(toastId, {
                render: 'Wachtwoord opslaan mislukt, het wachtwoord is niet gewijzigd.',
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            });
        });
};

export default compose(
    connect(mapStateToProps, () => {
        return {};
    }),
    withRouter,
    withStyles(styles)
)(PasswordForm);
