import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

export default function ConfirmationDialog(props) {
    const {onClose, onConfirm, open, title, disableActionButton, actionButtonName, ...other} = props;

    function handleCancel() {
        onClose(false);
    }

    function handleAction() {
        onClose(true);
        onConfirm();
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open} {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
            <DialogActions>
                <Button
                    style={{color: '#fff'}}
                    disabled={disableActionButton}
                    variant="contained"
                    onClick={handleAction}
                    color="secondary"
                >
                    {actionButtonName}
                </Button>
                <Button onClick={handleCancel} style={{backgroundColor: '#E0E0E0', color: '#fff'}}>
                    Annuleren
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialog.defaultProps = {
    actionButtonName: 'OK',
    disableActionButton: false
};

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    disableActionButton: PropTypes.bool,
    actionButtonName: PropTypes.string
};
