import {Box, Button, Grid, Input, InputLabel, Paper, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import {toast} from 'react-toastify';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import {withPermissions} from '../../components/permissions/withpermissions';
import {toastInfo} from '../../components/toast';
import * as RestClient from '../../services/restclient';
import defaultConnect from '../../store/connector';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    inputContainer: {
        margin: theme.spacing(2),
    },
    resultContainer: {
        marginTop: theme.spacing(2),
    },
    resultPaper: {
        padding: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    formInputBox: {
        maxWidth: 250,
        minWidth: 250,
        margin: theme.spacing(1),
    },
});

function ProductImport(props) {
    const {classes, authUserReducer} = props;

    const [saving, setSaving] = React.useState(true);
    const [organisation, setOrganisation] = React.useState(null);
    const [result, setResult] = React.useState({failed: [], added: [], updated: []});
    const [delimiter, setDelimiter] = React.useState(';');

    const csvFileRef = React.createRef();
    return (
        <Paper className={classes.root}>
            <Typography gutterBottom component="h1" variant="h5">
                Producten importeren
            </Typography>

            {authUserReducer && authUserReducer.beheerder && !authUserReducer.beheerder.uitgever && (
                <Grid item>
                    <FormControl className={classes.formInputBox}>
                        <AutoComplete
                            name={'organisation'}
                            endpoint={'organisations'}
                            endpointFilter={{type: 'Uitgever'}}
                            value={organisation}
                            labelKey={'name'}
                            secondLabelKey={'esn'}
                            placeholder={'Uitgever...'}
                            onChange={(property, value) => {
                                setOrganisation(value?.id);
                            }}
                        />
                    </FormControl>
                </Grid>
            )}

            <Paper className={classes.root}>
                <i>Let op! Wij accepteren alleen .csv bestanden</i>
                <Box display={'flex'} justifyContent={'space-between'} className={classes.inputContainer} style={{gap: '25px'}}>
                    <Box>
                        <InputLabel required shrink>
                            Delimiter
                        </InputLabel>
                        <Input value={delimiter} onChange={(e) => setDelimiter(e.target.value)} />
                    </Box>
                    <Box>
                        <InputLabel required shrink>
                            Selecteer het bestand
                        </InputLabel>
                        <input
                            ref={csvFileRef}
                            type="file"
                            label="file"
                            accept=".csv"
                            onChange={(r) => {
                                if (r.currentTarget.value) {
                                    setSaving(false);
                                } else {
                                    setSaving(true);
                                }
                            }}
                        />
                    </Box>
                    <Box>
                        <Button
                            color={'primary'}
                            style={{color: '#fff'}}
                            variant="contained"
                            disabled={saving}
                            onClick={() => {
                                let productenString = [];
                                setSaving(true);
                                let reader = new FileReader();
                                reader.readAsText(csvFileRef.current.files[0]);
                                reader.addEventListener(
                                    'load',
                                    async function () {
                                        productenString = reader.result;
                                    },
                                    false
                                );
                                reader.addEventListener('loadend', async () => {
                                    const toastId = toastInfo('Aan het verwerken...', {isLoading: true});
                                    if (productenString.length > 0) {
                                        let error = '';
                                        let lineBreak;
                                        if (productenString.includes('\r\n')) {
                                            lineBreak = '\r\n';
                                        } else if (productenString.includes('\r')) {
                                            lineBreak = '\r';
                                        } else if (productenString.includes('\n')) {
                                            lineBreak = '\n';
                                        }
                                        if (!lineBreak) {
                                            error = 'Onbekende linebreak.';
                                        } else {
                                            const rows = productenString.split(lineBreak).filter((r) => r);
                                            if (rows.length === 0) {
                                                error = 'CSV bevat geen rijen.';
                                            } else {
                                                if (rows.some((r) => !r.includes(delimiter))) {
                                                    error = 'Bij één of meerdere rijen ontbreekt de delimiter.';
                                                } else {
                                                    RestClient.postData({
                                                        endpoint: `products/actions/import${
                                                            organisation ? `?publisher=${organisation}` : ''
                                                        }`,
                                                        entity: {delimiter: delimiter, producten: productenString},
                                                    })
                                                        .then((res) => {
                                                            setResult(res);
                                                            toast.update(toastId, {
                                                                render: '',
                                                                autoClose: 1000,
                                                                type: 'success',
                                                                isLoading: false,
                                                            });
                                                            setSaving(false);
                                                        })
                                                        .catch((e) => {
                                                            toast.update(toastId, {
                                                                render: `Error: ${e.message}`,
                                                                autoClose: 6000,
                                                                type: 'error',
                                                                isLoading: false,
                                                            });
                                                            setSaving(false);
                                                        });
                                                    return;
                                                }
                                            }
                                        }
                                        setSaving(false);
                                        toast.update(toastId, {
                                            render: error,
                                            autoClose: 1000,
                                            type: 'error',
                                            isLoading: false,
                                        });
                                    } else {
                                        toast.update(toastId, {
                                            render: 'De producten lijst is leeg',
                                            autoClose: 1000,
                                            type: 'error',
                                            isLoading: false,
                                        });
                                        setSaving(false);
                                    }
                                });
                            }}
                        >
                            Importeren
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Box display={'grid'} style={{gridTemplateColumns: '8fr 1fr', rowGap: '20px'}}>
                <Box className={classes.resultContainer}>
                    <Paper className={classes.resultPaper}>
                        <Typography gutterBottom component="h2" variant="h6">
                            Gefaald: {`${result.failed.length}`}
                        </Typography>
                        <Box display={'grid'} style={{gridTemplateColumns: '1fr', rowGap: '20px'}}>
                            {result.failed.map((f) => {
                                return (
                                    <Box style={{margin: '0 20px 5px 0', padding: '10px', border: '1px solid #e3e3e3'}}>
                                        <h3 style={{margin: 0}}>{f.ean}</h3>
                                        {f.errors.map((field) => {
                                            return (
                                                <Box style={{marginTop: 5}}>
                                                    <hr />
                                                    <Box
                                                        display={'grid'}
                                                        style={{gridTemplateColumns: '1fr 3fr', rowGap: '10px'}}
                                                    >
                                                        <Box>
                                                            <h4 style={{margin: 0}}>VeldNaam</h4>
                                                            {field.fieldName}
                                                        </Box>
                                                        <Box>
                                                            <h4 style={{margin: 0}}>Huidige waarde</h4>
                                                            {field.error?.huidigeWaarde}
                                                        </Box>
                                                    </Box>
                                                    <Box style={{marginTop: 5}}>
                                                        <h4 style={{margin: 0}}>Foutmelding:</h4>
                                                        {field.error?.error ? field.error?.error : field}
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                );
                            })}
                        </Box>
                    </Paper>
                </Box>
                <Box>
                    <Box className={classes.resultContainer}>
                        <Paper className={classes.resultPaper}>
                            <Typography gutterBottom component="h2" variant="h6">
                                Bijgewerkt: {`${result.updated.length}`}
                            </Typography>
                            {result.updated.map((ean) => {
                                return (
                                    <Box
                                        style={{
                                            color: 'rgb(225,127,0)',
                                        }}
                                    >
                                        <b style={{margin: 0}}>{ean}</b>
                                    </Box>
                                );
                            })}
                        </Paper>
                    </Box>
                    <Box className={classes.resultContainer}>
                        <Paper className={classes.resultPaper}>
                            <Typography gutterBottom component="h2" variant="h6">
                                Toegevoegd: {`${result.added.length}`}
                            </Typography>
                            {result.added.map((ean) => {
                                return (
                                    <Box
                                        style={{
                                            color: 'rgb(0,204,215)',
                                        }}
                                    >
                                        <b style={{margin: 0}}>{ean}</b>
                                    </Box>
                                );
                            })}
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}

export default compose(defaultConnect, withPermissions(Recht.PRODUCTEN_TOEVOEGEN), withStyles(styles))(ProductImport);
