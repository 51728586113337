import DateFnsUtils from '@date-io/date-fns';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Restore} from '@material-ui/icons';
import {Sync} from '@material-ui/icons';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {nl} from 'date-fns/locale';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {DateToLocalDateRenderer} from '../../components/datatable/contentrenderers';
import DynamicSelect from '../../components/dynamicselect';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {toastPromise} from '../../components/toast';
import * as Restclient from '../../services/restclient';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';
import RetryPrius from './retryprius';

const dateFns = new DateFnsUtils();

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    formInputBox: {
        width: 280,
        margin: theme.spacing(1),
    },
    formInputBoxMedium: {
        width: 180,
        margin: theme.spacing(1),
    },
    formDateBox: {
        width: 210,
        margin: '0 8px 0 8px',
    },
    alignRight: {
        marginLeft: 'auto',
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

const bronnen = ['ECK', 'API'];

class OrdersOverzicht extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    q: Types.STRING,
                    'eckAccount.organisation': Types.STRING,
                    product: Types.NUMBER,
                    'product.organisation': Types.STRING,
                    fromDate: Types.DATE,
                    toDate: Types.DATE,
                    bronnen: Types.ARRAY,
                },
                props.history
            ),
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {classes, authUserReducer} = this.props;

        const columns = [
            {
                label: 'Aangemaakt',
                dataKey: 'createdAt',
                component: <DateToLocalDateRenderer />,
            },
            {label: 'Amount', dataKey: 'amount'},
            {label: 'Reference', dataKey: 'referenceId'},
            {label: 'Distributeur', dataKey: 'eckAccount.organisation.name'},
            {label: 'Correct reference', dataKey: 'correctReferenceId'},
            {label: 'Product', dataKey: 'product.title'},
            {label: 'EAN', dataKey: 'product.ean'},
            {label: 'Orderline', dataKey: 'orderLineId'},
            {label: 'correct orderline', dataKey: 'correctLineId'},
            {label: 'OrderId', dataKey: 'orderId'},
            {label: 'ExterneId', dataKey: 'externId'},
            {label: 'Bron', dataKey: 'creationMethod'},
            {label: 'Schooljaar', dataKey: 'schoolYear.name'},
        ];

        if (hasRight(this.props.authUserReducer, Recht.RETRY_PRIUS_ORDER)) {
            columns.push({
                label: 'Retry prius',
                component: (
                    <RetryPrius
                        onFinish={() => {
                            this.setState({
                                version: new Date().getTime(),
                            });
                        }}
                    />
                ),
                styling: {justifyContent: 'center'},
            });
        }

        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history} />
                <Grid container>
                    <Grid container xs={10} spacing={2}>
                        <Grid item>
                            <Typography component="h1" variant="h5">
                                Orders
                            </Typography>
                        </Grid>
                        {hasRight(authUserReducer, Recht.RETRY_PRIUS_ORDER) && (
                            <Grid item>
                                <Button
                                    title={'Alle gefaalde orders opnieuw inschieten'}
                                    onClick={async () => {
                                        toastPromise(
                                            Restclient.retryAllFailedOrders(),
                                            'Alle gefaalde orders worden opnieuw ingeschoten',
                                            () => {
                                                if (this._isMounted) {
                                                    this.setState({
                                                        version: Number(new Date()),
                                                    });
                                                }
                                                return 'Alle gefaalde orders zijn opnieuw ingeschoten';
                                            },
                                            (e) => e.message
                                        );
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    <Restore />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item className={classes.alignRight}>
                        <Button
                            title={'Refresh'}
                            style={{marginRight: "15px"}}
                            onClick={() => {
                                this.setState({
                                    version: new Date().getTime(),
                                });
                            }}
                            color="primary"
                        >
                            <Sync />
                        </Button>
                        {hasRight(authUserReducer, Recht.ORDERS_TOEVOEGEN) && (
                            <Link to='/orderimport'>
                                <Button
                                    color={'primary'}
                                    variant={'outlined'}
                                >
                                    Voorraadmutatie inschieten
                                </Button>
                            </Link>
                        )}
                    </Grid>
                </Grid>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl>
                            <SearchTextField
                                fullWidth={true}
                                className={classes.formInputBox}
                                label="ReferenceId, OrderId of OrderLineId"
                                value={this.state.filters.q}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(v) => {
                                    this.setState({filters: {...this.state.filters, q: v}});
                                }}
                                autoFocus={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <Select
                                label="Bronnen..."
                                labelId="bronLabel"
                                multiple
                                value={this.state.filters.bronnen}
                                onChange={(event) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            bronnen: event.target.value,
                                        },
                                    });
                                }}
                                input={<TextField margin="dense" variant="outlined" placeholder="Bronnen..." />}
                                renderValue={(selected) => selected.sort().join(', ')}
                                MenuProps={MenuProps}
                            >
                                {bronnen.map((bron) => (
                                    <MenuItem key={bron} value={bron}>
                                        <Checkbox checked={this.state.filters.bronnen.includes(bron)} />
                                        <ListItemText primary={bron} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBoxMedium}>
                            <DynamicSelect
                                name="distributeur"
                                endpoint="organisations"
                                endpointFilter={{type: 'Distributeur'}}
                                value={this.state.filters['eckAccount.organisation']}
                                valueKey="id"
                                label="Distributeur..."
                                labelKey="toString"
                                placeholder="Distributeur..."
                                onChange={(event) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            'eckAccount.organisation': event.target.value,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formInputBox}>
                            <AutoComplete
                                name={'product'}
                                endpoint={'products'}
                                value={this.state.filters.product}
                                labelKey={'toString'}
                                secondLabelKey={'ean'}
                                valueKey={'id'}
                                placeholder={'Product...'}
                                onEnter={() => this.reloadDataTable()}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            product: value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {!authUserReducer?.beheerder?.uitgever && (
                        <Grid item>
                            <FormControl className={classes.formInputBoxMedium}>
                                <AutoComplete
                                    name={'uitgever'}
                                    endpoint={'organisations'}
                                    endpointFilter={{type: 'Uitgever'}}
                                    value={this.state.filters['product.organisation']}
                                    labelKey={'name'}
                                    secondLabelKey={'esn'}
                                    placeholder={'Uitgever...'}
                                    onChange={(property, value) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                'product.organisation': value && value.id,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <FormControl className={classes.formInputBoxMedium}>
                            <AutoComplete
                                name={'schoolYear'}
                                endpoint={'schoolyears'}
                                value={this.state.filters['schoolYear']}
                                labelKey={'name'}
                                placeholder={'Schooljaar...'}
                                onChange={(property, value) => {
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            'schoolYear': value && value.id,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formDateBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    clearable
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt vanaf"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={(e) => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: null,
                                                },
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    fromDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                },
                                            });
                                        }
                                    }}
                                    value={this.state.filters.fromDate}
                                    invalidDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl className={classes.formDateBox}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                <KeyboardDatePicker
                                    autoOk
                                    clearable
                                    format="dd-MM-yyyy"
                                    label="Aangemaakt tot"
                                    margin="dense"
                                    inputVariant="outlined"
                                    onChange={(e) => {
                                        if (e === null) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: null,
                                                },
                                            });
                                        } else if (dateFns.isValid(e)) {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    toDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                },
                                            });
                                        }
                                    }}
                                    value={this.state.filters.toDate}
                                    invalidDateMessage=""
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        csv
                        endpoint="orderdetails"
                        version={this.state.version}
                        striped
                        filter={this.state.filters}
                        columns={columns}
                    />
                </Grid>
            </Paper>
        );
    }
}

export default compose(defaultConnect, withRouter, withPermissions(Recht.ORDERS_INZIEN), withStyles(styles))(OrdersOverzicht);
