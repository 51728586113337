import Immutable from 'immutable';

import {toastPromise} from '../components/toast';
import * as Errors from '../errors/errors';
import * as RestClient from '../services/restclient';
import * as Util from '../services/util';
import * as Actions from './actions';

export function sortDataTable({sortBy, sortDirection}) {
    return {
        type: Actions.SORT_DATATABLE,
        sortBy,
        sortDirection,
    };
}

export function fetchData({endpoint, sortBy, sortDirection, filter, rangeStart, rangeEnd}) {
    return (dispatch) => {
        const range = {start: rangeStart, end: rangeEnd};
        dispatch({type: Actions.FETCH_DATA});
        return RestClient.fetchData({endpoint, sortBy, sortDirection, filter, range})
            .then(({data, range}) => {
                dispatch(receiveData({data, range, sortBy, sortDirection}));
            })
            .catch((e) => {
                if (e.type === Errors.STALE_ACCESS_TOKEN) {
                    window.location.reload();
                } else {
                    dispatch(receiveDataError(e));
                }
            });
    };
}

export function fetchItem({endpoint, id}) {
    return (dispatch) => {
        dispatch({type: Actions.FETCH_ITEM});
        RestClient.fetchItem({endpoint: endpoint, id: id})
            .then((data) => {
                dispatch(receiveItem(data));
            })
            .catch((e) => {
                dispatch(receiveDataError(e));
            });
    };
}

export function deleteItem({endpoint, id}, throwError = true) {
    return async (dispatch) => {
        dispatch({type: Actions.DELETE_ITEM});
        try {
            await RestClient.deleteItem({endpoint, id});
            dispatch({type: Actions.DELETE_ITEM_SUCCESS, id});
        } catch (e) {
            if (throwError) {
                throw e;
            } else {
                dispatch({type: Actions.DELETE_ITEM_ERROR, failedEntities: e.failedEntities, error: e});
            }
        }
    };
}

export function postData({endpoint, entity, parameters, responseType}) {
    return async (dispatch) => {
        dispatch({type: Actions.POST_DATA});
        try {
            let data = await RestClient.postData({endpoint, entity, parameters, responseType});
            return dispatch(postDataSuccess(data));
        } catch (e) {
            return dispatch(postDataError(e));
        }
    };
}

export function updateData({endpoint, entity, parameters}) {
    return async (dispatch) => {
        dispatch({type: Actions.PUT_DATA});
        try {
            let data = await RestClient.updateData({endpoint, entity, parameters});
            return dispatch(updateDataSuccess(data));
        } catch (e) {
            return dispatch(updateDataError(e));
        }
    };
}

export function receiveData({data, range, sortBy, sortDirection}) {
    return {
        type: Actions.RECEIVE_DATA,
        data: Immutable.List(data),
        range,
        sortBy,
        sortDirection,
    };
}

export function receiveItem(data) {
    return {
        type: Actions.RECEIVE_ITEM,
        data,
    };
}

export function receiveDataError(error) {
    return {
        type: Actions.RECEIVE_DATA_ERROR,
        error,
    };
}

export function postDataSuccess(data) {
    return {
        type: Actions.POST_DATA_SUCCESS,
        data,
    };
}

export function postDataError(error) {
    return {
        type: Actions.POST_DATA_ERROR,
        error,
    };
}

export function updateDataSuccess({data}) {
    return {
        type: Actions.PUT_DATA_SUCCESS,
        data: Immutable.List(data),
    };
}

export function updateDataError(error) {
    return {
        type: Actions.PUT_DATA_ERROR,
        error,
    };
}

export function fetchAdministrator() {
    return (dispatch) => {
        dispatch(fetchAccountAction());
        RestClient.fetchAdministrator().then((beheerder) => dispatch(receiveAdministrator(beheerder)));
    };
}

export function fetchAccountAction() {
    return {
        type: Actions.FETCH_ADMIN_INFO,
        loading: true,
    };
}

export function receiveAdministrator(beheerder) {
    return {
        type: Actions.RECEIVE_ADMIN_INFO,
        beheerder,
    };
}

export function saveSupportEntry(supportEntry) {
    return (dispatch) => {
        try {
            Util.saveToSessionStorage('supportEntry', JSON.stringify(supportEntry));
        } catch (e) {
            console.warn(e);
        }
        dispatch({
            type: Actions.SAVE_SUPPORTENTRY,
            supportEntry,
        });
    };
}

export function resetClientSecret(entity) {
    return (dispatch) => {
        RestClient.resetClientSecret(entity)
            .then((res) => {
                dispatch({
                    type: Actions.RESET_CLIENT_SECRET,
                    entityId: entity.id,
                    secret: res.clientsecret,
                });
            })
            .catch((e) => {
                dispatch(updateDataError(e));
            });
    };
}

export function emptyClientSecret() {
    return (dispatch) => {
        dispatch({
            type: Actions.RESET_CLIENT_SECRET,
        });
    };
}

export function closeSupportTicket(key) {
    return (dispatch) => {
        return RestClient.closeSupportTicket(key)
            .then((res) => {
                //Util.removeFromSessionStorage('supportEntry');
                dispatch({type: Actions.SUPPORTENTRY_CLOSED, id: key});
            })
            .catch((e) => {
                dispatch(updateDataError(e));
            });
    };
}

export function destroySession(session) {
    return (dispatch) => {
        RestClient.destroySession(session)
            .then((v) =>
                dispatch({
                    type: Actions.DESTROY_SESSION_SUCCESS,
                    session,
                })
            )
            .catch((e) =>
                dispatch({
                    type: Actions.DESTROY_SESSION_ERROR,
                    error: {
                        message: `Er trad een fout op bij het stoppen van sessie ${session.id}.\n Foutmelding: ${e}`,
                    },
                })
            );
    };
}

export function removeOAuthClientFromCache(client) {
    return (dispatch) => {
        RestClient.removeOAuthClientFromCache(client.id)
            .then((v) =>
                dispatch({
                    type: Actions.REMOVE_OAUTHCLIENT_FROM_CACHE_SUCCESS,
                    client,
                })
            )
            .catch((e) =>
                dispatch({
                    type: Actions.REMOVE_OAUTHCLIENT_FROM_CACHE_ERROR,
                    error: {
                        message: `Er trad een fout op bij het verwijderen van de oude OAuthClient uit de cache.\n Foutmelding: ${e}`,
                    },
                })
            );
    };
}

export function resendAccessUrl(accountId) {
    return async (dispatch) => {
        dispatch({type: Actions.RESEND});
        return await RestClient.resendAccessUrl(accountId)
            .then((v) =>
                dispatch({
                    type: Actions.RESEND_OK,
                })
            )
            .catch((e) =>
                dispatch({
                    type: Actions.RESEND_FAILED,
                })
            );
    };
}

export function cancelReload() {
    return {
        type: Actions.RESEND_FAILED,
    };
}

export function exportProducts() {
    return (dispatch) => {
        RestClient.exportProducts();
    };
}

export function importProducts(body) {
    return (dispatch) => {
        RestClient.importProducts(body)
            .then((data) => {
                dispatch({
                    type: Actions.IMPORT_OK,
                    data: data,
                });
            })
            .catch((e) =>
                dispatch({
                    type: Actions.IMPORT_FAILED,
                })
            );
    };
}

export function clearImports() {
    return {
        type: Actions.CLEAR_IMPORT,
    };
}

export function fetchDistributeurs() {
    return async (dispatch) => {
        dispatch({type: Actions.ORDERBALANS_DISTRIBUTEURS_FETCH});
        return await RestClient.fetchData({
            endpoint: 'organisations',
            filter: {type: 'Distributeur'},
        })
            .then((result) =>
                dispatch({
                    type: Actions.ORDERBALANS_DISTRIBUTEURS_FETCH_OK,
                    data: result.data,
                })
            )
            .catch((e) =>
                dispatch({
                    type: Actions.ORDERBALANS_DISTRIBUTEURS_FETCH_ERROR,
                })
            );
    };
}

export function receiveCsv(url) {
    return {
        type: Actions.RECEIVE_CSV,
        url,
    };
}

export function receiveCsvError() {
    return {
        type: Actions.RECEIVE_CSV_ERROR,
    };
}

export function fetchCsv({endpoint, sortBy, sortDirection, filter, csvHeaders}) {
    return async (dispatch) => {
        dispatch({type: Actions.FETCH_CSV});
        try {
            const response = await RestClient.fetchCsv({endpoint, sortBy, sortDirection, filter, csvHeaders});
            if (response['url']) {
                return dispatch(receiveCsv(response['url']));
            } else {
                return dispatch(receiveCsv());
            }
        } catch (e) {
            return dispatch(receiveCsvError());
        }
    };
}

export function adjustInvoice(entity) {
    return async (dispatch) => {
        dispatch({type: Actions.FETCH_ADJUSTED_INVOICE});
        RestClient.adjustInvoice(entity)
            .then((res) => {
                dispatch({
                    type: Actions.RECEIVE_ADJUSTED_INVOICE,
                    entity: entity,
                });
            })
            .catch((e) => {
                dispatch(updateDataError(e));
            });
    };
}
