/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default function getValidationSchema() {
    return Yup.object()
        .shape({
            startDate: Yup.date().required(),
            endDate: Yup.date().nullable(),
            redirectAfterSec: Yup.number()
                .integer()
                .min(-1)
                .max(5000)
                .required()
        })
        .test({test: _startDateBeforeEndDate});
}

function _startDateBeforeEndDate(value) {
    if (!value.endDate || value.startDate < value.endDate) {
        return true;
    }
    return this.createError({path: 'endDate', message: 'Einddatum moet na startdatum liggen.'});
}
