import {FormControl, MenuItem, Select, TextField} from '@material-ui/core';
import React, {useEffect} from 'react';
import * as uuid from 'uuid/v4';

import * as RestClient from '../../services/restclient';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

function DynamicSelect(props) {
    const [data, setData] = React.useState([]);

    useEffect(() => {
        async function fetchData() {
            const {endpoint, endpointFilter, sortDirection, range} = props;
            const sortBy = props.sortBy ? props.sortBy : props.labelKey;
            const filter = endpointFilter || {};

            return await RestClient.fetchData({
                endpoint,
                sortBy,
                sortDirection,
                filter,
                range,
            });
        }

        fetchData().then((response) => setData(response.data));
    }, ['endpoint']);

    const {label, name, onChange, value, valueKey, labelKey, placeholder, disabled, ...rest} = props;

    return (
        <React.Fragment>
            <FormControl variant={'outlined'}>
                <Select
                    value={value}
                    onChange={onChange}
                    name={name}
                    disabled={disabled}
                    fullWidth={true}
                    label={label}
                    MenuProps={MenuProps}
                    placeholder={placeholder}
                    input={<TextField margin="dense" variant="outlined" />}
                    {...rest}
                >
                    <MenuItem value="">{label}</MenuItem>
                    {data.map((d) => (
                        <MenuItem value={d[valueKey]} key={uuid()}>
                            {d[labelKey]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </React.Fragment>
    );
}

export default DynamicSelect;
