import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import {Recht} from 'shared-types';

import ConfirmationDialog from '../../components/confirmationdialog';
import {hasRight} from '../../components/permissions/withpermissions';
import defaultConnect from '../../store/connector';
import {toastPromise} from "../../components/toast";

function DeletePoLicense(props) {
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const entity = props.rowData;
    const {authUserReducer, deleteItem} = props;

    const _doDelete = React.useCallback(async (entity, deleteItem) => {
        const deletingItem = deleteItem({
            endpoint: 'policenses',
            id: entity.id
        }).then(() => {
            props.handleRefresh();
        });

        toastPromise(
          deletingItem,
          'PO licentie aan het verwijderen...',
          'De PO licentie is verwijderd.',
          (err) => {
              if (err.status === 409) {
                  let msg =
                    'Dit kan nu niet worden verwijderd omdat er nog andere entiteiten aan zijn gekoppeld. Verwijder eerst:\n';
                  for (let entity of err.failedEntities) {
                      msg += `${entity.toString} (${entity.entityType}#${entity.id})\n`;
                  }
                  return msg;
              }
             return `De PO licentie kon niet worden verwijderd${err.message ? ': ' + err.message : ''}.`;
          }
        );
    });

    return hasRight(authUserReducer, Recht.POLICENTIES_VERWIJDEREN) ? (
        <React.Fragment>
            <ConfirmationDialog
                title="Weet je zeker dat je deze PO licentie wilt verwijderen?"
                open={openConfirmDialog}
                onConfirm={() => {
                    _doDelete(entity, deleteItem);
                }}
                onClose={() => setOpenConfirmDialog(false)}
            >
                Naast de PO Licentie worden ook alle aangemaakte tlinks voor{' '}
                {entity && `${entity.organisation.name} met referenceId ${entity.referenceId}`} verwijderd.{' '}
                <p>
                    <b>Dit is onomkeerbaar!</b>
                </p>
            </ConfirmationDialog>

            <IconButton onClick={() => setOpenConfirmDialog(true)} aria-label="Open drawer">
                <DeleteIcon />
            </IconButton>
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
}

export default defaultConnect(DeletePoLicense);
