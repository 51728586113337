import Button from '@material-ui/core/Button';
import React from 'react';

import ConfirmationDialog from '../../components/confirmationdialog';
import InfoDialog from '../../components/infodialog';
import defaultConnect from '../../store/connector';

function ResetOAuthSecret(props) {
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [openShowSecretDialog, setOpenShowSecretDialog] = React.useState(false);

    const entity = props.rowData;

    function updateSecret(entity) {
        props.resetClientSecret(entity);
        props.removeOAuthClientFromCache(entity);
    }

    return entity && entity.rol !== 'InternalApp' ? (
        <React.Fragment>
            <ConfirmationDialog
                title="Weet je zeker dat je de secret wil resetten?"
                open={openConfirmDialog}
                onConfirm={() => {
                    updateSecret(entity);
                    setOpenShowSecretDialog(true);
                }}
                onClose={() => setOpenConfirmDialog(false)}
            >
                Weet je zeker dat je de secret {entity && `voor ${entity.displayname}`} opnieuw wil genereren? Dit is
                onomkeerbaar!!
            </ConfirmationDialog>

            {openShowSecretDialog && (
                <InfoDialog
                    title={'Nieuwe secret'}
                    open={openShowSecretDialog}
                    onClose={() => {
                        setOpenShowSecretDialog(false);
                        props.emptyClientSecret();
                    }}
                >
                    {props.oauthClientReducer.secret}
                    <br />
                    <br />
                    Let op! Dit is het enige moment dat de secret in te zien is.
                </InfoDialog>
            )}

            <Button onClick={() => setOpenConfirmDialog(true)} variant="outlined" size="small" color="primary">
                Reset secret
            </Button>
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
}

export default defaultConnect(ResetOAuthSecret);
