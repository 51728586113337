import {Paper, withStyles} from '@material-ui/core';
import * as React from 'react';
import {compose} from 'recompose';

import EditPage from '../../../components/editpage';
import AccountForm from './accountform';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
});

class AccountEdit extends React.Component {
    static defaultProps = {
        endpoint: 'accounts',
        entityDescription: 'Account',
    };

    render() {
        const {entityDescription, endpoint, classes} = this.props;
        const {id} = this.props.match.params;
        return (
            <Paper className={classes.root}>
                <EditPage endpoint={endpoint} entityDescription={entityDescription} formComponent={AccountForm} entityId={id} />
            </Paper>
        );
    }
}

export default compose(withStyles(styles))(AccountEdit);
