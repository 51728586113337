import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {SearchRounded} from '@material-ui/icons';
import React from 'react';
import {Waypoint} from 'react-waypoint';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AuditLogOverzicht from '../../components/auditlogoverzicht';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import defaultConnect from '../../store/connector';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {nl} from 'date-fns/locale';

const dateFns = new DateFnsUtils();

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        margin: theme.spacing(4),
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(7),
        right: theme.spacing(7),
        position: 'fixed',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    formInputBox: {
        width: '400px',
        margin: theme.spacing(1),
    },
    searchButtonBox: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dateBox: {
        width: 210,
        marginLeft: theme.spacing(1),
    },
});

class Audit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialMeerClick: false,
            filters: {
                amount: 20,
                name: '',
                email: '',
                requestType: '',
                startDate: null,
                endDate: null
            },
            version: 0,
        };
    }

    reloadAuditLogDataTable() {
        this.setState({version: Number(Date.now())});
    }

    _meerButton = () => {
        this.setState({initialMeerClick: true});
        this.setState({filters: {...this.state.filters, amount: this.state.filters.amount + 10}});
        this.reloadAuditLogDataTable();
    };

    render() {
        const {classes, authUserReducer} = this.props;

        return (
            <Box>
                {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                    <Paper className={classes.root}>
                        <Grid container justify={'space-between'}>
                            <Grid>
                                <Typography component="h1" variant="h5">
                                    Audit log
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify={'flex-start'} className={classes.inputContainer}>
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <SearchTextField
                                        autoFocus={true}
                                        fullWidth
                                        variant="outlined"
                                        label="Zoeken op email"
                                        margin="dense"
                                        value={this.state.filters.email}
                                        onEnter={() => this.reloadAuditLogDataTable()}
                                        onChange={(e) => {
                                            this.setState({filters: {...this.state.filters, email: e, amount: 20}});
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchRounded style={{opacity: 0.2}} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <SearchTextField
                                        fullWidth
                                        variant="outlined"
                                        label="Zoeken op naam"
                                        margin="dense"
                                        value={this.state.filters.name}
                                        onEnter={() => this.reloadAuditLogDataTable()}
                                        onChange={(e) => {
                                            this.setState({filters: {...this.state.filters, name: e, amount: 20}});
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchRounded style={{opacity: 0.2}} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.formInputBox}>
                                    <TextField
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.formInputBox}
                                        label="Request type"
                                        value={this.state.filters.requestType}
                                        onChange={(e) => {
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    requestType: e.target.value,
                                                    amount: 20,
                                                },
                                            });
                                        }}
                                    >
                                        <MenuItem value="PUT">PUT</MenuItem>
                                        <MenuItem value="POST">POST</MenuItem>
                                        <MenuItem value="DELETE">DELETE</MenuItem>
                                        <MenuItem value="">Alles</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.dateBox}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDatePicker
                                            autoOk
                                            clearable
                                            format="dd-MM-yyyy"
                                            label="Startdatum"
                                            margin="dense"
                                            inputVariant="outlined"
                                            onChange={(e) => {
                                                if (e === null) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            startDate: null,
                                                        },
                                                    });
                                                } else if (dateFns.isValid(e)) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            startDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                        },
                                                    });
                                                }
                                            }}
                                            value={this.state.filters.startDate}
                                            invalidDateMessage=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <FormControl className={classes.dateBox}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                                        <KeyboardDatePicker
                                            autoOk
                                            clearable
                                            format="dd-MM-yyyy"
                                            label="Einddatum"
                                            margin="dense"
                                            inputVariant="outlined"
                                            onChange={(e) => {
                                                if (e === null) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            endDate: null,
                                                        },
                                                    });
                                                } else if (dateFns.isValid(e)) {
                                                    this.setState({
                                                        filters: {
                                                            ...this.state.filters,
                                                            endDate: dateFns.date(dateFns.format(e, 'yyyy-MM-dd')),
                                                        },
                                                    });
                                                }
                                            }}
                                            value={this.state.filters.endDate}
                                            invalidDateMessage=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                            <Grid item className={classes.searchButtonBox}>
                                <SearchButton onClick={this.reloadAuditLogDataTable.bind(this)} />
                            </Grid>
                            <Grid container>
                                <AuditLogOverzicht
                                    endpoint="es/auditlog"
                                    filter={this.state.filters}
                                    version={this.state.version}
                                    footer={({fetching, results}) =>
                                        fetching ? (
                                            <CircularProgress />
                                        ) : (
                                            <Waypoint>
                                                {results.size > 0 && results.size % 10 === 0 && (
                                                    <Button
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick={this._meerButton.bind(this)}
                                                    >
                                                        <i className="material-icons">expand_more</i>
                                                    </Button>
                                                )}
                                            </Waypoint>
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Box>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.IS_SUPERUSER), withStyles(styles))(Audit);
