import React from 'react';
import {ToastContainer, toast} from 'react-toastify';

/**
 * @module toast
 *
 * Component die {@link https://www.npmjs.com/package/react-toastify toast}berichten kan laten zien. Zowel
 * simpele messages als zelf updatende toasts o.b.v. een Promise zijn mogelijk.
 */

/**
 * Minimale aantal miliseconden dat een toast op het scherm moet staan.
 * @type {number}
 */
const MINIMUM_TOAST_DURATION = 4000;

/**
 * Toast container voor react-toastify notifications.
 * Deze wordt geplaatst in de root component (src/index.jsx)
 * zodat het altijd beschikbaar is
 * @return {JSX.Element}
 * @constructor
 */
export function ToegangToastContainer() {
    return (
        <ToastContainer
            theme="dark"
            position="bottom-left"
            autoClose={false}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
}

/**
 * Geeft een benadering van hoe lang een toast zichtbaar moet zijn op basis
 * van de lengte van de message.
 * @param message De tekst die in de toast zal worden weergegeven.
 * @return {number} aantal milliseconden dat de toast weergegeven moet worden.
 *                  Is altijd minstens {@link MINIMUM_TOAST_DURATION}.
 */
function _getToastDuration(message) {
    if (typeof message !== 'string') {
        return MINIMUM_TOAST_DURATION;
    }
    const estimatedReadingTime = Math.round(1000 * (message.length / 25));
    return Math.max(estimatedReadingTime, MINIMUM_TOAST_DURATION);
}

/**
 * Functie die toasts laat zien. Roep dus niet direct {@link toast} aan, we
 * willen dat de duration van de toast meeschaalt met de lengte van het bericht.
 * @param {function} toastFun De {@link toast} functie die een bepaald type toast
 * gaat weergeven.
 * Op basis hiervan wordt het juiste type toast weergegeven.
 * @param message De tekst of react component die in de toast weergegeven moet worden.
 * @param {object} [options] Optionele extra opties. Zie {@link https://fkhadra.github.io/react-toastify/api/toast} voor een
 * lijst met alle opties.
 * @returns de toastId van de toast. Deze kan je gebruiken om de toast bijvoorbeeld te laten verdwijnen met `toast.dismiss(id)`.
 * @private
 */
function _showToast(toastFun, message, options) {
    if (options === undefined || options === null) {
        options = {};
    }

    if (!Object.keys(options).includes('autoClose')) {
        let toastDuration;
        if (typeof message === 'string') {
            toastDuration = _getToastDuration(message);
        } else {
            toastDuration = MINIMUM_TOAST_DURATION;
        }
        options.autoClose = toastDuration;
    }

    return toastFun(message, options);
}

/**
 * Laat een toast message van type success zien.
 * @param message De tekst of het React-component die in de toast komt te staan.
 * @param {object} [options] Optionele extra opties. Zie {@link https://fkhadra.github.io/react-toastify/api/toast} voor een
 * lijst met alle opties.
 * @returns de toastId van de toast. Deze kan je gebruiken om de toast bijvoorbeeld te laten verdwijnen met `toast.dismiss(id)`.
 */
export function toastSuccess(message, options) {
    return _showToast(toast.success, message, options);
}

/**
 * Laat een toast message van type info zien.
 * @param message De tekst of het React-component die in de toast komt te staan.
 * @param {object} [options] Optionele extra opties. Zie {@link https://fkhadra.github.io/react-toastify/api/toast} voor een
 * lijst met alle opties.
 * @returns de toastId van de toast. Deze kan je gebruiken om de toast bijvoorbeeld te laten verdwijnen met `toast.dismiss(id)`.
 */
export function toastInfo(message, options) {
    return _showToast(toast.info, message, options);
}

/**
 * Laat een toast message van type warn zien.
 * @param message De tekst of het React-component die in de toast komt te staan.
 * @param {object} [options] Optionele extra opties. Zie {@link https://fkhadra.github.io/react-toastify/api/toast} voor een
 * lijst met alle opties.
 * @returns de toastId van de toast. Deze kan je gebruiken om de toast bijvoorbeeld te laten verdwijnen met `toast.dismiss(id)`.
 */
export function toastWarn(message, options) {
    return _showToast(toast.warn, message, options);
}

/**
 * Laat een toast message van type error zien.
 * @param message De tekst of het React-component die in de toast komt te staan.
 * @param {object} [options] Optionele extra opties. Zie {@link https://fkhadra.github.io/react-toastify/api/toast} voor een
 * lijst met alle opties.
 * @returns de toastId van de toast. Deze kan je gebruiken om de toast bijvoorbeeld te laten verdwijnen met `toast.dismiss(id)`.
 */
export function toastError(message, options) {
    return _showToast(toast.error, message, options);
}

/**
 * Laat een toast message zien die zichzelf update bij een promise. Als de promise wordt geresolved maar
 * de payload een error-field heeft dan wordt ook een error getoond.
 * Geef als "message"-argumenten callback functies met 1 argument om de opgehaalde data van
 * de promise in de toast te verwerken. Je kan natuurlijk ook gewoon een string of react component
 * opgeven die dan wordt weergegeven.
 * @param {Promise} promise De promise waar de toast naar luistert.
 * @param [messagePending] Bericht (string of react component) die in de toast staat terwijl de promise pending is.
 * @param [messageSuccess] Bericht (string, react component of callback) die in de toast staat nadat de promise resolved is. Laat deze undefined om
 * de toast te laten verdwijnen bij een resolve.
 * @param [messageError] Bericht (string, react component of callback) die in de toast staat nadat de promise rejected is.
 * @param {object} [options] Optionele extra opties. Zie {@link https://fkhadra.github.io/react-toastify/api/toast} voor een
 * lijst met alle opties.
 * @param {boolean} [returnPromise] Optie om de meegegeven promise weer te returnen, voor chains. Indien false (default) wordt de toastId teruggegeven.
 * Deze kan je gebruiken om de toast bijvoorbeeld te laten verdwijnen met `toast.dismiss(id)`.
 * @returns {Promise | number} de Promise of de toastId, afhankelijk van het `returnPromise`-argument.
 *
 * @example
 * // Voorbeeldje met callback functies
 * toastPromise(
 *  p,
 *  "Aan het opslaan..."
 *  (res) => <div>{`${res.data.data} is opgeslagen.`}</div>,
 *  (err) => <div>{`Opslaan niet gelukt: ${err.message}.`}</div>
 * );
 *
 * @example
 * // Voorbeeldje met gewone strings, en een autoClose override
 * toastPromise(
 *  p,
 *  "Aan het opslaan...",
 *  "Opgeslagen.",
 *  "Mislukt."
 *  {autoClose: false} // false betekent disabled.
 * );
 */
export function toastPromise(promise, messagePending, messageSuccess, messageError, options, returnPromise = false) {
    options = options ? options : {};

    const toastId = toast.loading(messagePending ? messagePending : 'Een moment geduld...', options);
    const toastCreatedTime = Date.now();

    promise
        .then((result) => {
            if (result && Object.keys(result).includes('error')) {
                const rendered = typeof messageError === 'function' ? messageError(result) : messageError;

                if (!Object.keys(options).includes('autoClose')) {
                    options.autoClose = _getToastDuration(rendered);
                }

                setTimeout(function () {
                    toast.update(toastId, {render: rendered, type: 'error', isLoading: false, ...options});
                }, Math.max(0, 700 - (Date.now() - toastCreatedTime)));
            } else if (messageSuccess) {
                const rendered = typeof messageSuccess === 'function' ? messageSuccess(result) : messageSuccess;

                if (!Object.keys(options).includes('autoClose')) {
                    options.autoClose = _getToastDuration(rendered);
                }
                setTimeout(function () {
                    toast.update(toastId, {render: rendered, type: 'success', isLoading: false, ...options});
                }, Math.max(0, 700 - (Date.now() - toastCreatedTime)));
            } else {
                setTimeout(function () {
                    toast.update(toastId, {type: 'success', isLoading: false, ...options});
                }, Math.max(0, 700 - (Date.now() - toastCreatedTime)));
            }
        })
        .catch((e) => {
            const rendered = typeof messageError === 'function' ? messageError(e) : messageError;
            if (!Object.keys(options).includes('autoClose')) {
                options.autoClose = _getToastDuration(rendered);
            }

            setTimeout(function () {
                toast.update(toastId, {render: rendered, type: 'error', isLoading: false, ...options});
            }, Math.max(0, 700 - (Date.now() - toastCreatedTime)));
        });

    if (returnPromise) {
        return promise;
    }
    return toastId;
}
