import {getUserManager} from '@axa-fr/react-oidc-redux';
import {withStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, {forwardRef} from 'react';
import {withRouter} from 'react-router';
import {compose} from 'recompose';

import defaultConnect from '../../store/connector';

const styles = theme => ({});

const LogOutButton = forwardRef((props, ref) => (
    <MenuItem
        ref={ref}
        onClick={async () => {
            const {userManager} = props;
            userManager.removeUser();
            await userManager.signoutRedirect();
        }}
    >
        Uitloggen
    </MenuItem>
));

const BaseUserMenu = props => {
    const [account, setAccount] = React.useState(null);
    const [currentDate] = React.useState(new Date());
    const {oidc, fetchAdministrator, authUserReducer} = props;

    if (!authUserReducer.loading && oidc && oidc.user) {
        if (!authUserReducer.beheerder) {
            fetchAdministrator();
        } else if (!account) {
            setAccount(authUserReducer.beheerder);
        }
    }

    return oidc && oidc.user ? (
        <React.Fragment>
            {account ? account.givenName || account.email || 'Onbekend' : 'Onbekend'}
            <div>
                <IconButton onClick={props.handleMenuClick} color="inherit">
                    <AccountCircleIcon />
                </IconButton>
                {currentDate.getMonth() === 11 && currentDate.getDate() >= 15 && (
                    <img
                        style={{marginLeft: '-2.1em', width: '1.5em', marginBottom: '0.7em'}}
                        src="https://cdn-toegang.storage.googleapis.com/santa_hat.png"
                    />
                )}
            </div>
            <Menu
                id="user-menu"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                keepMounted
                anchorEl={props.anchorEl}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <MenuItem
                    onClick={() => {
                        const {history} = props;
                        history.push(`/beheerders/${account.id}/edit/password`);
                        props.handleClose();
                    }}
                >
                    Account
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        const {history} = props;
                        history.push(`/myexports`);
                        props.handleClose();
                    }}
                >
                    Exports
                </MenuItem>
                <LogOutButton userManager={getUserManager()} />
            </Menu>
        </React.Fragment>
    ) : (
        <span>niet ingelogd</span>
    );
};

function UserMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(event) {
        setAnchorEl(null);
    }

    return <BaseUserMenu handleMenuClick={handleMenuClick} handleClose={handleClose} anchorEl={anchorEl} {...props} />;
}

export default compose(defaultConnect, withRouter, withStyles(styles))(UserMenu);
