import Immutable from 'immutable';

import * as Actions from '../store/actions';

export const initialState = {
    results: Immutable.List.of(),
    fetching: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_DATA:
            return {...state, fetching: true};
        case Actions.RECEIVE_DATA: {
            return {fetching: false, results: action.data};
        }
        case Actions.RECEIVE_DATA_ERROR:
            return {...state, fetching: false};
        default:
            return state;
    }
};
