import DateFnsUtils from '@date-io/date-fns';
import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import {Info} from '@material-ui/icons';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {nl} from 'date-fns/locale';
import React from 'react';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {hasRight, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import defaultConnect from '../../store/connector';

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    formInputBox: {
        maxWidth: 250,
        minWidth: 250,
        margin: theme.spacing(1),
    },
});

const dateFns = new DateFnsUtils();

// TODO: als we @date-io/date-fns updaten (naar v2) kan dit netter
const lastMonthDate = new Date();
lastMonthDate.setDate(0);
lastMonthDate.setDate(1);
lastMonthDate.setHours(0);
lastMonthDate.setMinutes(0);
lastMonthDate.setSeconds(0);
lastMonthDate.setMilliseconds(0);

const minDate = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0);

const getToDate = (fromDate) => {
    // TODO: als we @date-io/date-fns updaten (naar v2) kan dit netter
    const toDate = new Date(fromDate.getTime());
    toDate.setMonth(toDate.getMonth() + 1);
    return toDate;
};

class TLinkStatusOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                fromDate: lastMonthDate,
                toDate: getToDate(lastMonthDate),
            },
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    getDataTableColumns() {
        const columns = [
            {label: 'Product', dataKey: 'productTitle'},
            {label: 'EAN', dataKey: 'productEan'},
            {label: 'Available', dataKey: 'available'},
            {label: 'Active', dataKey: 'active'},
            {
                label: (
                    <span>
                        Inactief
                        <Tooltip
                            placement={'top'}
                            title={
                                <>
                                    Aantal <u>Revoked</u> en <u>Expired</u> licenties
                                </>
                            }
                        >
                            <Info color={'secondary'} fontSize={'small'} />
                        </Tooltip>
                    </span>
                ),
                dataKey: 'inactive',
            },
        ];
        if (hasRight(this.props.authUserReducer, Recht.IS_SUPERUSER)) {
            columns.unshift({label: 'Uitgever', dataKey: 'publisherName'});
        }
        return columns;
    }

    render() {
        const {classes, authUserReducer} = this.props;

        return (
            <Paper className={classes.root}>
                <Typography gutterBottom component="h1" variant="h5">
                    Aantal licenties per maand
                </Typography>
                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                        {hasRight(authUserReducer, Recht.IS_SUPERUSER) && (
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name={'organisation'}
                                    endpoint={'organisations'}
                                    endpointFilter={{type: 'Uitgever'}}
                                    value={this.state.filters.publisherId}
                                    labelKey={'name'}
                                    secondLabelKey={'esn'}
                                    placeholder={'Uitgever...'}
                                    onChange={(property, value) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                publisherId: value && value.id,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Periode"
                                variant={'inline'}
                                openTo={'month'}
                                autoOk={true}
                                minDate={minDate}
                                maxDate={lastMonthDate}
                                value={this.state.filters.fromDate}
                                onChange={(date) => {
                                    const fromDate = date <= lastMonthDate ? date : lastMonthDate;
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            fromDate,
                                            toDate: getToDate(fromDate),
                                        },
                                    });
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)} />
                    </Grid>
                </Grid>
                <DataTable
                    endpoint="tlinklicenses/statusOverview"
                    version={this.state.version}
                    columns={this.getDataTableColumns()}
                    filter={{
                        ...this.state.filters,
                        fromDate: dateFns.format(this.state.filters.fromDate, 'yyyy-MM-dd'),
                        toDate: dateFns.format(this.state.filters.toDate, 'yyyy-MM-dd'),
                    }}
                />
            </Paper>
        );
    }
}

export default compose(defaultConnect, withPermissions(Recht.IS_SUPERUSER), withStyles(styles))(TLinkStatusOverview);
