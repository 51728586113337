import {CardContent, Fab, MenuItem, Select, TextField, withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForever from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import {Form, Formik} from 'formik';
import * as React from 'react';
import {Row} from 'react-materialize';
import {withRouter} from 'react-router-dom';

import ConfirmationDialog from '../../../components/confirmationdialog';
import {toastPromise} from '../../../components/toast';
import * as RestClient from '../../../services/restclient';
import getValidationSchema from './getValidationSchema';

const endpoint = 'oauthclients';
const styles = (theme) => ({
    root: {
        padding: 0,
    },
    formControl: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
    },
    error: {
        marginLeft: '2px',
        color: '#f00',
    },
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    saveButton: {
        color: '#fff',
    },
    cancelButton: {
        backgroundColor: '#E0E0E0',
        color: '#fff',
    },
});

const _doSubmit = (values, syncData, endpoint) => {
    const syncing = syncData({
        endpoint: endpoint,
        entity: getValidationSchema().cast(values),
    });

    return toastPromise(
        syncing,
        'Aan het opslaan...',
        'De OAuthClient is opgeslagen.',
        (e) =>
            `De OAuthClient kon niet worden opgeslagen${
                e.message || e.error.message ? ': ' + (e.message ?? e.error.message) : ''
            }.`,
        null,
        true
    );
};

function OAuthClientForm(props) {
    const {classes, entity, deleteItem, history, postSuccess} = props;
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (postSuccess) {
            redirectToOverviewScreen();
        }
    });
    const redirectToOverviewScreen = () => {
        history.push('/oauthclients');
    };
    const _doDelete = async (entity, deleteItem) => {
        toastPromise(
            deleteItem({
                endpoint,
                id: entity.id,
            }),
            'Aan het verwijderen...',
            'De OAuthClient is verwijderd.',
            (err) => {
                if (err.status === 409) {
                    let msg =
                        'De OAuthClient kan nu niet worden verwijderd omdat er nog andere entiteiten aan zijn gekoppeld. Verwijder eerst:\n';
                    if (err.failedEntities.summary) {
                        msg += err.failedEntities.summary;
                    } else {
                        for (let entity of err.failedEntities) {
                            msg += `${entity.toString} (${entity.entityType}#${entity.id})\n`;
                        }
                    }
                    return msg;
                } else {
                    return 'De OAuthClient kon niet worden verwijderd.';
                }
            },
            null,
            true
        )
            .then(() => redirectToOverviewScreen())
            .catch(() => redirectToOverviewScreen());
    };
    const _detectEsc = (e) => {
        if (e.keyCode === 27) redirectToOverviewScreen();
    };

    const _getUitgevers = React.useCallback(async (values, setFieldValue) => {
        RestClient.fetchData({
            endpoint: 'organisations',
            filter: {type: 'Uitgever'},
        }).then((response) => {
            if (response.data?.length > 1) {
                setFieldValue('beschikbareOrgs', response.data, false);
            } else {
                setFieldValue('beschikbareOrgs', [], false);
                throw new Error();
            }
        });
    });

    return entity || !window.location.pathname.includes('edit') ? (
        <CardContent className={classes.root}>
            <ConfirmationDialog
                title="Weet je het zeker?"
                open={open}
                onConfirm={() => {
                    _doDelete(entity, deleteItem);
                }}
                onClose={() => setOpen(false)}
            >
                {entity && entity.displayname
                    ? `Wil je Oauthclient '${entity.displayname}' echt verwijderen?`
                    : 'Wil je deze Oauthclient echt verwijderen?'}
            </ConfirmationDialog>
            <Typography component="h1" variant="h5" gutterBottom noWrap>
                OAuthClient Maken
            </Typography>
            <Formik
                validationSchema={getValidationSchema}
                enableReinitialize={true}
                validateOnChange={false}
                initialValues={{
                    id: entity ? entity.id : undefined,
                    clientId: entity ? entity.clientId : '',
                    rol: entity ? entity.rol : 'UitgeverApp',
                    redirectUris: entity ? entity.redirectUris.join(',') : undefined,
                    organisations: entity ? entity.organisations : [],
                    ownerId: entity ? entity.organisations?.[0].id : undefined,
                    beschikbareOrgs: entity ? entity.organisations : [],
                    eckAccount: entity ? entity.eckAccount : undefined,
                    displayName: entity ? entity.displayName : '',
                }}
                onSubmit={async (values, actions) => {
                    const {postData, updateData} = props;
                    await _doSubmit(values, values.id ? updateData : postData, values.id ? `${endpoint}/${values.id}` : endpoint)
                        .then((result) => {
                            actions.setSubmitting(false);
                        })
                        .catch((e) => {
                            actions.setSubmitting(false);
                        });
                }}
                displayName={'OAuthClient'}
                render={({values, errors, isSubmitting, setFieldValue, setFieldTouched}) => (
                    <Form onKeyDown={_detectEsc}>
                        <Grid container direction="column">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    error={Boolean(errors.displayName)}
                                    autoFocus={true}
                                    value={values.displayName}
                                    label="Korte Omschrijving"
                                    onChange={(e) => {
                                        setFieldValue('displayName', e.target.value, false);
                                    }}
                                />
                                {errors.displayName && <span className={classes.error}>{errors.displayName}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                {entity && entity.clientid ? (
                                    <TextField value={values.clientId} label="Client ID" disabled={true} />
                                ) : (
                                    <TextField
                                        required
                                        error={Boolean(errors.clientId)}
                                        autoFocus={true}
                                        value={values.clientId}
                                        label="Client ID"
                                        onChange={(e) => {
                                            setFieldValue('clientId', e.target.value, false);
                                        }}
                                    />
                                )}
                                {errors.clientId && <span className={classes.error}>{errors.clientId}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    required
                                    error={Boolean(errors.redirectUris)}
                                    autoFocus={true}
                                    value={values.redirectUris}
                                    label="Redirect URL"
                                    onChange={(e) => {
                                        setFieldValue('redirectUris', e.target.value, false);
                                    }}
                                />
                                {errors.redirectUris && <span className={classes.error}>{errors.redirectUris}</span>}
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                {(!entity || !entity.rol) && <InputLabel id="label-id-rol">Rol</InputLabel>}
                                {entity && entity.rol ? (
                                    <TextField value={values.rol} label="Rol" disabled={true} />
                                ) : (
                                    <Select
                                        labelId="label-id-rol"
                                        value={values.rol}
                                        label="Rol"
                                        onChange={(e) => {
                                            setFieldValue('rol', e.target.value, false);
                                        }}
                                    >
                                        <MenuItem value="DistributeurApp">DistributeurApp</MenuItem>
                                        <MenuItem value="InternalApp">InternalApp</MenuItem>
                                        <MenuItem value="UitgeverApp">UitgeverApp</MenuItem>
                                    </Select>
                                )}
                            </FormControl>
                            {values.rol !== 'InternalApp' && (
                                <FormControl className={classes.formControl}>
                                    <label>Organisaties</label>
                                    <Select
                                        multiple
                                        labelId="label-id-organisations"
                                        disabled={values.rol === 'InternalApp'}
                                        value={values.organisations?.map((o) => o.id) || []}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'organisations',
                                                values.beschikbareOrgs.filter((o) => e.target.value.find((n) => n === o.id)),
                                                false
                                            );
                                            if (!e.target.value.includes(values.ownerId)) {
                                                setFieldValue('ownerId', undefined, false);
                                            }
                                        }}
                                        onClick={() => {
                                            _getUitgevers(values, setFieldValue);
                                        }}
                                    >
                                        {values.beschikbareOrgs?.map((o) => (
                                            <MenuItem key={o.id} value={o.id}>
                                                {o.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.organisations && <span className={classes.error}>{errors.organisations}</span>}
                                </FormControl>
                            )}
                            {values.rol !== 'InternalApp' && (
                                <FormControl className={classes.formControl}>
                                    <label>Eigenaar</label>
                                    <Select
                                        labelId="label-id-eigenaar"
                                        disabled={values.rol === 'InternalApp'}
                                        value={values.ownerId}
                                        onChange={(e) => {
                                            setFieldValue('ownerId', e.target.value, false);
                                        }}
                                    >
                                        {values.organisations?.map((o) => (
                                            <MenuItem key={o.id} value={o.id}>
                                                {o.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.ownerId && <span className={classes.error}>{errors.ownerId}</span>}
                                </FormControl>
                            )}
                        </Grid>
                        <Row className={classes.actionButtonContainer}>
                            <Button
                                className={classNames(classes.button, classes.saveButton)}
                                variant="contained"
                                size="medium"
                                color="secondary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Opslaan
                            </Button>
                            <Button
                                size="medium"
                                className={classNames(classes.button, classes.cancelButton)}
                                onClick={(e) => history.goBack()}
                            >
                                <CancelIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                Annuleren
                            </Button>
                            {entity && (
                                <Fab
                                    className={classes.fab}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <DeleteForever />
                                </Fab>
                            )}
                        </Row>
                    </Form>
                )}
            />
        </CardContent>
    ) : (
        <span>Loading...</span>
    );
}

export default withRouter(withStyles(styles)(OAuthClientForm));
