import {
    Box,
    Button,
    Fab,
    FormControlLabel,
    Grid,
    Icon,
    InputAdornment,
    Paper,
    Switch,
    Typography,
    withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {SearchRounded} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {compose} from 'recompose';
import {Recht} from 'shared-types';

import AutoComplete from '../../components/autocomplete';
import DataTable from '../../components/datatable';
import {BooleanRenderer, InfoResolveRenderer} from '../../components/datatable/contentrenderers';
import SearchTextField from '../../components/input/searchtextfield';
import {hasRight, isUitgeverAccount, withPermissions} from '../../components/permissions/withpermissions';
import SearchButton from '../../components/searchbutton';
import SearchQuerySetter from '../../components/searchquerysetter';
import {Types, parseURLSearchParams} from '../../services/util';
import defaultConnect from '../../store/connector';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        zIndex: 999,
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        position: 'fixed',
        color: '#fff'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    formInputBox: {
        maxWidth: 250,
        minWidth: 250,
        margin: theme.spacing(1)
    },
    filterSwitch: {
        margin: 0
    }
});

class Producten extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: parseURLSearchParams(
                {
                    organisation: Types.NUMBER,
                    q: Types.STRING,
                    onlyActive: {type: Types.BOOLEAN, default: true}
                },
                props.history
            )
        };
    }

    reloadDataTable() {
        this.setState({version: Number(Date.now())});
    }

    handleSwitch(event) {
        this.setState({
            version: Number(new Date()),
            filters: {...this.state.filters, onlyActive: event.target.checked}
        });
    }

    getAllowedActions() {
        let actions = ['EXTENDEDVIEW'];
        if (hasRight(this.props.authUserReducer, Recht.PRODUCTEN_AANPASSEN)) {
            actions.unshift('EDIT');
        }
        return actions;
    }

    getDataTableFields() {
        const fields = [
            {label: 'EAN', dataKey: 'ean'},
            {label: 'Titel', dataKey: 'title'}
        ];

        if (!isUitgeverAccount(this.props.authUserReducer)) {
            fields.push({label: 'Uitgever', dataKey: 'organisation.name'});
        }

        fields.push({
            label: 'Active',
            component: (
                <InfoResolveRenderer
                    infoResolver={rowData => {
                        const cross = (
                            <Icon style={{opacity: '0.6'}} color="secondary">
                                clear
                            </Icon>
                        );
                        const check = <Icon color="primary">done</Icon>;
                        return {
                            title: 'active',
                            rowValue: new Date(rowData.endOfLifeDate) > new Date() ? check : cross
                        };
                    }}
                />
            ),
            styling: {justifyContent: 'center'}
        });
        fields.push({
            label: 'Catalogservice',
            dataKey: 'deliverable',
            component: <BooleanRenderer/>,
            styling: {justifyContent: 'center'}
        });
        return fields;
    }

    render() {
        const {classes, authUserReducer, history} = this.props;
        return (
            <Paper className={classes.root}>
                <SearchQuerySetter filters={this.state.filters} history={this.props.history}/>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography component="h1" variant="h5">
                        Producten
                    </Typography>
                    <Link to='/productimport'>
                        <Button
                            color={'primary'}
                            variant={'outlined'}
                        >
                            Producten importeren
                        </Button>
                    </Link>
                </Box>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControl>
                            <SearchTextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                className={classes.formInputBox}
                                label="EAN, Titel of uitgeverinformatie"
                                value={this.state.filters.q}
                                autoFocus={true}
                                onEnter={() => this.reloadDataTable()}
                                onChange={v =>
                                    this.setState({
                                        filters: {
                                            ...this.state.filters,
                                            q: v
                                        }
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchRounded style={{opacity: 0.2}}/>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {authUserReducer && authUserReducer.beheerder && !authUserReducer.beheerder.uitgever && (
                        <Grid item>
                            <FormControl className={classes.formInputBox}>
                                <AutoComplete
                                    name={'organisation'}
                                    endpoint={'organisations'}
                                    endpointFilter={{type: 'Uitgever'}}
                                    value={this.state.filters.organisation}
                                    labelKey={'name'}
                                    secondLabelKey={'esn'}
                                    placeholder={'Uitgever...'}
                                    onChange={(property, value) => {
                                        this.setState({
                                            filters: {
                                                ...this.state.filters,
                                                organisation: value && value.id
                                            }
                                        });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        <SearchButton onClick={this.reloadDataTable.bind(this)}/>
                    </Grid>
                </Grid>
                <Grid container justify="flex-start" className={classes.inputContainer}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={this.state.filters.onlyActive}
                                    onChange={this.handleSwitch.bind(this)}
                                    value={this.state.filters.onlyActive}
                                    color="primary"
                                />
                            }
                            labelPlacement="start"
                            label={'Toon alleen actieve producten'}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <DataTable
                        endpoint="products"
                        version={this.state.version}
                        onRowClick={data => {
                            if (data.id) {
                                history.push(`/producten/${data.id}/extended-view`);
                            }
                        }}
                        filter={this.state.filters}
                        columns={this.getDataTableFields()}
                    />
                </Grid>
                {hasRight(authUserReducer, Recht.PRODUCTEN_TOEVOEGEN) && (
                    <Fab color="secondary" aria-label="Add" className={classes.fab} component={Link}
                         to="/producten/new">
                        <AddIcon/>
                    </Fab>
                )}
            </Paper>
        );
    }
}

export default compose(defaultConnect, withRouter, withPermissions(Recht.PRODUCTEN_INZIEN), withStyles(styles))(Producten);
